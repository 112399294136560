import styled, { keyframes } from 'styled-components'
import { ButtonV1 } from '@footdex2022/uikit'
import pointer from '../../../assets/images/cursor.svg'

const flast = keyframes`
  0% {
			-webkit-transform: scale(0) rotate(45deg);
			opacity: 0;
		}
		80% {
			-webkit-transform: scale(0) rotate(45deg);
			opacity: 0.5;
		}
		81% {
			-webkit-transform: scale(4) rotate(45deg);
			opacity: 1;
		}
		100% {
			-webkit-transform: scale(50) rotate(45deg);
			opacity: 0;
		}
`

const ButtonQuata = styled(ButtonV1)`
	position: relative;
	border: none;
	background: linear-gradient(267.66deg, #c8ff4d 0.33%, #9ade00 98.08%);
	border: 2px solid #e5ffab;
	border-radius: 10px;
	width: 100%;
	color: #fff;
	overflow: hidden;
	box-shadow: none;
	cursor: url(${pointer}) 15 15, move !important;

	font-family: 'Monda';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 26px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	white-space: nowrap;
	color: #000000;
	transition: 0.3s ease;

	:hover {
		text-decoration: none;
		color: #fff;
	}

	:before {
		position: absolute;
		content: '';
		display: inline-block;
		top: -180px;
		left: 0;
		width: 30px;
		height: 100%;
		background-color: #fff;
		animation: ${flast} 3s ease-in-out infinite;
	}

	:hover {
		opacity: 1 !important;
	}

	:active {
		box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
			-4px -4px 6px 0 rgba(116, 125, 136, 0.2),
			inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
			inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
	}
	@media only screen and (max-width: 376px) {
		font-size: 10px;
		max-width: 130px;
	}
`
export default ButtonQuata
