import axios from 'axios'

const API_URL = 'https://api.footdex.io'

// Login user
const login = async (userData: any) => {
	const response = await axios.post(API_URL + '/api/login', userData)
	const statusCode = response.data.StatusCode
	if (statusCode === 200 && response.data) {
		const jwt = response.data.Data.Token
		localStorage.setItem('user', jwt)
	}

	return response.data
}

const authService = {
	login,
}

export default authService
