import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

const ScrollToTop = (props: any) => {
	const location = useLocation()

	// console.log('>>>>>', location)

	useEffect(() => {
		const element: any = document.getElementById(location.hash.slice(1))
		if (location.hash === '') {
			window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
			return
		}
		if (location.hash !== '' && element !== null) {
			const timmer = setTimeout(() => {
				element && element.scrollIntoView({ behavior: 'smooth' })
				return () => {
					clearTimeout(timmer)
				}
			}, 400)
		}
	}, [location])

	return <>{props.children}</>
}

export default ScrollToTop
