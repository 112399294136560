import { Api } from './BaseApi'

class AuthManagerService {
	LichSuGiaoDich = (infoLichSu: any) => {
		return Api.post(`/api/user/transactions`, infoLichSu)
	}

	PostInvestment = (infoProduct: any) => {
		return Api.post(`/api/user/investment`, infoProduct)
	}
	GetBettingTotal = () => {
		return Api.get(`/api/Betting_Total_Gets`)
	}
	getWalletAddress = () => {
		return Api.get(`/api/ReceiveAddress`)
	}
	getInvestment = (infoInvestment: any) => {
		return Api.post(`/api/user/investmenthistory`, infoInvestment)
	}
	getAffiliate = () => {
		return Api.get(`/api/affiliate/statistic`)
	}
}

export const AuthManagerServices = new AuthManagerService()
