export const ArrMenu = [
  {
    title: 'about',
    id: 0,
    to: '/#aboutus',
  },
  {
    title: 'referral',
    id: 2,
    to: '/referral',
  },
  {
    title: 'marketplace',
    id: 3,
    to: '/marketplace',
  },
  {
    title: 'staking',
    id: 5,
    to: 'https://staking.footdex.io/dashboard',
  },
  {
    title: 'play game',
    id: 1,
    to: '/playgame',
  },
  {
    title: 'Metaverse',
    id: 4,
    to: '/metaverse',
  },
]
