import { Flex } from "@footdex2022/uikit";
import styled from "styled-components";
import WheelLucky from '../../assets/imgQuata/luckyspin/ballCenter.png'

export const Wapper = styled(Flex)`
    align-items: center;
    justify-content: center;
    width: 133.02px;
    height: 96.42px;
    position: fixed;
    right:  0px;
    top: 50%;
    transform: translateY(-50%);
    img{
        :nth-child(1){
            width: 100%;
            position: relative;
            z-index: 1;
        }
        :nth-child(2){
            width: 41%;
            position: absolute;
            top: -3%;
            left: 28%;
            animation: RotateIcon 1s linear infinite;
        }
    }
    :before{
        content: "";
        position: absolute;
        width: 6%;
        aspect-ratio: 1/1;
        background-image: url('${WheelLucky}');
        top: 26%;
        left: 48.5%;
        transform: translateY(-50%) translateX(-50%);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        z-index:1;
    }
    @keyframes RotateIcon {
        0%{
            transform: rotateZ(0deg);
        }
        100%{
            transform: rotateZ(360deg);
        }
    }
`