import React from 'react'
import { SvgProps, Svg } from '@footdex2022/uikit'

const ActiveIcon: React.FC<SvgProps> = (props) => {
	return (
		<Svg viewBox="0 0 15 18" {...props}>
			<path
				d="M11.0526 0H1.57895C0.710526 0 0 0.736364 0 1.63636V13.0909H1.57895V1.63636H11.0526V0ZM13.4211 3.27273H4.73684C3.86842 3.27273 3.15789 4.00909 3.15789 4.90909V16.3636C3.15789 17.2636 3.86842 18 4.73684 18H13.4211C14.2895 18 15 17.2636 15 16.3636V4.90909C15 4.00909 14.2895 3.27273 13.4211 3.27273ZM13.4211 16.3636H4.73684V4.90909H13.4211V16.3636Z"
				fill="white"
			/>
		</Svg>
	)
}

export default ActiveIcon
