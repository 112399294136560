import styled, { keyframes } from 'styled-components'
import { Box, Flex, Heading } from '@footdex2022/uikit'
import BannerMarketPng from '../../assets/imgQuata/Marketplace/bannerMarket.png'
import Move from '../../assets/imgQuata/Marketplace/Move.png'
import BannerMarketPngMini from '../../assets/imgQuata/Marketplace/bannerMarketMini.png'
import BannerPlayer from '../../assets/imgQuata/Betting/bannerPlayer.png'

export const Wrapper = styled(Flex)`
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: flex-start;
`

export const BannerMarket = styled(Flex)`
	width: 100%;
	height: 100%;
	min-height: 80vh;
	position: relative;
	/* background-image: url(${BannerMarketPng}); */
	/* background-repeat: no-repeat;
	background-size: cover;
	background-position: center bottom;
	position: relative; */

	.cus-Banner {
		width: 100%;
		height: 100%;
		position: absolute;

		:after {
			content: '';
			position: absolute;
			top: 0%;
			right: 0;
			height: 100%;
			width: 320px;
			background-image: url(${Move});
		}

		:before {
			content: '';
			position: absolute;
			top: 0%;
			left: 0;
			height: 100%;
			width: 320px;
			background-image: url(${Move});
			transform: rotateY(180deg);
		}
	}

	:after {
		position: absolute;
		content: '';
		bottom: 0;
		left: 0;
		width: 100%;
		height: 160px;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.55%, #000000 100%);
	}

	:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 200px;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0), #000000 100%);
	}

	.cus-market-title {
		font-style: normal;
		font-weight: 700;
		font-size: 180px;
		line-height: 110%;
		letter-spacing: 0.02em;
		text-transform: uppercase;

		background: linear-gradient(180deg, #ffffff 0%, #b4b4b4 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
	}

	.cus-market-small {
		font-size: 48px;
		line-height: 110%;
		position: relative;
		margin-top: -40px;
	}

	@media only screen and (max-width: 768px) {
		flex-direction: column;
		padding-top: 40px;
		gap: 20px;

		.cus-market-title {
			font-size: 120px;
		}

		.cus-market-small {
			font-size: 30px;
			margin-top: -10px;
		}
	}
`
export const BannerMarketMini = styled(Box)`
	width: 100%;
	height: 100%;
	min-height: 55vh;
	background-image: url(${BannerMarketPngMini});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center bottom;
	position: relative;

	:after {
		position: absolute;
		content: '';
		bottom: 0;
		left: 0;
		width: 100%;
		height: 160px;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.55%, #000000 100%);
	}

	:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 200px;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0), #000000 100%);
	}
`

export const BoxTrending = styled(Box)`
	width: 100%;
	height: 100%;
	min-height: 500px;
	background-image: url(${BannerPlayer});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	.cus-title {
		background: linear-gradient(
			90deg,
			#6deacd 0%,
			#bfd6e9 36.04%,
			#bca2f6 74.2%,
			#6a57f1 101.76%
		);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
	}

	.cus-content {
		max-width: 70px;
		padding-left: 12px;
	}

	.bg-trans {
		background: transparent !important;
		color: #fff !important;
	}
`
