import { configureStore } from '@reduxjs/toolkit'
import authReducer from './auth/authSlice'
import userReducer from './actions/actions'
import userReducerTotalBetting from './actions/actionsTotalBetting'

import blockReducer from './block'
import poolsReducer from './pools'
import pricesReducer from './prices'

const store = configureStore({
	devTools: process.env.NODE_ENV !== 'production',
	reducer: {
		block: blockReducer,
		prices: pricesReducer,
		pools: poolsReducer,
		auth: authReducer,
		info: userReducer,
		// totalBetting: userReducerTotalBetting,
	},
})

export default store
