import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Heading, Box, Flex, Text } from '@footdex2022/uikit'
import {
	FootterAndOutTeam,
	FooterAndOutTeamContainer,
	Outteam,
	Title,
	Teams,
	FootterFootball,
	FooterItem,
	SOCIAL,
	CoppyRight,
	OutteamFloor,
	FooterLogo,
	FooterConten,
	FooterItemElm,
} from './style'
import Img from '../../assets/imgQuata/Home/Outteam.png'
import Logo from '../../assets/imgQuata/Logo/Logo.png'
import img1 from '../../assets/imgQuata/socical/TLG.svg'
import img2 from '../../assets/imgQuata/socical/socical.svg'
import img3 from '../../assets/imgQuata/socical/twitter.svg'
import img4 from '../../assets/imgQuata/socical/intergram.svg'
import img5 from '../../assets/imgQuata/socical/youtube.svg'
import img6 from '../../assets/imgQuata/socical/Media.svg'
import img7 from '../../assets/imgQuata/socical/FB.svg'
import img8 from '../../assets/imgQuata/socical/Cus.svg'
import ArrowDown from '../../assets/imgQuata/Home/arrowDown.svg'

const ArrSociical = [
	{
		url: 'https://t.me/footdexofficial',
		src: img1,
	},
	{
		url: 'https://discord.com/invite/qXPScXpjsK',
		src: img2,
	},
	{
		url: 'https://twitter.com/footballdex',
		src: img3,
	},
	{
		url: 'https://www.instagram.com/footdex.io/',
		src: img4,
	},
	{
		url: 'https://www.youtube.com/channel/UCyeK1Y1VMpfvscSyusoT6Og',
		src: img5,
	},
	{
		url: 'https://footdex.medium.com/',
		src: img6,
	},
	{
		url: 'https://www.facebook.com/footdex.io',
		src: img7,
	},
	{
		url: 'https://footdex.substack.com/',
		src: img8,
	},
]

const Footter = () => {
	const [showECOSYSTEM, setShowECOSYSTEM] = useState(false)
	const [showECONOMY, setShowECONOMY] = useState(false)
	const [showTEAM, setShowTEAM] = useState(false)
	const [showABOUT, setShowABOUT] = useState(false)
	return (
		<FootterAndOutTeam>
			<FooterAndOutTeamContainer>
				<FootterFootball>
					<FooterLogo>
						<Link to="/">
							<img src={Logo} alt="" />
						</Link>
					</FooterLogo>
					<FooterConten>
						<FooterItem>
							<h1
								onClick={() => {
									setShowECOSYSTEM(!showECOSYSTEM)
								}}
							>
								ECOSYSTEM
								{window.innerWidth < 476 ? (
									<img
										src={ArrowDown}
										alt=""
										style={{
											transform: `rotateZ(${showECOSYSTEM ? '180' : '0'}deg)`,
										}}
									/>
								) : (
									<></>
								)}
							</h1>
							<FooterItemElm
								height={
									window.innerWidth < 476 && showECOSYSTEM ? '100px' : '0px'
								}
							>
								<p>
									<Link to="/marketplace">NFT MARKETPLACE</Link>
								</p>
								<p>
									<Link to="/betting">WEB3 BETTING</Link>
								</p>
								<p>
									<Link to="/playgame">PLAY GAME</Link>
								</p>
								<Link to="/metaverse">
									<p>METAVERSE</p>
								</Link>
							</FooterItemElm>
						</FooterItem>
						<FooterItem>
							<h1
								onClick={() => {
									setShowECONOMY(!showECONOMY)
								}}
							>
								ECONOMY
								{window.innerWidth < 476 ? (
									<img
										src={ArrowDown}
										alt=""
										style={{
											transform: `rotateZ(${showECONOMY ? '180' : '0'}deg)`,
										}}
									/>
								) : (
									<></>
								)}
							</h1>
							<FooterItemElm
								height={
									window.innerWidth < 476 && showECONOMY ? '100px' : '0px'
								}
							>
								<p>TOKENNOMICS</p>
								<p>ROAD MAP</p>
								<p>FBD TOKEN</p>
							</FooterItemElm>
						</FooterItem>
						<FooterItem>
							<h1
								onClick={() => {
									setShowTEAM(!showTEAM)
								}}
							>
								TEAM
								{window.innerWidth < 476 ? (
									<img
										src={ArrowDown}
										alt=""
										style={{
											transform: `rotateZ(${showTEAM ? '180' : '0'}deg)`,
										}}
									/>
								) : (
									<></>
								)}
							</h1>
							<FooterItemElm
								height={window.innerWidth < 476 && showTEAM ? '100px' : '0px'}
							>
								<p>ADVISOR</p>
								<p>CORE TEAM</p>
							</FooterItemElm>
						</FooterItem>
						<FooterItem>
							<h1
								onClick={() => {
									setShowABOUT(!showABOUT)
								}}
							>
								ABOUT
								{window.innerWidth < 476 ? (
									<img
										src={ArrowDown}
										alt=""
										style={{
											transform: `rotateZ(${showABOUT ? '180' : '0'}deg)`,
										}}
									/>
								) : (
									<></>
								)}
							</h1>
							<FooterItemElm
								height={window.innerWidth < 476 && showABOUT ? '100px' : '0px'}
							>
								<p>
									<a
										href="https://docs.footdex.io/"
										target="_blank"
										rel="noreferrer"
									>
										WHITEPAPER
									</a>
								</p>
								<p>
									<a
										href="https://docsend.com/view/3jna7f8jfw52b43a"
										target="_blank"
										rel="noreferrer"
									>
										PITCHDECK
									</a>
								</p>
								<p>
									<a
										href="https://linktr.ee/footdex"
										target="_blank"
										rel="noreferrer"
									>
										SOCIAL MEDIA
									</a>
								</p>
							</FooterItemElm>
						</FooterItem>
					</FooterConten>
				</FootterFootball>
				<SOCIAL>
					{ArrSociical.map((_item, _index) => (
						<Box key={_index}>
							<a href={_item.url} rel="noreferrer" target="_blank">
								<img src={_item.src} alt={`cus-${_index}`} />
							</a>
						</Box>
					))}
				</SOCIAL>
				<CoppyRight>
					<p>User Agreement</p>
					<p>Copyright © 2022 FOOTBALL</p>
				</CoppyRight>
			</FooterAndOutTeamContainer>
		</FootterAndOutTeam>
	)
}
export default Footter
