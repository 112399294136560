import React from 'react'
import { SvgProps, Svg } from '@footdex2022/uikit'

const ActiveIcon: React.FC<SvgProps> = (props) => {
	return (
		<Svg viewBox="0 0 24 24" {...props}>
			<path d="M8.11997 14.7101L12 10.8301L15.88 14.7101C16.27 15.1001 16.9 15.1001 17.29 14.7101C17.68 14.3201 17.68 13.6901 17.29 13.3001L12.7 8.7101C12.31 8.3201 11.68 8.3201 11.29 8.7101L6.69997 13.3001C6.30997 13.6901 6.30997 14.3201 6.69997 14.7101C7.08997 15.0901 7.72997 15.1001 8.11997 14.7101Z"></path>
		</Svg>
	)
}

export default ActiveIcon
