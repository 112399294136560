import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'

// const user = JSON.parse(localStorage.getItem("user")!!);

const initialState = {
	user: '',
	isError: false,
	isSuccess: false,
	isLoading: false,
	message: '',
}

export const login = createAsyncThunk(
	'auth/login',
	async (user: any, thunkAPI) => {
		try {
			return await authService.login(user)
		} catch (error) {
			console.log('error')
		}
	},
)

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		reset: (state) => {
			state.isLoading = false
			state.isSuccess = false
			state.isError = false
			state.message = ''
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.pending, (state) => {
				state.isLoading = true
			})
			.addCase(login.fulfilled, (state, action) => {
				state.isLoading = false
				state.isSuccess = true
				state.user = action.payload
			})
			.addCase(login.rejected, (state, action: any) => {
				state.isLoading = false
				state.isError = true
				state.message = action.payload
				state.user = null
			})
	},
})

export const { reset } = authSlice.actions
export default authSlice.reducer
