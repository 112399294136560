import React from 'react'
import { Box, BoxProps } from '@footdex2022/uikit'

const BoxFull: React.FC<BoxProps> = ({ children, ...props }) => (
	<Box height="100%" width="100%" {...props}>
		{children}
	</Box>
)

export default BoxFull
