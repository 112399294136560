import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AuthManagerServices } from '../services/AuthApi'

export const initialState = {
	info: '',
	isError: false,
	isSuccess: false,
	isLoading: false,
	message: '',
	totalsBetting: '',
	resultWallet: '',
	resultInvent: '',
	affiliate: '',
}

export const getTransation = createAsyncThunk(
	'api/user/investment',
	async (data: any, thunkAPI) => {
		// console.log(data)
		try {
			// const token = thunkAPI.getState().auth.user.token
			return await AuthManagerServices.PostInvestment(data)
		} catch (error) {
			// const message =
			// 	(error.response &&
			// 		error.response.data &&
			// 		error.response.data.message) ||
			// 	error.message ||
			// 	error.toString()
			// return thunkAPI.rejectWithValue(message)
			console.log('error !!!!')
		}
	},
)

export const getBettingTotal = createAsyncThunk(
	'/api/Betting_Total_Gets',
	async () => {
		try {
			return await AuthManagerServices.GetBettingTotal()
		} catch (error) {
			console.log('error !!!!')
		}
	},
)
export const getgetInvestmentTotal = createAsyncThunk(
	'/api/user/transactions',
	async (data: any, thunkAPI) => {
		try {
			return await AuthManagerServices.getInvestment(data)
		} catch (error) {
			console.log('error !!!!')
		}
	},
)

//getWalletAddress

export const getResulttWalletAddress = createAsyncThunk(
	'/api/ReceiveAddress',
	async () => {
		try {
			return await AuthManagerServices.getWalletAddress()
		} catch (error) {
			console.log('error !!!!')
		}
	},
)
export const getAffiliates = createAsyncThunk(
	'/api/affiliate/statistic',
	async () => {
		try {
			return await AuthManagerServices.getAffiliate()
		} catch (error) {
			console.log('error !!!!')
		}
	},
)

export const authSlice = createSlice({
	name: 'info',
	initialState,
	reducers: {
		reset: (state) => {
			state.isLoading = false
			state.isSuccess = false
			state.isError = false
			state.message = ''
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getTransation.pending, (state) => {
				state.isLoading = true
			})
			.addCase(getTransation.fulfilled, (state: any, action) => {
				state.isLoading = false
				state.isSuccess = true
				state.info = action.payload
			})
			.addCase(getTransation.rejected, (state: any, action: any) => {
				state.isLoading = false
				state.isError = true
				state.message = action.payload
				state.info = null
			})

			.addCase(getBettingTotal.pending, (state) => {
				state.isLoading = true
			})
			.addCase(getBettingTotal.fulfilled, (state: any, action) => {
				state.isLoading = false
				state.isSuccess = true
				state.totalsBetting = action.payload
			})
			.addCase(getBettingTotal.rejected, (state: any, action: any) => {
				state.isLoading = false
				state.isError = true
				state.message = action.payload
				state.totalsBetting = null
			})
			.addCase(getResulttWalletAddress.pending, (state) => {
				state.isLoading = true
			})
			.addCase(getResulttWalletAddress.fulfilled, (state: any, action) => {
				state.isLoading = false
				state.isSuccess = true
				state.resultWallet = action.payload
			})
			.addCase(getResulttWalletAddress.rejected, (state: any, action: any) => {
				state.isLoading = false
				state.isError = true
				state.message = action.payload
				state.resultWallet = null
			})
			.addCase(getgetInvestmentTotal.pending, (state) => {
				state.isLoading = true
			})
			.addCase(getgetInvestmentTotal.fulfilled, (state: any, action) => {
				state.isLoading = false
				state.isSuccess = true
				state.resultInvent = action.payload
			})
			.addCase(getgetInvestmentTotal.rejected, (state: any, action: any) => {
				state.isLoading = false
				state.isError = true
				state.message = action.payload
				state.resultInvent = null
			})
			.addCase(getAffiliates.pending, (state) => {
				state.isLoading = true
			})
			.addCase(getAffiliates.fulfilled, (state: any, action) => {
				state.isLoading = false
				state.isSuccess = true
				state.affiliate = action.payload
			})
			.addCase(getAffiliates.rejected, (state: any, action: any) => {
				state.isLoading = false
				state.isError = true
				state.message = action.payload
				state.affiliate = null
			})
	},
})
