import React, { useEffect, useState } from 'react';

export default function BinanceWallet(props) {
  return (
    <svg {...props} width="48" height="48" viewBox="0 0 36 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.8048 0.879883C20.8807 2.71323 23.9566 4.54842 27.0325 6.38176C27.5033 6.66239 27.9759 6.94118 28.504 7.2532C27.2947 7.9714 26.1223 8.6379 24.985 9.36164C24.5788 9.62012 24.3166 9.46134 23.9917 9.26748C22.175 8.18557 20.3342 7.14058 18.5397 6.02359C17.9396 5.65064 17.5039 5.62295 16.8891 6.01251C15.1001 7.13873 13.2501 8.16895 11.4481 9.27302C11.018 9.53703 10.717 9.53888 10.2924 9.26748C9.20862 8.57697 8.08609 7.9437 6.94141 7.26428C10.5324 5.11522 14.0772 2.99755 17.6202 0.879883C17.683 0.879883 17.7439 0.879883 17.8048 0.879883Z" fill="#F0B902"/>
      <path d="M17.4354 42.3897C16.4864 41.5626 15.3232 41.0733 14.2653 40.4179C13.8776 40.1779 13.717 39.9416 13.7281 39.4819C13.7595 38.1969 13.7392 36.91 13.7392 35.4533C14.9503 36.1549 16.0654 36.7642 17.1381 37.438C17.5683 37.7094 17.8693 37.6854 18.2865 37.427C19.3463 36.7679 20.4393 36.1641 21.6799 35.4459C21.6799 37.0097 21.6763 38.4332 21.6818 39.8548C21.6836 40.2278 21.3513 40.2536 21.1427 40.3995C20.1235 41.1103 18.9272 41.5441 17.9893 42.3879C17.8046 42.3897 17.62 42.3897 17.4354 42.3897Z" fill="#F0B902"/>
      <path d="M13.7467 28.0444C13.7467 27.2155 13.6397 26.368 13.7707 25.5612C13.9738 24.3057 13.383 23.7076 12.3602 23.1684C10.7078 22.3007 9.13662 21.2816 7.51005 20.364C7.04849 20.1036 6.88232 19.8027 6.90079 19.2784C6.9414 18.1429 6.93033 17.0056 6.90448 15.8701C6.89525 15.4492 7.03002 15.2036 7.40297 14.995C8.39442 14.4448 9.37109 13.8706 10.3312 13.2688C10.7558 13.0029 11.0881 13.0417 11.5017 13.2928C13.2796 14.3784 15.0927 15.4067 16.8559 16.5126C17.4873 16.9096 17.9655 16.8745 18.5803 16.4923C20.3508 15.3957 22.1694 14.3784 23.9437 13.2909C24.4089 13.0047 24.7597 13.0324 25.1955 13.3094C25.969 13.8023 26.7389 14.3267 27.5698 14.7015C28.3803 15.067 28.6129 15.6135 28.5243 16.4333C28.4744 16.8893 28.4449 17.3675 28.528 17.8124C28.84 19.4648 28.1975 20.4489 26.6651 21.1468C25.1401 21.841 23.7517 22.8306 22.282 23.6522C21.8186 23.9106 21.6635 24.2171 21.6709 24.7415C21.6986 26.9533 21.6672 29.1651 21.6949 31.3751C21.7023 31.905 21.5417 32.2115 21.069 32.4626C20.1238 32.9666 19.1988 33.5131 18.296 34.0891C17.8676 34.3624 17.5371 34.3291 17.1273 34.0725C16.0398 33.3894 14.5923 32.9721 13.9295 32.0084C13.2833 31.0668 13.7948 29.5713 13.7431 28.3195C13.7431 28.2291 13.7467 28.1367 13.7467 28.0444Z" fill="#F0B901"/>
      <path d="M24.6013 38.4127C24.6013 36.9043 24.6253 35.5842 24.5883 34.266C24.5754 33.7675 24.845 33.5736 25.2068 33.3613C27.0568 32.2812 28.8846 31.1568 30.7549 30.1119C31.3032 29.8054 31.4454 29.4546 31.438 28.8712C31.4121 26.6907 31.4491 24.5103 31.4306 22.3317C31.4269 21.8443 31.5635 21.5563 32.0085 21.3181C32.9519 20.8104 33.864 20.2436 34.7779 19.6841C35.1748 19.4404 35.365 19.4367 35.365 19.9888C35.3558 23.8586 35.3595 27.7265 35.3613 31.5963C35.3613 31.8843 35.3318 32.1224 35.0382 32.2941C31.6078 34.2955 28.1849 36.3098 24.6013 38.4127Z" fill="#F0B901"/>
      <path d="M0.160066 19.4019C1.38783 20.1293 2.50483 20.8143 3.64582 21.4549C4.03538 21.6746 3.98738 21.9903 3.98738 22.319C3.99477 24.468 4.03908 26.6171 3.98738 28.7661C3.97076 29.4899 4.2237 29.8628 4.82928 30.2025C6.64416 31.2235 8.41473 32.3183 10.2222 33.3523C10.6783 33.6126 10.8684 33.9061 10.8518 34.4416C10.813 35.697 10.8389 36.9562 10.8389 38.4C9.61481 37.6854 8.49781 37.0356 7.38267 36.382C5.1653 35.0822 2.94978 33.7751 0.728717 32.4808C0.344693 32.2574 0.0529828 32.0543 0.0566753 31.5134C0.082523 27.6141 0.0732917 23.7147 0.0769842 19.8173C0.0788305 19.7268 0.112063 19.64 0.160066 19.4019Z" fill="#F0B901"/>
      <path d="M7.99564 11.2709C6.76602 11.9947 5.69888 12.6519 4.60219 13.2594C4.14432 13.5123 3.95969 13.8096 3.97631 14.345C4.01877 15.6004 3.98923 16.8596 3.98923 18.2683C2.69685 17.5132 1.49308 16.8116 0.294852 16.1045C-0.000550706 15.9309 0.0751463 15.6244 0.0751463 15.3567C0.0696075 14.1917 0.0954552 13.0249 0.0603761 11.8599C0.045606 11.3891 0.233925 11.1417 0.621642 10.9275C1.45246 10.4697 2.2999 10.0266 3.06795 9.47821C3.77322 8.97418 4.32341 9.0148 5.02314 9.50775C5.91858 10.1373 6.90634 10.634 7.99564 11.2709Z" fill="#F0B902"/>
      <path d="M31.4228 18.2701C31.4228 16.8596 31.397 15.582 31.4358 14.308C31.4505 13.8077 31.2788 13.5326 30.8542 13.2926C29.7409 12.6667 28.6534 11.9947 27.4404 11.2709C28.7236 10.5121 29.9107 9.82346 31.0813 9.10895C31.3841 8.92432 31.589 9.04433 31.8327 9.18834C32.8315 9.78284 33.8304 10.381 34.844 10.9515C35.2003 11.1528 35.3757 11.378 35.3646 11.8137C35.3332 13.0101 35.3517 14.2083 35.3554 15.4047C35.3572 15.7186 35.3517 15.9974 35.0194 16.1857C33.8562 16.8448 32.7041 17.5261 31.4228 18.2701Z" fill="#F0B902"/>
      <path d="M21.6735 11.2691C20.4033 12.026 19.2235 12.7258 18.0493 13.4329C17.8185 13.5714 17.6228 13.5898 17.3791 13.4403C16.2067 12.7258 15.0251 12.0279 13.7549 11.2672C15.0122 10.5269 16.1975 9.83451 17.3773 9.12924C17.5804 9.00738 17.7502 8.95753 17.9699 9.08677C19.1755 9.80497 20.3885 10.5139 21.6735 11.2691Z" fill="#F0B902"/>
      <path d="M6.91205 23.3678C8.19705 24.1211 9.37867 24.8134 10.5584 25.5076C10.7264 25.6055 10.8298 25.7384 10.8298 25.9489C10.8261 27.3502 10.828 28.7515 10.828 30.3208C9.53006 29.5657 8.37799 28.8881 7.21484 28.2272C6.95636 28.0813 6.90836 27.8764 6.91021 27.6161C6.9139 26.2406 6.91205 24.8688 6.91205 23.3678Z" fill="#F0B902"/>
      <path d="M24.5954 30.3391C24.5954 28.7845 24.6009 27.4165 24.5899 26.0465C24.588 25.7622 24.7136 25.6016 24.9443 25.4686C26.0964 24.7947 27.2448 24.1135 28.5132 23.3639C28.5132 24.876 28.5113 26.2662 28.515 27.6583C28.515 27.8836 28.4744 28.07 28.2547 28.1974C27.0786 28.8787 25.9063 29.5692 24.5954 30.3391Z" fill="#F0B902"/>
    </svg>
  )
}

