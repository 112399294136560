import React from 'react'
import { toast } from 'react-toastify'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { NavLink } from 'react-router-dom'
import { BoxFull, FlexFull } from '../../../../components/Quata'
import { Heading, Box, Flex, Text } from '@footdex2022/uikit'
import { Picture } from '../../../../components/Picture'
import { useActiveWeb3React } from '../../../../ConnectWallet/hooks'
import { Button } from '../../../../components/Quata'

interface PropsTitleHeading {
	icon?: string
	content?: string
	title?: string
	classNameContent?: string
	classNameTitle?: string
	isPb?: boolean
}

const TitleLeft: React.FC<PropsTitleHeading> = ({
	icon,
	title,
	content,
	classNameContent,
	classNameTitle,
	isPb,
}) => {
	const { account } = useActiveWeb3React()

	const accountEllipsis = `https://footdex.io/?referral=${account?.substring(
		0,
		10,
	)}...${account?.substring(account.length - 4)}`

	const linkReferral = `https://footdex.io/?referral=${account || `TBA`}`
	const onCopyText = () => {
		toast.success('Coppied !!!')
	}

	return (
		<Flex
			width="100%"
			justifyContent="space-between"
			flexDirection={['column', 'column', 'row']}
		>
			<Flex pb={isPb ? '' : '24px'}>
				<Box pr="18px">
					<Flex>
						<Box>
							<Picture url={icon} />
						</Box>
						<Text bold className={classNameContent}>
							{content}
						</Text>
					</Flex>
				</Box>
				<Box width="4px" height="40px" background="#6BE601" />
				<Heading className={`cus-font-devantor ${classNameTitle}`} pl="18px">
					{title}
				</Heading>
			</Flex>
			{title === 'KNOCK-OUT' && (
				<Flex flexDirection={['column', 'row', 'row']}>
					{/* <Box mr={['0px', '20px']} pb={['20px', '0']}>
						{!account ? (
							<Text
								className="cus-nowrap"
								as="p"
								fontSize="14px"
								fontFamily="OpenSansMedium"
							>
								Please Connect Your Wallet
							</Text>
						) : (
							<p className="cus-nowrap">{accountEllipsis}</p>
						)}
					</Box> */}
					{/* <Box width="80px" pb={['30px', '0']}>
						<CopyToClipboard
							text={linkReferral || `TBA`}
							onCopy={() => onCopyText()}
						>
							<Button>Copy</Button>
						</CopyToClipboard>
					</Box> */}
					<Box pb={['20px', '0']}>
						<Button className="cus-btn-reff">
							<NavLink className="cus-title-referral" to="/referral">
								Referral Link
							</NavLink>
						</Button>
					</Box>
				</Flex>
			)}
		</Flex>
	)
}

export default TitleLeft
