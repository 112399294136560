import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
	initialState,
	getTransation,
	getBettingTotal,
	getResulttWalletAddress,
	getgetInvestmentTotal,
	getAffiliates,
} from '../users/userSlice'

export const userSlice = createSlice({
	name: 'invesment',
	initialState,
	reducers: {
		reset: (state) => {
			state.isLoading = false
			state.isSuccess = false
			state.isError = false
			state.message = ''
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getTransation.pending, (state) => {
				state.isLoading = true
			})
			.addCase(getTransation.fulfilled, (state, action: any) => {
				state.isLoading = false
				state.isSuccess = true
				state.info = action.payload
			})
			.addCase(getTransation.rejected, (state, action: any) => {
				state.isLoading = false
				state.isError = true
				state.message = action.payload
			})
			.addCase(getBettingTotal.pending, (state) => {
				state.isLoading = true
			})
			.addCase(getBettingTotal.fulfilled, (state, action: any) => {
				state.isLoading = false
				state.isSuccess = true
				state.totalsBetting = action.payload
			})
			.addCase(getBettingTotal.rejected, (state, action: any) => {
				state.isLoading = false
				state.isError = true
				state.message = action.payload
			})

			.addCase(getResulttWalletAddress.pending, (state) => {
				state.isLoading = true
			})
			.addCase(getResulttWalletAddress.fulfilled, (state: any, action) => {
				state.isLoading = false
				state.isSuccess = true
				state.resultWallet = action.payload
			})
			.addCase(getResulttWalletAddress.rejected, (state: any, action: any) => {
				state.isLoading = false
				state.isError = true
				state.message = action.payload
				state.resultWallet = null
			})
			.addCase(getgetInvestmentTotal.pending, (state) => {
				state.isLoading = true
			})
			.addCase(getgetInvestmentTotal.fulfilled, (state: any, action) => {
				state.isLoading = false
				state.isSuccess = true
				state.resultInvent = action.payload
			})
			.addCase(getgetInvestmentTotal.rejected, (state: any, action: any) => {
				state.isLoading = false
				state.isError = true
				state.message = action.payload
				state.resultInvent = null
			})
			.addCase(getAffiliates.pending, (state) => {
				state.isLoading = true
			})
			.addCase(getAffiliates.fulfilled, (state: any, action) => {
				state.isLoading = false
				state.isSuccess = true
				state.affiliate = action.payload
			})
			.addCase(getAffiliates.rejected, (state: any, action: any) => {
				state.isLoading = false
				state.isError = true
				state.message = action.payload
				state.affiliate = null
			})
	},
})

export const { reset } = userSlice.actions
export default userSlice.reducer
