import React from 'react'
import { SvgProps, Svg } from '@footdex2022/uikit'

const ActiveIcon: React.FC<SvgProps> = (props) => {
	return (
		<Svg viewBox="0 0 16 16" {...props}>
			<path
				d="M8 16C12.4206 16 16 12.4151 16 8C16 3.57938 12.4151 0 8 0C3.57938 0 0 3.58488 0 8C0 12.4206 3.58488 16 8 16ZM10.5897 15.2247C10.5897 15.2247 8.46185 16.3354 5.40481 15.2247C5.64673 14.2955 5.64673 14.2955 5.64673 14.2955L8.3134 13.4873L10.5897 14.2955V15.2247ZM15.956 8.02749C15.956 8.02749 15.8515 11.09 14.2625 12.9485C13.5863 12.5966 13.3223 12.4536 13.3223 12.4536V9.94639L15.0598 7.73058L15.956 8.02749ZM10.5897 0.692784C12.5691 1.02268 14.6034 3.76082 14.6034 3.76082L14.2186 4.53058L11.7828 3.88728L10.2323 1.19313C10.2323 1.19863 10.2323 1.19863 10.5897 0.692784ZM10.3643 5.56426L11.4309 8.71478L8.3134 10.7821L5.7622 8.57732L6.72439 5.34983L10.3643 5.56426ZM6.28453 0.461856C6.85635 1.09416 6.85635 1.09416 6.85635 1.09416L5.65773 3.37045L2.8646 3.88728L2.17182 3.00756C2.17732 3.00756 4.63505 0.714777 6.28453 0.461856ZM0.247423 7.51615L1.65498 7.28522L2.89759 9.25911V12.0632C2.89759 12.0632 2.89759 12.0632 1.77594 12.6021C-0.241925 10.3313 0.247423 7.51615 0.247423 7.51615Z"
				fill="white"
			/>
		</Svg>
	)
}

export default ActiveIcon
