import styled from 'styled-components'
import { Box, Flex } from '@footdex2022/uikit'
// import HoverConnect from "../../assets/hoverConnect.png";
import BgHover from '../../../assets/imgQuata/Home/backgroundConnecWalletItem.svg'
import Bg from '../../../assets/imgQuata/Home/ConnectWalletBG.svg'

export const BoxModal = styled(Box)`
	width: 100%;
	max-width: 401px;
	margin-top: 80px;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	z-index: 100;
	background: #24272a;
	box-shadow: rgb(14 14 44 / 10%) 0px 20px 36px -8px,
		rgb(0 0 0 / 5%) 0px 1px 1px;
	border-radius: 18px;
	background-image: url('${Bg}');
	background-repeat: no-repeat;
	background-position: center bottom;

	.cus-box-header {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	@media only screen and (max-width: 570px) {
		margin-top: 40px;
		max-width: 350px;
		min-width: 350px;
	}

	.none-pb {
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 0;
	}

	img {
		max-width: 100%;
	}

	.text-custom {
		font-family: Monda !important;
		color: #fff;
		font-weight: 600;
		line-height: 1.5;
		font-size: 16px;
		background-color: transparent;
		padding: 8px;
		border-radius: 10px;
		display: flex;
		gap: 14px;
		cursor: pointer;
		margin-bottom: 20px;
		padding-right: 20px;
		padding-left: 20px;
		position: relative;
		height: 62px;
		border: 1px solid #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 90%;
		margin: 0 auto;
		margin-bottom: 20px;
	}
`

export const BoxPd = styled(Box)`
	/* padding: 10px; */
	padding-top: 0;
	padding-bottom: 50px;
	position: relative;

	:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 20%;
		background-repeat: no-repeat;
		background-size: cover;
	}
`

export const BoxPdDisconnect = styled(Box)`
	/* padding: 10px; */
	padding-top: 50px;
	padding-bottom: 50px;
	position: relative;
	z-index: 11;

	:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 60%;
		background-repeat: no-repeat;
		background-size: cover;
		z-index: -1;
	}
`

export const BoxModalContent = styled(Box)`
	border-radius: 10px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	& > * {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
		padding-left: 20px;
	}

	.wallet-flex {
		transition: 0.3s ease-in-out;
		padding: 0 8px;
		min-width: 358px;
		border: 1px solid #ffffff;
		border-radius: 10px;
		background: transparent;
		height: 100%;
		background-image: transparent;
		transition: 0.3s ease;
		cursor: pointer;

		:hover {
			background-image: url(${BgHover});
		}
	}

	@media only screen and (max-width: 570px) {
		& > * {
			width: 100%;
		}
		.wallet-flex {
			min-width: 280px;
		}
	}
`

export const BoxModalDisConnect = styled(Box)`
	flex-direction: column;
	min-width: 401px;
	padding-top: 24px;
	padding-bottom: 24px;
	overflow: hidden;
	& > * {
		width: 100%;
		justify-content: center;
		display: flex;
	}

	.btn-connect {
		margin: 0 20px;
		width: 165px;
		height: 50px;
		background: linear-gradient(275.2deg, #37abff 1.74%, #8a3ffc 97.49%),
			radial-gradient(
				63.11% 63.11% at 31.97% 19.67%,
				hsla(0, 0%, 100%, 0.7) 0,
				hsla(0, 0%, 100%, 0) 69.79%,
				hsla(0, 0%, 100%, 0) 100%
			),
			#2f80ed;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		cursor: pointer;
		transition: all 0.1s ease-in-out;
		border: none;
		color: #fff;
		text-align: center;
		font-size: 16px;
		font-weight: 700;
		line-height: 110%;
	}

	.noti-out-text {
		font-weight: 500;
		text-align: center;
		font-size: 22px;
		color: #fffdfd;
		padding-bottom: 20px;
	}

	.cus-btn {
		width: 80%;
		background: transparent;
		border: 1px solid #fff;
		color: #fff;
	}

	@media only screen and (max-width: 570px) {
		min-width: auto;
	}
`

export const ModalText = styled.h1`
	font-family: Monda;
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 120%;
	color: #fff;
	text-shadow: 0 1px 1px rgb(0 0 0 / 25%);

	@media only screen and (max-width: 570px) {
		font-size: 24px;
	}
`

export const BoxHeader = styled(Flex)`
	padding: 40px 16px 24px 30px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;

	button {
		border: none;
		outline: none;
		background-color: transparent;
		color: #000;
		font-weight: bold;
		font-size: 18px;
		cursor: pointer;
		position: absolute;
		top: 10px;
		right: 0px;
	}

	@media only screen and (max-width: 570px) {
		padding: 12px;
	}
`

export const CloseModalIcon = styled.div`
	width: 18px;
	height: 18px;
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
	:before,
	:after {
		content: '';
		width: 100%;
		height: 4px;
		position: absolute;
		top: 50%;
		left: 50%;
		background-color: #fff;
	}
	:before {
		transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
	}
	:after {
		transform: translateX(-50%) translateY(-50%) rotateZ(-45deg);
	}
`

export const PlayGameBoXConnect = styled(Flex)`
	height: 80%;
	aspect-ratio: 1/0.59314028092;
	position: relative;
	flex-wrap: wrap;
	background: #24272a;
	justify-content: center;
	background-image: url('${Bg}');
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;
	border-radius: 5%;
`
export const PlayGameBoXConnectHeader = styled(Flex)`
	width: 100%;
	height: fit-content;
	flex-wrap: wrap;
	justify-content: center;
	img {
		max-width: 15%;
	}
`
export const PlayGameBoXConnectHeaderTitle = styled(Flex)`
	width: 100%;
	font-size: 7vh;
	justify-content: center;
	font-family: Monda;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	color: #fff;
	text-shadow: 0 1px 1px rgb(0 0 0 / 25%);
`
export const PlayGameConnecBody = styled(Flex)`
	width: 100%;
	padding-top: 0;
	position: relative;
	justify-content: space-between;
	align-items: center;

	:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 20%;
		background-repeat: no-repeat;
		background-size: cover;
	}
`

export const PlayGameBoxModalContent = styled(Flex)`
	flex-wrap: wrap;
	justify-content: space-between;
	padding-left: 5%;
	padding-right: 5%;
`

export const PlayGameConnecBodyItem = styled(Flex)`
	width: 47.5%;
	aspect-ratio: 1/0.24270310833;
	border: 1vh solid rgba(255 , 255, 255, 1);
	border-radius: 4%/16%;
	:hover {
		background-image: url(${BgHover});
	}
	svg{
		height: 100%;
		max-height: 100%;
		padding: 2% 2%;
		aspect-ratio: 1/1;
	}
	p{
		flex:1;
		text-align: center;
		font-family: Monda;
		font-weight: 700;
		font-size: 4.5vh;
	}
	margin-bottom: 2.5%;
`
export const BoxPdDisconnectPlayGame = styled(Flex)`
	width: 100%;
	flex-wrap: wrap;
	justify-content: center;
	.text-custom{
		width: 100%;
	}
`