import axios from 'axios'

export const DOMAIN_RACA = 'https://api.footdex.io'

const Api = axios.create({
	baseURL: DOMAIN_RACA,
})

Api.interceptors.request.use(
	function (config) {
		const toKenUser = localStorage.getItem('user') || ''
		config.headers = {
			...config.headers,
			'FBD-User-Token': toKenUser,
			// 'RACAFI-User-Token': toKenUser,
		}
		return config
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error)
	},
)

export { Api }
