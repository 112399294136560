import React, { createContext, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Overlay } from "./Overlay";
import { Handler } from "./types";

// khai bao kieu du lieu
interface ModalsContext {
  isOpen: boolean;
  nodeId: string;
  modalNode: React.ReactNode;
  setModalNode: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  onPresent: (node: React.ReactNode, newNodeId: string) => void;
  onDismiss: Handler;
  setCloseOnOverlayClick: React.Dispatch<React.SetStateAction<boolean>>;
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
    /* visibility: hidden; */

  }
  100% {
    opacity: 1;
    /* visibility: visible; */
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  will-change: opacity;
  /* opacity: 0; */
  /* visibility: hidden; */
  transform: translateX(0px);
  animation: 0.3s ease-in-out 0s 1 normal forwards running ${fadeIn};
  transition: opacity 0.3s ease-in-out;
`;

// set kieu du lieu defaut
export const Context = createContext<ModalsContext>({
  isOpen: false,
  nodeId: "",
  modalNode: null,
  setModalNode: () => null,
  onPresent: () => null,
  onDismiss: () => null,
  setCloseOnOverlayClick: () => true,
});

const ModalProviderCusttom = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalNode, setModalNode] = useState<React.ReactNode>();
  const [nodeId, setNodeId] = useState("");
  const [closeOnOverlayClick, setCloseOnOverlayClick] = useState(true);

  const handlePresent = (node: React.ReactNode, newNodeId: string) => {
    setModalNode(node);
    setIsOpen(true);
    setNodeId(newNodeId);
  };

  // console.log("modalNode >>>>",modalNode)
  // console.log("nodeId >>>>",nodeId)

  // close modal
  const handleDismiss = () => {
    setModalNode(undefined);
    setIsOpen(false);
    setNodeId("");
  };

  // nhap vao lop ovelay ben ngoai  => dong modal
  const handleOverlayDismiss = () => {
    if (closeOnOverlayClick) {
      handleDismiss();
    }
  };

  return (
    <Context.Provider
      value={{
        isOpen,
        nodeId,
        modalNode,
        setModalNode,
        onPresent: handlePresent,
        onDismiss: handleDismiss,
        setCloseOnOverlayClick,
      }}
    >
      {isOpen && (
        <ModalWrapper>
          <Overlay onClick={handleOverlayDismiss} />
          {React.isValidElement(modalNode) &&
            React.cloneElement(modalNode, {
              //@ts-ignore
              onDismiss: handleDismiss,
            })}
        </ModalWrapper>
      )}
      {children}
    </Context.Provider>
  );
};

export default ModalProviderCusttom;

// sd o file global index.tsx
