import React from 'react'
import { SvgProps, Svg } from '@footdex2022/uikit'

const ActiveIcon: React.FC<SvgProps> = (props) => {
	return (
		<Svg viewBox="0 0 16 16" {...props}>
			<g clipPath="url(#clip0_122_1351)">
				<path
					d="M8.0026 1.33594C4.31594 1.33594 1.33594 4.31594 1.33594 8.0026C1.33594 11.6893 4.31594 14.6693 8.0026 14.6693C11.6893 14.6693 14.6693 11.6893 14.6693 8.0026C14.6693 4.31594 11.6893 1.33594 8.0026 1.33594ZM11.3359 10.3959L10.3959 11.3359L8.0026 8.9426L5.60927 11.3359L4.66927 10.3959L7.0626 8.0026L4.66927 5.60927L5.60927 4.66927L8.0026 7.0626L10.3959 4.66927L11.3359 5.60927L8.9426 8.0026L11.3359 10.3959Z"
					fill="black"
				/>
			</g>
			<defs>
				<clipPath id="clip0_122_1351">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</Svg>
	)
}

export default ActiveIcon
