import React from 'react'

const TwitterIcon = (props:any) => {
	return (
		<svg
			width="23"
			height="20"
			viewBox="0 0 23 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22.706 2.93708C21.871 3.30708 20.974 3.55708 20.031 3.67008C21.004 3.08787 21.7319 2.17154 22.079 1.09208C21.1648 1.63507 20.1643 2.01727 19.121 2.22208C18.4194 1.47294 17.4901 0.976403 16.4773 0.809551C15.4646 0.642699 14.4251 0.814868 13.5202 1.29933C12.6154 1.78379 11.8958 2.55344 11.4732 3.48878C11.0505 4.42412 10.9485 5.47283 11.183 6.47208C9.33069 6.37907 7.51863 5.89763 5.86442 5.05898C4.21022 4.22034 2.75084 3.04324 1.581 1.60408C1.181 2.29408 0.951 3.09408 0.951 3.94608C0.950554 4.71307 1.13943 5.46832 1.50088 6.14481C1.86232 6.8213 2.38516 7.39812 3.023 7.82408C2.28328 7.80054 1.55987 7.60066 0.913 7.24108V7.30108C0.912925 8.37682 1.28503 9.41945 1.96618 10.2521C2.64733 11.0847 3.59557 11.656 4.65 11.8691C3.96378 12.0548 3.24434 12.0821 2.546 11.9491C2.8435 12.8747 3.423 13.6841 4.20337 14.264C4.98374 14.8439 5.92592 15.1653 6.898 15.1831C5.24783 16.4785 3.20989 17.1812 1.112 17.1781C0.740381 17.1782 0.369076 17.1565 0 17.1131C2.12948 18.4823 4.60834 19.2089 7.14 19.2061C15.71 19.2061 20.395 12.1081 20.395 5.95208C20.395 5.75208 20.39 5.55008 20.381 5.35008C21.2923 4.69105 22.0789 3.87497 22.704 2.94008L22.706 2.93708Z"
				fill={props.color}
			/>
		</svg>
	)
}
export default TwitterIcon
