import tokens from './tokens'

const poolsConfig = [
	// typePool = 1 --> TOP GOAL SCORER
	// typePool = 2 --> BEST GOALKEEPER
	// typePool = 4 --> GROUP TOTAL
	// typePool = 5 --> CHAMPION
	// typePool = 6 --> Betting group stage
	// typePool = 3 --> Godlen ball
	// typeGetPrice: 1 token stake no price
	// typeGetPrice: 2 token earn no price
	// typeGetPrice: 3 token stake + earn no price

	// {
	// 	sousId: 0,
	// 	stakingToken: tokens.Lukaku,
	// 	earningToken: tokens.Lukaku,
	// 	// addressRecivie: '0xb662484370D509402960c9Ce2CAFccBdE2368a39',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 771000000000000000,
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 1,
	// 	typeGetPrice: 3,
	// },
	{
		sousId: 1,
		stakingToken: tokens.KarimBenzema,
		earningToken: tokens.KarimBenzema,
		// addressRecivie: '0x2dC1790CBDE6bfB04a136489169E9733edaC6D4c',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 1,
		typeGetPrice: 3,
	},
	{
		sousId: 2,
		stakingToken: tokens.RobertLewandowski,
		earningToken: tokens.RobertLewandowski,
		// addressRecivie: '0x1FDB4c40BC6BC0aF06FC0cDd23bc8F5A5ce02A85',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 1,
		typeGetPrice: 3,
	},
	{
		sousId: 3,
		stakingToken: tokens.RafaelLeao,
		earningToken: tokens.RafaelLeao,
		// addressRecivie: '0xe3E97b57013f0a07dBB1091Ba2FCeBEd8631510e',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 1,
		typeGetPrice: 3,
	},
	{
		sousId: 4,
		stakingToken: tokens.KylianMbappe,
		earningToken: tokens.KylianMbappe,
		// addressRecivie: '0x3748577B9e5139b3981ba1AA5E7897355a26604E',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 1,
		typeGetPrice: 3,
	},
	// {
	// 	sousId: 5,
	// 	stakingToken: tokens.LuisSuarez,
	// 	earningToken: tokens.LuisSuarez,
	// 	// addressRecivie: '0x70C4dd87888F303b340736b66295d6721F4CebDE',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 1,
	// 	typeGetPrice: 3,
	// },
	{
		sousId: 6,
		stakingToken: tokens.CristianoRonaldo,
		earningToken: tokens.CristianoRonaldo,
		// addressRecivie: '0x9366AC8eCF30D193f63a3461eEF7c5506203C0F1',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 1,
		typeGetPrice: 3,
	},
	{
		sousId: 7,
		stakingToken: tokens.Neymar,
		earningToken: tokens.Neymar,
		// addressRecivie: '0x1a912eB35BDC479EAd9bc9EeD4A0b898a21724dC',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 1,
		typeGetPrice: 3,
	},
	{
		sousId: 8,
		stakingToken: tokens.LukaModric,
		earningToken: tokens.LukaModric,
		// addressRecivie: '0x9b299F39b6292D302f747347186c2fDdF7cc6b81',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 1,
		typeGetPrice: 3,
	},
	// {
	// 	sousId: 9,
	// 	stakingToken: tokens.ThomasMuller,
	// 	earningToken: tokens.ThomasMuller,
	// 	// addressRecivie: '0x1cCdD8D322f89EcB53D61D1f9D3738703a8aCd5F',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 1,
	// 	typeGetPrice: 3,
	// },

	{
		sousId: 10,
		stakingToken: tokens.LionelMessi,
		earningToken: tokens.LionelMessi,
		// addressRecivie: '0x1cCD4e49633BFF11031E0374037c22c1092a1039',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 1,
		typeGetPrice: 3,
	},

	// {
	// 	sousId: 11,
	// 	stakingToken: tokens.GarethBale,
	// 	earningToken: tokens.GarethBale,
	// 	// addressRecivie: '0x7f941324EF9259b06d833a5649205fC01bB976E8',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 1,
	// 	typeGetPrice: 3,
	// },

	{
		sousId: 12,
		stakingToken: tokens.JadonSancho,
		earningToken: tokens.JadonSancho,
		// addressRecivie: '0xED19173E8E31CC9f93dE21363B39De996D51B603',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 1,
		typeGetPrice: 3,
	},

	{
		sousId: 13,
		stakingToken: tokens.MarcusRashford,
		earningToken: tokens.MarcusRashford,
		// addressRecivie: '0x8E798113D6FB1d5494aD6d64C3E0cc5039b66786',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 1,
		typeGetPrice: 3,
	},

	{
		sousId: 14,
		stakingToken: tokens.HarryKane2,
		earningToken: tokens.HarryKane2,
		// addressRecivie: '0x79e4b60b4B360959dddF3279C4f274C578FE8064',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 3,
		typeGetPrice: 3,
	},

	// {
	// 	sousId: 15,
	// 	stakingToken: tokens.PaulPogba,
	// 	earningToken: tokens.PaulPogba,
	// 	// addressRecivie: '0x95b1aF8aD1aABDa4E0523113E1b71aD8378e5F59',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 1,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 16,
	// 	stakingToken: tokens.NGoloKante,
	// 	earningToken: tokens.NGoloKante,
	// 	// addressRecivie: '0xe5EBf18927685c63930Cf749A91978C776be2986',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 1,
	// 	typeGetPrice: 3,
	// },

	// them ngay 10/6
	// {
	// 	sousId: 17,
	// 	stakingToken: tokens.EdinsonCavani,
	// 	earningToken: tokens.EdinsonCavani,
	// 	// addressRecivie: '0xD728FB32C1aD37E1F2e6768ed669bdf5E0ff37f6',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 1,
	// 	typeGetPrice: 3,
	// },
	// {
	// 	sousId: 18,
	// 	stakingToken: tokens.SonHeungMin,
	// 	earningToken: tokens.SonHeungMin,
	// 	// addressRecivie: '0xD8AE04ba33cea2Da34469Baeb2C37E32E14b1F88',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 1,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 19,
	// 	stakingToken: tokens.ToniKroos,
	// 	earningToken: tokens.ToniKroos,
	// 	// addressRecivie: '0xCf9F222F7Ca1d89e54f98a411E05524bFB97E042',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 1,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 20,
	// 	stakingToken: tokens.DavidDeGea,
	// 	earningToken: tokens.DavidDeGea,
	// 	// addressRecivie: '0x22898929860512e7ef6BEb653FB959011862945e',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 2,
	// 	typeGetPrice: 3,
	// },
	// {
	// 	sousId: 21,
	// 	stakingToken: tokens.ThibautCourtois,
	// 	earningToken: tokens.ThibautCourtois,
	// 	// addressRecivie: '0xaFE9C8eb406E76a6EA8D8e3d5EFF17e1432596ee',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 2,
	// 	typeGetPrice: 3,
	// },
	{
		sousId: 22,
		stakingToken: tokens.ManuelNeuer,
		earningToken: tokens.ManuelNeuer,
		// addressRecivie: '0x6B85E3BDCb67e89B495369F163B8cACb9557e185',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 2,
		typeGetPrice: 3,
	},
	{
		sousId: 23,
		stakingToken: tokens.JordanPickford,
		earningToken: tokens.JordanPickford,
		// addressRecivie: '0xFDdef5BAff19A0C3dBf5246fcA0F491e0f025bC4',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 2,
		typeGetPrice: 3,
	},
	{
		sousId: 24,
		stakingToken: tokens.HugoLloris,
		earningToken: tokens.HugoLloris,
		// addressRecivie: '0xe4DFcEeDa2becE062F1EF93cD6B7AE22f423532b',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 2,
		typeGetPrice: 3,
	},

	// {
	// 	sousId: 25,
	// 	stakingToken: tokens.Ecuador2,
	// 	earningToken: tokens.Ecuador2,
	// 	// addressRecivie: '0xE9C28391234fbDEd588B5e5D4072B223FBC79fB5',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 21985601,
	// 	tokenPerBlock: 771000000000000000,
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	{
		sousId: 26,
		stakingToken: tokens.England2,
		earningToken: tokens.England2,
		// addressRecivie: '0xf979E3Fff17C04aF6c032e776693Bd173a0f9419',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 77006753,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 5,
		typeGetPrice: 3,
	},
	{
		sousId: 27,
		stakingToken: tokens.Netherlands2,
		earningToken: tokens.Netherlands2,
		// addressRecivie: '0x94B4577b9F157ba05F12C2F2E90e4c8A97903da2',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 5,
		typeGetPrice: 3,
	},

	// {
	// 	sousId: 28,
	// 	stakingToken: tokens.Qatar2,
	// 	earningToken: tokens.Qatar2,
	// 	// addressRecivie: '0x98b61577C04C9c3333d90a0dBe893031Fbc48C59',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	{
		sousId: 29,
		stakingToken: tokens.Brazill2,
		earningToken: tokens.Brazill2,
		// addressRecivie: '0x6F4f774CB6705A6dfBdFCc8e5884C3c9f5966680',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 5,
		typeGetPrice: 3,
	},

	{
		sousId: 30,
		stakingToken: tokens.France2,
		earningToken: tokens.France2,
		// addressRecivie: '0x57AbAF6Ad65eFC6A00649a733Aed55D18f73716a',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 5,
		typeGetPrice: 3,
	},

	// {
	// 	sousId: 31,
	// 	stakingToken: tokens.Iran2,
	// 	earningToken: tokens.Iran2,
	// 	// addressRecivie: '0xE2fAcCE2Db8EdD308eDB6C03B50608a6D109b961',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 32,
	// 	stakingToken: tokens.Senegal2,
	// 	earningToken: tokens.Senegal2,
	// 	// addressRecivie: '0xAe6ab225442D15269a54f7F6ABE9909d6fd758CF',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 33,
	// 	stakingToken: tokens.UnitedStates2,
	// 	earningToken: tokens.UnitedStates2,
	// 	// addressRecivie: '0x5bfF2f23dc15A54468EEDF0F34a8B4f431ec854C',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	{
		sousId: 34,
		stakingToken: tokens.Argentina2,
		earningToken: tokens.Argentina2,
		// addressRecivie: '0x8047b72477357978A752B8412c9B201732426386',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 5,
		typeGetPrice: 3,
	},

	// {
	// 	sousId: 35,
	// 	stakingToken: tokens.Wales2,
	// 	earningToken: tokens.Wales2,
	// 	// addressRecivie: '0x4E043C998f3b4F82327ffC6785eEEB4F53Dc562d',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 36,
	// 	stakingToken: tokens.SaudiArabic2,
	// 	earningToken: tokens.SaudiArabic2,
	// 	// addressRecivie: '0x499aBC5bcc54834538b2c2DE1600e916679C18A9',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	{
		sousId: 37,
		stakingToken: tokens.Croatia2,
		earningToken: tokens.Croatia2,
		// addressRecivie: '0x987e135f90ea6C432aDa0632e81A9FE5625eeEfC',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 5,
		typeGetPrice: 3,
	},

	{
		sousId: 38,
		stakingToken: tokens.SWITZERLAND2,
		earningToken: tokens.SWITZERLAND2,
		// addressRecivie: '0x7C2a7B2c891727F9b3001865f931Ed49C9B57FC9',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 5,
		typeGetPrice: 3,
	},

	// {
	// 	sousId: 39,
	// 	stakingToken: tokens.Poland2,
	// 	earningToken: tokens.Poland2,
	// 	// addressRecivie: '0x1e90392B0B4546D62F2f388573675d5758510efE',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 40,
	// 	stakingToken: tokens.Australia2,
	// 	earningToken: tokens.Australia2,
	// 	// addressRecivie: '0x9Befb2b2A17114EAC70657B2d4919900D0ae6C76',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 41,
	// 	stakingToken: tokens.denmark2,
	// 	earningToken: tokens.denmark2,
	// 	// addressRecivie: '0x8Ba4e133B0ff714485814E872C062a550946D477',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 42,
	// 	stakingToken: tokens.Tunisian2,
	// 	earningToken: tokens.Tunisian2,
	// 	// addressRecivie: '0xB67c718110FFA7b9445A9BDFA42410BFe83f9F93',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	{
		sousId: 43,
		stakingToken: tokens.Spain2,
		earningToken: tokens.Spain2,
		// addressRecivie: '0x039638c9E87b5DC3E163406cBB610187313F6002',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 5,
		typeGetPrice: 3,
	},

	// {
	// 	sousId: 44,
	// 	stakingToken: tokens.CostaRica2,
	// 	earningToken: tokens.CostaRica2,
	// 	// addressRecivie: '0x4faCD355Ad10080a68bA112fD1F8f5B4AfD35BF9',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 45,
	// 	stakingToken: tokens.Germany2,
	// 	earningToken: tokens.Germany2,
	// 	// addressRecivie: '0x9fbB1697f49866bAff61e63Fe6A125668f82D0D0',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 46,
	// 	stakingToken: tokens.Belgium2,
	// 	earningToken: tokens.Belgium2,
	// 	// addressRecivie: '0xCf3D6b7BB6581cE9a2D23b3A05463a16c04C8487',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 47,
	// 	stakingToken: tokens.Cameroon2,
	// 	earningToken: tokens.Cameroon2,
	// 	// addressRecivie: '0xA336E5C3917803c12f9318C3dc0Dec19225f43CF',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 48,
	// 	stakingToken: tokens.CANADA2,
	// 	earningToken: tokens.CANADA2,
	// 	// addressRecivie: '0xdDD1096Dafb3C8599768364587A9918a87443997',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	{
		sousId: 49,
		stakingToken: tokens.Ghana2,
		earningToken: tokens.Ghana2,
		// addressRecivie: '0x2E95B60e543f577c267fc562ea38bbD2292D6182',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 5,
		typeGetPrice: 3,
	},

	{
		sousId: 50,
		stakingToken: tokens.Japan2,
		earningToken: tokens.Japan2,
		// addressRecivie: '0x06078c4E86fa9C926Ce468c9e5561cDdBeb0669B',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 5,
		typeGetPrice: 3,
	},

	{
		sousId: 51,
		stakingToken: tokens.Korea2,
		earningToken: tokens.Korea2,
		// addressRecivie: '0x244DC34956A346c86947a0397b4EB7e8Fbe1114c',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 5,
		typeGetPrice: 3,
	},

	{
		sousId: 52,
		stakingToken: tokens.Morocco2,
		earningToken: tokens.Morocco2,
		// addressRecivie: '0xE8d3a4FEf38BDA4ffD04949e10463488A41eb949',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 5,
		typeGetPrice: 3,
	},

	{
		sousId: 53,
		stakingToken: tokens.Potugal2,
		earningToken: tokens.Potugal2,
		// addressRecivie: '0x7D9f6e7D7d6175fB448af8BA4f48bd96ebdc89Fa',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 5,
		typeGetPrice: 3,
	},

	// {
	// 	sousId: 54,
	// 	stakingToken: tokens.Serbia2,
	// 	earningToken: tokens.Serbia2,
	// 	// addressRecivie: '0x54C30E720034cA0e711e748c7F0e35f57928F0cE',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 55,
	// 	stakingToken: tokens.Uruguay2,
	// 	earningToken: tokens.Uruguay2,
	// 	// addressRecivie: '0xf81ed3E984A1c6E8014F6586412dC7943eE9AEcE',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	{
		sousId: 56,
		stakingToken: tokens.Net,
		earningToken: tokens.Net,
		// addressRecivie: '0xfdB9c7B315fbf7276e82474025fA0871068Edf74',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23250906,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 57,
		stakingToken: tokens.Sen,
		earningToken: tokens.Sen,
		// addressRecivie: '0x70B0D2153a6AB6b92518D368bEa6d71D052601A9',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23250906,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 58,
		stakingToken: tokens.Qata,
		earningToken: tokens.Qata,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23222483,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 59,
		stakingToken: tokens.Ecu,
		earningToken: tokens.Ecu,
		// addressRecivie: '0xA992e83A3dD153b89c62aCd9FaA7aef51e1b46C0',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23222483,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 60,
		stakingToken: tokens.End,
		earningToken: tokens.End,
		// addressRecivie: '0x6e9181441076d89Cf50c5a0facE9d5f33866D59e',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23247456,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 61,
		stakingToken: tokens.Ira,
		earningToken: tokens.Ira,
		// addressRecivie: '0xEAb47ee46F6BbBb604cE917F45fdf5Fb219c6fC0',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23247456,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 62,
		stakingToken: tokens.Usa,
		earningToken: tokens.Usa,
		// addressRecivie: '0xAc81eD2FE26028b281EF91da2ce31a438C8a122e',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23254406,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 63,
		stakingToken: tokens.Wal,
		earningToken: tokens.Wal,
		// addressRecivie: '0x68CD8d23013a617AE3199Fe5f19483c7561ffb3e',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23254406,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 64,
		stakingToken: tokens.Arg,
		earningToken: tokens.Arg,
		// addressRecivie: '0x218A0F765C962cFA2FbB76B74a6A0D4a0a4037aA',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23272283,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 65,
		stakingToken: tokens.Sau,
		earningToken: tokens.Sau,
		// addressRecivie: '0xa95efbe8F2F887713C889b9716CCB478babAC48b',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23272283,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 66,
		stakingToken: tokens.Mex,
		earningToken: tokens.Mex,
		// addressRecivie: '0xAB723FDF6C0C6695B61EA236b0a25A5c8d41eB2e',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23279483,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 67,
		stakingToken: tokens.Pol,
		earningToken: tokens.Pol,
		// addressRecivie: '0x1Bc95ca934236E8Cb3706a67177b6A62172f888F',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23279483,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 68,
		stakingToken: tokens.Den,
		earningToken: tokens.Den,
		// addressRecivie: '0x8F3FBA3Ece3c75E198725D0d84a43872A0a925Aa',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23275883,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 69,
		stakingToken: tokens.Tun,
		earningToken: tokens.Tun,
		// addressRecivie: '0x5C595c7A5F06f2055B54f44992CbdbC23BFD818f',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23275883,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 70,
		stakingToken: tokens.Fra,
		earningToken: tokens.Fra,
		// addressRecivie: '0x3EC11Ee4fc5c18a7A833a986B531658191A47145',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23283083,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 72,
		stakingToken: tokens.Aus,
		earningToken: tokens.Aus,
		// addressRecivie: '0xaccF8cd206aFfb93cC17083C75b5B89A2748eBCb',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23283083,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 73,
		stakingToken: tokens.Ger,
		earningToken: tokens.Ger,
		// addressRecivie: '0x870b16bf15b56350169A59A0e34417C6D943CDE2',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23304060,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 74,
		stakingToken: tokens.Jap,
		earningToken: tokens.Jap,
		// addressRecivie: '0x194120707ad1A017462350B19321f9bC597B5552',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23304060,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 75,
		stakingToken: tokens.Spa,
		earningToken: tokens.Spa,
		// addressRecivie: '0x83Ce6D6eA523b792a74537717307cF737078C25a',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23307530,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 76,
		stakingToken: tokens.Cos,
		earningToken: tokens.Cos,
		// addressRecivie: '0xd8FE937109AB3adA69e76E7D8B6734d8EA61bdB4',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23307530,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 77,
		stakingToken: tokens.Mor,
		earningToken: tokens.Mor,
		// addressRecivie: '0x7bffb85DAF67b1523884d4F3c40F5Ae6428A99BF',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23300560,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 78,
		stakingToken: tokens.Cro,
		earningToken: tokens.Cro,
		// addressRecivie: '0x74A5856935F576fE244DE87b461cFeE1B46b4a15',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23300560,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 79,
		stakingToken: tokens.Bel,
		earningToken: tokens.Bel,
		// addressRecivie: '0x1AA4E2d2eE2cc3323F8c9567Ce8C88241EeFA5e4',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23309830,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 80,
		stakingToken: tokens.Can,
		earningToken: tokens.Can,
		// addressRecivie: '0x474Aca402BB75d3Ab457B382BcB09ebFB640D500',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23309830,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 81,
		stakingToken: tokens.Uru,
		earningToken: tokens.Uru,
		// addressRecivie: '0xd26872FA7558943A8f240E008dcB51c6AfAE00c6',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23332633,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 82,
		stakingToken: tokens.Kor,
		earningToken: tokens.Kor,
		// addressRecivie: '0x5877F55E6afb94AC4680eae880AD46351Fc7A8dA',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23332633,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 83,
		stakingToken: tokens.Por,
		earningToken: tokens.Por,
		// addressRecivie: '0x2f83dd9d213B610a25D9B1454154A73E705A5c7A',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23336233,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 84,
		stakingToken: tokens.Gha,
		earningToken: tokens.Gha,
		// addressRecivie: '0x131Fe95e847Bd2c145e81a80C6F2898c85D5Df61',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23336233,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	// {
	// 	sousId: 85,
	// 	stakingToken: tokens.Mexico2,
	// 	earningToken: tokens.Mexico2,
	// 	// addressRecivie: '0x8047b72477357978A752B8412c9B201732426386',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 85,
	// 	stakingToken: tokens.Mexico,
	// 	earningToken: tokens.Mexico,
	// 	// addressRecivie: '0xB3891f310fbbaA5fD8C89ECe109b9899aD99dEA8',
	// 	fees: 0,
	// 	blockPeriod: 86400,
	// 	startBlock: 22937903,
	// 	// closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 5,
	// 	typeGetPrice: 3,
	// },

	//Mexico

	// // type 3:
	// {
	// 	sousId: 86,
	// 	stakingToken: tokens.Lukaku,
	// 	earningToken: tokens.Lukaku,
	// 	// addressRecivie: '0x08341733BF19F5F6516E533a6DCe37EacbdACdA0',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 771000000000000000,
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },
	{
		sousId: 87,
		stakingToken: tokens.KarimBenzema2,
		earningToken: tokens.KarimBenzema2,
		// addressRecivie: '0x7117A31ea17E71d04c8689b8070C40241046D7d8',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 3,
		typeGetPrice: 3,
	},
	// {
	// 	sousId: 88,
	// 	stakingToken: tokens.RobertLewandowski2,
	// 	earningToken: tokens.RobertLewandowski2,
	// 	// addressRecivie: '0x9b499b185F3b51219c790Fc0Ad307D72AD11E341',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },
	{
		sousId: 89,
		stakingToken: tokens.RafaelLeao2,
		earningToken: tokens.RafaelLeao2,
		// addressRecivie: '0x58fE3d1c71B69486Ccff51FCf627a0eD37999f5b',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 3,
		typeGetPrice: 3,
	},
	{
		sousId: 90,
		stakingToken: tokens.KylianMbappe2,
		earningToken: tokens.KylianMbappe2,
		// addressRecivie: '0x803f4D2448f4805E0b7858fD1cA65926052b5A44',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 3,
		typeGetPrice: 3,
	},
	// {
	// 	sousId: 91,
	// 	stakingToken: tokens.LuisSuarez,
	// 	earningToken: tokens.LuisSuarez,
	// 	// addressRecivie: '0x8A9106E0fBFC3FF61734B76B6d4c1D034a2AdbC0',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },
	{
		sousId: 92,
		stakingToken: tokens.CristianoRonaldo2,
		earningToken: tokens.CristianoRonaldo2,
		// addressRecivie: '0x82b9f2b718d7258ce16Ba7D33cc548D1825c269c',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 3,
		typeGetPrice: 3,
	},
	// {
	// 	sousId: 93,
	// 	stakingToken: tokens.Neymar,
	// 	earningToken: tokens.Neymar,
	// 	// addressRecivie: '0x881f59Ec9db258f3a344dcA034e548D4482C0ae5',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },
	{
		sousId: 94,
		stakingToken: tokens.LukaModric2,
		earningToken: tokens.LukaModric2,
		// addressRecivie: '0x03Fd59967F27Be6692fcD1Ce318b557FE6bE555A',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 3,
		typeGetPrice: 3,
	},
	// {
	// 	sousId: 95,
	// 	stakingToken: tokens.ThomasMuller,
	// 	earningToken: tokens.ThomasMuller,
	// 	// addressRecivie: '0x0432A089E5a403e9d7C150261D79960B91cb8D1e',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },

	{
		sousId: 96,
		stakingToken: tokens.LionelMessi2,
		earningToken: tokens.LionelMessi2,
		// addressRecivie: '0x3Cd67149196C03f34B93BAF3e9E1471aDBee8dAC',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 3,
		typeGetPrice: 3,
	},

	// {
	// 	sousId: 97,
	// 	stakingToken: tokens.GarethBale,
	// 	earningToken: tokens.GarethBale,
	// 	// addressRecivie: '0xF882C7DB18b2fbfb9e84BB3c6F4C2aa542096379',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 98,
	// 	stakingToken: tokens.JadonSancho,
	// 	earningToken: tokens.JadonSancho,
	// 	// addressRecivie: '0x51056668C20f22054f115206adf0744664c0b5B7',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 99,
	// 	stakingToken: tokens.MarcusRashford,
	// 	earningToken: tokens.MarcusRashford,
	// 	// addressRecivie: '0xa6d930789C507650d7136c67831DF6ffeD9857D8',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },

	{
		sousId: 100,
		stakingToken: tokens.Forden,
		earningToken: tokens.Forden,
		// addressRecivie: '0x225D7e5F018BD83b6991c216a3f3930437f668fF',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 1,
		typeGetPrice: 3,
	},

	// {
	// 	sousId: 101,
	// 	stakingToken: tokens.PaulPogba,
	// 	earningToken: tokens.PaulPogba,
	// 	// addressRecivie: '0x4Dbc41d3708D01C3026F03e2889732B2760c5C7a',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 102,
	// 	stakingToken: tokens.NGoloKante,
	// 	earningToken: tokens.NGoloKante,
	// 	// addressRecivie: '0xd81451926F8560Ad9a74dD27E61bf6070c8482df',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },

	// them ngay 10/6
	// {
	// 	sousId: 103,
	// 	stakingToken: tokens.EdinsonCavani,
	// 	earningToken: tokens.EdinsonCavani,
	// 	// addressRecivie: '0xCB0484fce393aF85c326a12c6388da2e6dD19BF5',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },
	// {
	// 	sousId: 104,
	// 	stakingToken: tokens.SonHeungMin,
	// 	earningToken: tokens.SonHeungMin,
	// 	// addressRecivie: '0xD83b2C757e9aFAc21Aab975cBa15C6B25c795674',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },

	// {
	// 	sousId: 105,
	// 	stakingToken: tokens.ToniKroos,
	// 	earningToken: tokens.ToniKroos,
	// 	// addressRecivie: '0x06742D57630d3EAf5958775399Dc178891ffE51A',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },

	// type = 6
	{
		sousId: 106,
		stakingToken: tokens.Ecuador,
		earningToken: tokens.Ecuador,
		// addressRecivie: '0x173764614ed1d305Fa30C64b8c359C7AF3CBe1EC',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 21985601,
		tokenPerBlock: 771000000000000000,
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 107,
		stakingToken: tokens.England,
		earningToken: tokens.England,
		// addressRecivie: '0x2aA93D4E94Eb3A7eF621E23895D885fe25405C2B',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 77006753,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},
	{
		sousId: 108,
		stakingToken: tokens.Netherlands,
		earningToken: tokens.Netherlands,
		// addressRecivie: '0xF6090122E42D25fF419DA13a96808577e81a1f02',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 109,
		stakingToken: tokens.Qatar,
		earningToken: tokens.Qatar,
		// addressRecivie: '0xc6cefe1fFb823015b3806e8605828DFf97b8C3bA',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 110,
		stakingToken: tokens.Brazill,
		earningToken: tokens.Brazill,
		// addressRecivie: '0x030277652c64229cA69e49c01e6c19b0e33842B4',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 111,
		stakingToken: tokens.France,
		earningToken: tokens.France,
		// addressRecivie: '0x79b25b1CFEeE49f6b4882D33bCd13B9c2793b551',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 112,
		stakingToken: tokens.Iran,
		earningToken: tokens.Iran,
		// addressRecivie: '0xD107a6a743AF72Ce93e207954f85E7805b8536c3',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 113,
		stakingToken: tokens.Senegal,
		earningToken: tokens.Senegal,
		// addressRecivie: '0x7881B3408E02A94D47a9ABA50E1A293Dc3DbDa5A',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 114,
		stakingToken: tokens.UnitedStates,
		earningToken: tokens.UnitedStates,
		// addressRecivie: '0x05b49c0aF6BB7C6aDB3Faaaabe9fA0Cc0897e324',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 115,
		stakingToken: tokens.Argentina,
		earningToken: tokens.Argentina,
		// addressRecivie: '0x38A636DA4AA096a48817692f60ff09a19DF9f906',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 116,
		stakingToken: tokens.Wales,
		earningToken: tokens.Wales,
		// addressRecivie: '0xf68cA2f83B787513167B986ac42A961f8dc9DB79',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 117,
		stakingToken: tokens.SaudiArabic,
		earningToken: tokens.SaudiArabic,
		// addressRecivie: '0xa4026d6EB8b0630184FB61fE4801cdc1f9B6548A',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 118,
		stakingToken: tokens.Croatia,
		earningToken: tokens.Croatia,
		// addressRecivie: '0x7B852F448046107df46c82d2e7159078aa4d1edF',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 119,
		stakingToken: tokens.SWITZERLAND,
		earningToken: tokens.SWITZERLAND,
		// addressRecivie: '0xC8474f78f13af51FF03c48c99F8E4186849566EA',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 120,
		stakingToken: tokens.Poland,
		earningToken: tokens.Poland,
		// addressRecivie: '0x3C3C7B0751E757E87E40d85bB5E35d1594c0f27D',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 121,
		stakingToken: tokens.Australia,
		earningToken: tokens.Australia,
		// addressRecivie: '0x9c22cF3CA8ca7f2e861704575A38593A93CFC726',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 122,
		stakingToken: tokens.denmark,
		earningToken: tokens.denmark,
		// addressRecivie: '0x053877297Cb684E1F9b3e7Ec2C4710D0Ec9D28Ec',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 123,
		stakingToken: tokens.Tunisian,
		earningToken: tokens.Tunisian,
		// addressRecivie: '0x736eA2D2746ea74B5C0a21D66314E149C5399718',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 124,
		stakingToken: tokens.Spain,
		earningToken: tokens.Spain,
		// addressRecivie: '0x98160Fc4b4eBCd50F70179f53A93f4801a400967',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 125,
		stakingToken: tokens.CostaRica,
		earningToken: tokens.CostaRica,
		// addressRecivie: '0x1fe4c2764E96AD0d1715Aee5c1bcfc7C56c2A6EB',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 126,
		stakingToken: tokens.Germany,
		earningToken: tokens.Germany,
		// addressRecivie: '0x7D47187741534b66ffb645DCbC1Cb772ff23CD38',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 127,
		stakingToken: tokens.Belgium,
		earningToken: tokens.Belgium,
		// addressRecivie: '0x2E40c0214Dbf31dDa933684640241E5318310242',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 128,
		stakingToken: tokens.Cameroon,
		earningToken: tokens.Cameroon,
		// addressRecivie: '0x8Af5BE06Ded8ca4D7A3b584BaE1F92D1FF827778',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 129,
		stakingToken: tokens.CANADA,
		earningToken: tokens.CANADA,
		// addressRecivie: '0x5F601ac332340b669C9EE668445378D289B1E670',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 130,
		stakingToken: tokens.Ghana,
		earningToken: tokens.Ghana,
		// addressRecivie: '0xfeD7eE87c410Cf1144Ed55c316BD6e893a28C01F',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 131,
		stakingToken: tokens.Japan,
		earningToken: tokens.Japan,
		// addressRecivie: '0xB8635bD9aA059027ac92a8b995D4dDcD1040BACe',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 132,
		stakingToken: tokens.Korea,
		earningToken: tokens.Korea,
		// addressRecivie: '0xaFe7989bb73809F8Bcb718434EEe8301101AFb1E',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 133,
		stakingToken: tokens.Morocco,
		earningToken: tokens.Morocco,
		// addressRecivie: '0xC36C4e467d779f3A8A39dE98a94590a3fD911955',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 134,
		stakingToken: tokens.Potugal,
		earningToken: tokens.Potugal,
		// addressRecivie: '0xd948E46da85417687E71338B544EC6b38DC025b5',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 135,
		stakingToken: tokens.Serbia,
		earningToken: tokens.Serbia,
		// addressRecivie: '0x5603797F17e03A5e2885De22BebF98D2aA9cC104',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	{
		sousId: 136,
		stakingToken: tokens.Uruguay,
		earningToken: tokens.Uruguay,
		// addressRecivie: '0xac12a1BEF50B82A23531f438a73C88f0D36B000f',
		fees: 0,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 6,
		typeGetPrice: 3,
	},

	// them ngay 10/11
	// {
	// 	sousId: 137,
	// 	stakingToken: tokens.WojciechSzczesny,
	// 	earningToken: tokens.WojciechSzczesny,
	// 	// addressRecivie: '0x6B85E3BDCb67e89B495369F163B8cACb9557e185',
	// 	fees: 0,
	// 	startBlock: 22938503,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 2,
	// 	typeGetPrice: 3,
	// },

	{
		sousId: 138,
		stakingToken: tokens.Alisson,
		earningToken: tokens.Alisson,
		// addressRecivie: '0x6B85E3BDCb67e89B495369F163B8cACb9557e185',
		fees: 0,
		startBlock: 22938503,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 2,
		typeGetPrice: 3,
	},

	// {
	// 	sousId: 139,
	// 	stakingToken: tokens.SadioMane,
	// 	earningToken: tokens.SadioMane,
	// 	// addressRecivie: '0x6B85E3BDCb67e89B495369F163B8cACb9557e185',
	// 	fees: 0,
	// 	startBlock: 22938503,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },

	{
		sousId: 140,
		stakingToken: tokens.ViniciusJunior,
		earningToken: tokens.ViniciusJunior,
		// addressRecivie: '0x6B85E3BDCb67e89B495369F163B8cACb9557e185',
		fees: 0,
		startBlock: 22938503,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 3,
		typeGetPrice: 3,
	},

	// {
	// 	sousId: 141,
	// 	stakingToken: tokens.KevinDeBruyne,
	// 	earningToken: tokens.KevinDeBruyne,
	// 	// addressRecivie: '0x6B85E3BDCb67e89B495369F163B8cACb9557e185',
	// 	fees: 0,
	// 	startBlock: 22938503,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },
	// {
	// 	sousId: 142,
	// 	stakingToken: tokens.KevinDeBruyne,
	// 	earningToken: tokens.KevinDeBruyne,
	// 	// addressRecivie: '0x6B85E3BDCb67e89B495369F163B8cACb9557e185',
	// 	fees: 0,
	// 	startBlock: 22938503,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 1,
	// 	typeGetPrice: 3,
	// },

	{
		sousId: 143,
		stakingToken: tokens.JosuaKimmich,
		earningToken: tokens.JosuaKimmich,
		// addressRecivie: '0x6B85E3BDCb67e89B495369F163B8cACb9557e185',
		fees: 0,
		startBlock: 22938503,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 3,
		typeGetPrice: 3,
	},
	{
		sousId: 144,
		stakingToken: tokens.Fabinho,
		earningToken: tokens.Fabinho,
		// addressRecivie: '0x6B85E3BDCb67e89B495369F163B8cACb9557e185',
		fees: 0,
		startBlock: 22938503,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 3,
		typeGetPrice: 3,
	},
	{
		sousId: 145,
		stakingToken: tokens.AlexanderArnold,
		earningToken: tokens.AlexanderArnold,
		// addressRecivie: '0x6B85E3BDCb67e89B495369F163B8cACb9557e185',
		fees: 0,
		startBlock: 22938503,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 3,
		typeGetPrice: 3,
	},
	// {
	// 	sousId: 146,
	// 	stakingToken: tokens.VirgilVanDijk,
	// 	earningToken: tokens.VirgilVanDijk,
	// 	// addressRecivie: '0x6B85E3BDCb67e89B495369F163B8cACb9557e185',
	// 	fees: 0,
	// 	startBlock: 22938503,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },
	// {
	// 	sousId: 147,
	// 	stakingToken: tokens.AntonioRudiger,
	// 	earningToken: tokens.AntonioRudiger,
	// 	// addressRecivie: '0x6B85E3BDCb67e89B495369F163B8cACb9557e185',
	// 	fees: 0,
	// 	startBlock: 22938503,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },
	{
		sousId: 148,
		stakingToken: tokens.BernardoSilva,
		earningToken: tokens.BernardoSilva,
		// addressRecivie: '0x6B85E3BDCb67e89B495369F163B8cACb9557e185',
		fees: 0,
		startBlock: 22938503,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 3,
		typeGetPrice: 3,
	},
	{
		sousId: 149,
		stakingToken: tokens.Casemiro,
		earningToken: tokens.Casemiro,
		// addressRecivie: '0x6B85E3BDCb67e89B495369F163B8cACb9557e185',
		fees: 0,
		startBlock: 22938503,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 3,
		typeGetPrice: 3,
	},

	{
		sousId: 150,
		stakingToken: tokens.Swi,
		earningToken: tokens.Swi,
		// addressRecivie: '0xfdB9c7B315fbf7276e82474025fA0871068Edf74',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23329033,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 151,
		stakingToken: tokens.Cam,
		earningToken: tokens.Cam,
		// addressRecivie: '0xfdB9c7B315fbf7276e82474025fA0871068Edf74',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23329033,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 152,
		stakingToken: tokens.Bra,
		earningToken: tokens.Bra,
		// addressRecivie: '0xfdB9c7B315fbf7276e82474025fA0871068Edf74',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23339833,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 153,
		stakingToken: tokens.Ser,
		earningToken: tokens.Ser,
		// addressRecivie: '0xfdB9c7B315fbf7276e82474025fA0871068Edf74',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23339833,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	// {
	// 	sousId: 154,
	// 	stakingToken: tokens.ThibautCourtois2,
	// 	earningToken: tokens.ThibautCourtois2,
	// 	// addressRecivie: '0xaFE9C8eb406E76a6EA8D8e3d5EFF17e1432596ee',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 3,
	// 	typeGetPrice: 3,
	// },
	// {
	// 	sousId: 155,
	// 	stakingToken: tokens.MarcoReus,
	// 	earningToken: tokens.MarcoReus,
	// 	// addressRecivie: '0xaFE9C8eb406E76a6EA8D8e3d5EFF17e1432596ee',
	// 	fees: 0,
	// 	startBlock: 22937903,
	// 	// // closeBlock: 29192327,
	// 	endBlock: 16308000648,
	// 	tokenPerBlock: 173600000000000000, // +0
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	disStake: false,
	// 	disHarvest: true,
	// 	userLock: true,
	// 	typePool: 1,
	// 	typeGetPrice: 3,
	// },

	{
		sousId: 156,
		stakingToken: tokens.Forden2,
		earningToken: tokens.Forden2,
		// addressRecivie: '0x225D7e5F018BD83b6991c216a3f3930437f668fF',
		fees: 0,
		startBlock: 22937903,
		// // closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 3,
		typeGetPrice: 3,
	},

	{
		sousId: 157,
		stakingToken: tokens.Qata2,
		earningToken: tokens.Qata2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23360798,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 158,
		stakingToken: tokens.Qata3,
		earningToken: tokens.Qata3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 159,
		stakingToken: tokens.Swi2,
		earningToken: tokens.Swi2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23449636,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 160,
		stakingToken: tokens.Swi3,
		earningToken: tokens.Swi3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 161,
		stakingToken: tokens.Cam2,
		earningToken: tokens.Cam2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23442456,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 162,
		stakingToken: tokens.Cam3,
		earningToken: tokens.Cam3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 163,
		stakingToken: tokens.Bra2,
		earningToken: tokens.Bra2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23449636,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 164,
		stakingToken: tokens.Bra3,
		earningToken: tokens.Bra3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 165,
		stakingToken: tokens.Ser2,
		earningToken: tokens.Ser2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23442456,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 166,
		stakingToken: tokens.Ser3,
		earningToken: tokens.Ser3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 167,
		stakingToken: tokens.Net2,
		earningToken: tokens.Net2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23364300,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 168,
		stakingToken: tokens.Net3,
		earningToken: tokens.Net3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 169,
		stakingToken: tokens.Sen2,
		earningToken: tokens.Sen2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23360798,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 170,
		stakingToken: tokens.Sen3,
		earningToken: tokens.Sen3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 171,
		stakingToken: tokens.Ecu2,
		earningToken: tokens.Ecu2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23364300,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 172,
		stakingToken: tokens.Ecu3,
		earningToken: tokens.Ecu3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 173,
		stakingToken: tokens.End2,
		earningToken: tokens.End2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23367800,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 174,
		stakingToken: tokens.End3,
		earningToken: tokens.End3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23481332,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 175,
		stakingToken: tokens.Ira2,
		earningToken: tokens.Ira2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: true,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 176,
		stakingToken: tokens.Ira3,
		earningToken: tokens.Ira3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23481332,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 177,
		stakingToken: tokens.Usa2,
		earningToken: tokens.Usa2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23367800,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 178,
		stakingToken: tokens.Usa3,
		earningToken: tokens.Usa3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23481332,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 179,
		stakingToken: tokens.Wal2,
		earningToken: tokens.Wal2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: true,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 180,
		stakingToken: tokens.Wal3,
		earningToken: tokens.Wal3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23481332,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 181,
		stakingToken: tokens.Arg2,
		earningToken: tokens.Arg2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23395882,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 182,
		stakingToken: tokens.Arg3,
		earningToken: tokens.Arg3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 183,
		stakingToken: tokens.Sau2,
		earningToken: tokens.Sau2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23388882,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 184,
		stakingToken: tokens.Sau3,
		earningToken: tokens.Sau3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 185,
		stakingToken: tokens.Mex2,
		earningToken: tokens.Mex2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23395882,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 186,
		stakingToken: tokens.Mex3,
		earningToken: tokens.Mex3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 187,
		stakingToken: tokens.Pol2,
		earningToken: tokens.Pol2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23388882,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 188,
		stakingToken: tokens.Pol3,
		earningToken: tokens.Pol3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 189,
		stakingToken: tokens.Den2,
		earningToken: tokens.Den2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23392362,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 190,
		stakingToken: tokens.Den3,
		earningToken: tokens.Den3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 191,
		stakingToken: tokens.Tun2,
		earningToken: tokens.Tun2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23385382,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 192,
		stakingToken: tokens.Tun3,
		earningToken: tokens.Tun3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 193,
		stakingToken: tokens.Fra2,
		earningToken: tokens.Fra2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23392362,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 194,
		stakingToken: tokens.Fra3,
		earningToken: tokens.Fra3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 195,
		stakingToken: tokens.Aus2,
		earningToken: tokens.Aus2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23385382,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 196,
		stakingToken: tokens.Aus3,
		earningToken: tokens.Aus3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 197,
		stakingToken: tokens.Ger2,
		earningToken: tokens.Ger2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23424649,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 198,
		stakingToken: tokens.Ger3,
		earningToken: tokens.Ger3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 199,
		stakingToken: tokens.Jap2,
		earningToken: tokens.Jap2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23413849,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 200,
		stakingToken: tokens.Jap3,
		earningToken: tokens.Jap3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 201,
		stakingToken: tokens.Spa2,
		earningToken: tokens.Spa2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23424649,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 202,
		stakingToken: tokens.Spa3,
		earningToken: tokens.Spa3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 203,
		stakingToken: tokens.Cos2,
		earningToken: tokens.Cos2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23413849,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 204,
		stakingToken: tokens.Cos3,
		earningToken: tokens.Cos3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 205,
		stakingToken: tokens.Mor2,
		earningToken: tokens.Mor2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23417449,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 206,
		stakingToken: tokens.Mor3,
		earningToken: tokens.Mor3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 207,
		stakingToken: tokens.Cro2,
		earningToken: tokens.Cro2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23421049,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 208,
		stakingToken: tokens.Cro3,
		earningToken: tokens.Cro3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 209,
		stakingToken: tokens.Bel2,
		earningToken: tokens.Bel2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23417449,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 210,
		stakingToken: tokens.Bel3,
		earningToken: tokens.Bel3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 211,
		stakingToken: tokens.Can2,
		earningToken: tokens.Can2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23421049,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 212,
		stakingToken: tokens.Can3,
		earningToken: tokens.Can3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 213,
		stakingToken: tokens.Uru2,
		earningToken: tokens.Uru2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23453236,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 214,
		stakingToken: tokens.Uru3,
		earningToken: tokens.Uru3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 215,
		stakingToken: tokens.Kor2,
		earningToken: tokens.Kor2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23446046,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 216,
		stakingToken: tokens.Kor3,
		earningToken: tokens.Kor3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 217,
		stakingToken: tokens.Por2,
		earningToken: tokens.Por2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23453236,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 218,
		stakingToken: tokens.Por3,
		earningToken: tokens.Por3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 219,
		stakingToken: tokens.Gha2,
		earningToken: tokens.Gha2,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		closeBlock: 23446046,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 220,
		stakingToken: tokens.Gha3,
		earningToken: tokens.Gha3,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 22937903,
		// closeBlock: 29192327,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000, // +0
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},

	{
		sousId: 221,
		stakingToken: tokens.Net4,
		earningToken: tokens.Net4,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		// startBlock: 22937903,
		// closeBlock: 29192327,
		// // endBlock: 16308000648,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000,
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 222,
		stakingToken: tokens.Usa4,
		earningToken: tokens.Usa4,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		// startBlock: 22937903,
		// closeBlock: 29192327,
		// // endBlock: 16308000648,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000,
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 223,
		stakingToken: tokens.Arg4,
		earningToken: tokens.Arg4,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		// startBlock: 22937903,
		// closeBlock: 29192327,
		// // endBlock: 16308000648,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000,
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 224,
		stakingToken: tokens.Aus4,
		earningToken: tokens.Aus4,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		// startBlock: 22937903,
		// closeBlock: 29192327,
		// // endBlock: 16308000648,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000,
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 225,
		stakingToken: tokens.Fra4,
		earningToken: tokens.Fra4,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		// startBlock: 22937903,
		// closeBlock: 29192327,
		// // endBlock: 16308000648,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000,
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 226,
		stakingToken: tokens.Pol4,
		earningToken: tokens.Pol4,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		// startBlock: 22937903,
		// closeBlock: 29192327,
		// // endBlock: 16308000648,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000,
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 227,
		stakingToken: tokens.End4,
		earningToken: tokens.End4,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		// startBlock: 22937903,
		// closeBlock: 29192327,
		// // endBlock: 16308000648,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000,
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 228,
		stakingToken: tokens.Sen4,
		earningToken: tokens.Sen4,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		// startBlock: 22937903,
		// closeBlock: 29192327,
		// // endBlock: 16308000648,
		startBlock: 22937903,
		closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000,
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 229,
		stakingToken: tokens.Jap4,
		earningToken: tokens.Jap4,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		// startBlock: 22937903,
		// closeBlock: 29192327,
		// // endBlock: 16308000648,
		startBlock: 22937903,
		// closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000,
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 230,
		stakingToken: tokens.Cro4,
		earningToken: tokens.Cro4,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		// startBlock: 22937903,
		// closeBlock: 29192327,
		// // endBlock: 16308000648,
		startBlock: 22937903,
		// closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000,
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 231,
		stakingToken: tokens.Bra4,
		earningToken: tokens.Bra4,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		// startBlock: 22937903,
		// closeBlock: 29192327,
		// // endBlock: 16308000648,
		startBlock: 22937903,
		// closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000,
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 232,
		stakingToken: tokens.Kor4,
		earningToken: tokens.Kor4,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		// startBlock: 22937903,
		// closeBlock: 29192327,
		// // endBlock: 16308000648,
		startBlock: 22937903,
		// closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000,
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 234,
		stakingToken: tokens.Mor4,
		earningToken: tokens.Mor4,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		// startBlock: 22937903,
		// closeBlock: 29192327,
		// // endBlock: 16308000648,
		startBlock: 22937903,
		// closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000,
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 235,
		stakingToken: tokens.Spa4,
		earningToken: tokens.Spa4,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		// startBlock: 22937903,
		// closeBlock: 29192327,
		// // endBlock: 16308000648,
		startBlock: 22937903,
		// closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000,
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 236,
		stakingToken: tokens.Por4,
		earningToken: tokens.Por4,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		// startBlock: 22937903,
		// closeBlock: 29192327,
		// // endBlock: 16308000648,
		startBlock: 22937903,
		// closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000,
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
	{
		sousId: 237,
		stakingToken: tokens.Swi4,
		earningToken: tokens.Swi4,
		// addressRecivie: '0x20B495c1679659326074D6965132aC6fbFB6369d',
		fees: 0,
		blockPeriod: 86400,
		// startBlock: 22937903,
		// closeBlock: 29192327,
		// // endBlock: 16308000648,
		startBlock: 22937903,
		// closeBlock: 23476832,
		endBlock: 16308000648,
		tokenPerBlock: 173600000000000000,
		isFinished: false,
		stakingLimit: 0,
		disStake: false,
		disHarvest: true,
		userLock: true,
		typePool: 4,
		typeGetPrice: 3,
	},
]
// Gha3
// contract test 0xb3a2F220c987af61C274B26917956eb6faf1bCBa
export default poolsConfig
