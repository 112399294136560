import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

interface ContainerProps {
	children?: React.ReactNode
	size?: 'sm' | 'md' | 'lg'
	padding?: number | string
}

const Container: React.FC<ContainerProps> = ({
	children,
	size = 'normal',
	padding,
}) => {
	const { siteWidth } = useContext<{ siteWidth: number }>(ThemeContext)
	let width: number
	switch (size) {
		case 'normal':
			width = siteWidth
			break
		case 'sm':
			width = siteWidth / 2
			break
		case 'md':
			width = (siteWidth * 2) / 3
			break
		case 'lg':
		default:
			width = siteWidth
	}
	return (
		<StyledContainer width={width} padding={padding}>
			{children}
		</StyledContainer>
	)
}

interface StyledContainerProps {
	width: number
	padding: number | string
}

const StyledContainer = styled.div<StyledContainerProps>`
	position: relative;
	box-sizing: border-box;
	margin: 0 auto;
	max-width: 100%;
	width: ${({ width }) => width && width}px;
	padding: ${({ padding }) => padding || '0 15px'};

	@media only screen and (max-width: 768px) {
		padding: 0 10px;
	}
`

export default Container
