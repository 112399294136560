import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { Heading, Box, Flex, Text, IconButton } from '@footdex2022/uikit'
import { Button, BoxFull, FlexFull } from '../../components/Quata'
import { Picture } from '../../components/Picture'
import CardNft from './CardNft'

import PersonOne from '../../assets/imgQuata/Marketplace/PersonOne.png'
import PersonTwo from '../../assets/imgQuata/Marketplace/PersonTwo.png'
import PersonThree from '../../assets/imgQuata/Marketplace/PersonThree.png'
import PersonFour from '../../assets/imgQuata/Marketplace/PersonFour.png'
import PersonFive from '../../assets/imgQuata/Marketplace/PersonFive.png'

const ArrClone = [
	{
		id: '1',
		img: PersonOne,
	},
	{
		id: '2',
		img: PersonTwo,
	},
	{
		id: '3',
		img: PersonThree,
	},
	{
		id: '4',
		img: PersonFour,
	},
	{
		id: '5',
		img: PersonFive,
	},
	{
		id: '1',
		img: PersonOne,
	},
	{
		id: '2',
		img: PersonTwo,
	},
	{
		id: '3',
		img: PersonThree,
	},
	{
		id: '4',
		img: PersonFour,
	},
	{
		id: '5',
		img: PersonFive,
	},
]

const Carousel = () => {
	const settings: any = {
		dots: false,
		infinite: true,
		slidesToShow: 4,
		// autoplay: true,
		speed: 500,
		autoplaySpeed: 4000,
		initialSlide: 0,
		className: 'center',
		// centerMode: true,
		// centerPadding: '60px',
		responsive: [
			{
				breakpoint: 1290,
				settings: {
					slidesToShow: 3,
					// slidesToScroll: 3,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 975,
				settings: {
					slidesToShow: 2,
					// slidesToScroll: 2,
					// initialSlide: 2,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	}

	return (
		<Wrapper>
			<Slider {...settings}>
				{ArrClone.map((_item, _index) => {
					return (
						<Box className="cus-img" key={_index}>
							<Picture url={_item.img} />
						</Box>
					)
				})}
			</Slider>
		</Wrapper>
	)
}

export default Carousel

const Wrapper = styled(Box)`
	width: 100%;
	height: 100%;

	img {
		max-width: 100%;
	}

	.slick-prev,
	.slick-next {
		z-index: 50;
		width: 40px;
		height: 40px;
	}

	.slick-prev {
		left: 0;
	}

	.slick-next {
		right: 0;
	}
	/* 
	.slick-initialized .slick-slide,
	.cus-img {
		max-width: 370px;
	} */
`
