import React from 'react'
import { SvgProps, Svg } from '@footdex2022/uikit'

const ActiveIcon: React.FC<SvgProps> = (props) => {
	return (
		<Svg viewBox="0 0 30 48" {...props}>
			<path
				d="M29.64 42.36L11.32 24L29.64 5.64L24 0L0 24L24 48L29.64 42.36Z"
				fill="white"
			/>
		</Svg>
	)
}

export default ActiveIcon
