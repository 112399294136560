import React, { FC, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
// import { Box, BoxProps } from "../Box";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    /* visibility: hidden; */
  }
  100% {
    opacity: 1;
    /* visibility: visible; */
  }
`

const StyledOverlay = styled.div`
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 20;
	will-change: opacity;
	/* visibility: hidden; */
	/* opacity: 0; */
	animation: 0.3s ease-in-out 0s 1 normal forwards running ${fadeIn};
`

// func khoa scroll body
//@ts-ignore
const BodyLock = () => {
	useEffect(() => {
		document.body.style.overflow = 'hidden'
		return () => {
			// document.body.style.overflow = "overlay";
			document.body.style.cssText = `
      overflow: visible;
      overflow: overlay;
    `
		}
	}, [])
	//@ts-ignore
	return null
}

export const Overlay = (props: any) => {
	return (
		<>
			<BodyLock />
			<StyledOverlay role="presentation" {...props} />
		</>
	)
}

export default Overlay
