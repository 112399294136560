import React from 'react'
import styled from 'styled-components'
import { Flex, Box, IconButton } from '@footdex2022/uikit'
// import { ArrowBackIcon, CloseIcon } from "../../components/Svg";
// import { IconButton } from "../../../components/ButtonPancake";
import { ModalProps } from './types'

// Custom button close Modal
export const ModalCloseButton: React.FC<{
	onDismiss: ModalProps['onDismiss']
}> = ({ onDismiss }) => {
	return (
		<IconButton
			variant="text"
			onClick={onDismiss}
			aria-label="Close the dialog"
		>
			{/* <CloseIcon color="primary" /> */}Close
		</IconButton>
	)
}

export const ModalBackButton: React.FC<{ onBack: ModalProps['onBack'] }> = ({
	onBack,
}) => {
	return (
		<IconButton variant="text" onClick={onBack} area-label="go back" mr="8px">
			{/* <ArrowBackIcon color="primary" /> */} onBack
		</IconButton>
	)
}

export const ModalContainer = styled(Box)<{ minWidth: string }>`
	overflow: hidden;
	background: transparent;
	box-shadow: 0px 20px 36px -8px rgba(14, 14, 44, 0.1),
		0px 1px 1px rgba(0, 0, 0, 0.05);
	border: 1px solid #000;
	border-radius: 32px;
	width: 100%;
	min-width: ${({ minWidth }) => minWidth};
	max-height: 100vh;
	z-index: 100;

	@media only screen and (min-width: 852px) {
		/* width: auto; */
		max-width: 100%;
	}
`
