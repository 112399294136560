import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import Providers from './Providers'
import './index.css'
import { Web3ReactProvider, createWeb3ReactRoot } from '@web3-react/core'
import { getLibrary, Web3ProviderNetwork } from './contexts/web3provider'
import { RefreshContextProvider } from './contexts/RefreshContext'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

ReactDOM.render(
	<BrowserRouter>
		<Providers>
			<App />
		</Providers>
	</BrowserRouter>,

	document.getElementById('root'),
)
