import React, { useMemo } from 'react'
import { useParams, Link } from 'react-router-dom'
import {
	Box,
	Flex,
	Heading,
	Text,
	useMatchBreakpoints,
} from '@footdex2022/uikit'
import Container from '../../components/Container'

import { Supper, WorldCup } from '../Marketplace/Marketplace'

import { Wrapper, BoxBanner, BodyBox } from './styled'

import Foots from '../../assets/imgQuata/MarketplaceDetail/foots.png'
import iconFBD from '../../assets/imgQuata/MarketplaceDetail/iconFBD.svg'

const MarketplaceDetail = () => {
	const { player }: any = useParams()

	const { isXxl, isSm } = useMatchBreakpoints()

	const result = JSON.parse(localStorage.getItem('ARR_DETAIL'))

	const resultMemo = useMemo(() => {
		if (result === 'supperStar') {
			return Supper
		}
		if (result === 'worldCup') {
			return WorldCup
		}
	}, [result])

	// console.log('>>>>>>', player)
	// console.log('>>>>>>', resultMemo)

	return (
		<Wrapper>
			<BoxBanner />
			<Container>
				<Box>
					{resultMemo
						.filter((players) => players.id === player)
						.map((_item: any) => {
							return (
								<BodyBox key={_item.id}>
									<Box width={['100%', '100%', '100%', '460px']} px="10px">
										<Link to="/marketplace">
											<Text>{`<- BACK`}</Text>
										</Link>
										<Box className="media-flex-img">
											<img src={_item.img} alt="" />
										</Box>
									</Box>
									<Box
										width={['100%', '100%', '100%', '100% - 460px']}
										px="10px"
									>
										<Box pb="42px">
											<Text small>#94179</Text>
											<Text fontSize="32px">{_item?.title.toUpperCase()}</Text>
										</Box>
										<Box className="media-flex">
											<Box pb="32px">
												<Text small color="#8D8D8D">
													Overall
												</Text>
												<Text color="#6BE601" fontSize="32px">
													102
												</Text>
											</Box>
											<Flex pb="34px" className="media-flex-info">
												<Text pr="50px">
													<Text fontSize="11px" color="#8D8D8D" pb="12px">
														Specific Role
													</Text>
													<Text fontSize="18px" color="#6BE601">
														{`striker`.toUpperCase()}
													</Text>
												</Text>
												<Text pr="50px">
													<Text fontSize="11px" color="#8D8D8D" pb="12px">
														Age
													</Text>
													<Text fontSize="18px" color="#6BE601">
														{`31`.toUpperCase()}
													</Text>
												</Text>
												<Text pr="50px">
													<Text fontSize="11px" color="#8D8D8D" pb="12px">
														Height & Weight
													</Text>
													<Text fontSize="18px" color="#6BE601">
														{`170 CM / 65 KG`.toUpperCase()}
													</Text>
												</Text>
												<Text pr="50px">
													<Text fontSize="11px" color="#8D8D8D" pb="12px">
														Preferred Foot
													</Text>
													<Text fontSize="18px" color="#6BE601">
														{`Left`.toUpperCase()}
													</Text>
												</Text>
												<Text>
													<img src={Foots} alt="" />
												</Text>
											</Flex>
										</Box>

										<Box
											width="100%"
											height="138px"
											borderRadius="10px"
											border="1px solid #1B1B1B"
											px="22px"
											py="18px"
											mb="30px"
										>
											<Flex>
												<Box pr="20px">
													<img src={iconFBD} alt="" />
												</Box>
												<Flex>
													<Text pr="10px">240</Text>
													<Text>0.59$</Text>
												</Flex>
											</Flex>
											<Box>
												<Text pr="18px" small>
													Owned by:
												</Text>
												{isSm ? (
													<>
														<Text>
															0x3adc0....7201
														</Text>
													</>
												) : (
													<>
														<Text>
															0x3adc0D492F881924F45DadDcfC11C36ECBD37201
														</Text>
													</>
												)}
											</Box>
										</Box>

										<Box
											width="100%"
											height="100%"
											borderRadius="10px"
											border="1px solid #1B1B1B"
											px="22px"
											py="18px"
											mb="30px"
										>
											<Text pb="12px" bold>
												{`Overview`.toUpperCase()}
											</Text>
											<Text pb="18px">
												Neymar da Silva Santos Júnior (born 5 February 1992),
												known as Neymar, is a Brazilian professional footballer
												who plays as a forward for Ligue 1 club Paris
												Saint-Germain and the Brazil national team. A prolific
												goalscorer and renowned playmaker, he is regarded as one
												of the best players in the world.[7] Neymar has scored
												at least 100 goals for three different clubs, making him
												one of three players to achieve this.[8]
											</Text>
											<Text>
												Neymar came into prominence at Santos, where he made his
												professional debut aged 17. He helped the club win two
												successive Campeonato Paulista championships, a Copa do
												Brasil, and the 2011 Copa Libertadores; the latter being
												Santos' first since 1963. Neymar was twice named the
												South American Footballer of the Year, in 2011 and 2012,
												and soon relocated to Europe to join Barcelona. As part
												of Barcelona's attacking trio with Lionel Messi and
											</Text>
										</Box>
										<Box
											width="100%"
											height="100%"
											borderRadius="10px"
											border="1px solid #1B1B1B"
											px="22px"
											py="18px"
										>
											<Text pb="24px" bold>
												{`Transfer History`.toUpperCase()}
											</Text>
											<Text pb="12px" bold>
												{`TBA`.toUpperCase()}
											</Text>
										</Box>
									</Box>
								</BodyBox>
							)
						})}
				</Box>
			</Container>
		</Wrapper>
	)
}

export default MarketplaceDetail
