const tokens = {
	// add pool stake
	// test: {
	// 	icon: 'LukakuMini',
	// 	symbol: 'Lukaku',
	// 	address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
	// 	decimals: 18,
	// },
	// test1: {
	// 	icon: 'DegeaMini',
	// 	symbol: 'HarryMaguire',
	// 	address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
	// 	decimals: 18,
	// },
	busd: {
		symbol: 'BUSD',
		address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
		decimals: 18,
	},
	Lukaku: {
		icon: 'LukakuMini',
		symbol: 'Lukaku',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_RomeluLukaku',
	},
	// HarryMaguire: {
	// 	icon: 'DegeaMini',
	// 	symbol: 'HarryMaguire',
	// 	address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
	// 	decimals: 18,
	// },
	KevinDeBruyne: {
		icon: 'KevinDeBruyneMini',
		symbol: 'KevinDeBruyne',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_KevindeBruyne',
	},

	KylianMbappe: {
		icon: 'KylianMbappeMini',
		symbol: 'KylianMbappe',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_KylianMbappe',
	},
	KylianMbappe2: {
		icon: 'KylianMbappeMini',
		symbol: 'KylianMbappe',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_KylianMbappe',
	},

	LuisSuarez: {
		icon: 'LuisSuarezMini',
		symbol: 'LuisSuarez',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_LuisSuarez',
	},

	CristianoRonaldo: {
		icon: 'CristianoRonaldoMini',
		symbol: 'CristianoRonaldo',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_CristianoRonaldo',
	},
	CristianoRonaldo2: {
		icon: 'CristianoRonaldoMini',
		symbol: 'CristianoRonaldo',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_CristianoRonaldo',
	},
	Neymar: {
		icon: 'NeymarMiniV1',
		symbol: 'Neymar',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_Neymar',
	},

	LukaModric: {
		icon: 'LukaModricMini',
		symbol: 'LukaModric',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_LukaModric',
	},
	LukaModric2: {
		icon: 'LukaModricMini',
		symbol: 'LukaModric',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_LukaModric',
	},

	ThomasMuller: {
		icon: 'ThomasMullerMini',
		symbol: 'ThomasMuller',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_ThomasMuller',
	},

	LionelMessi: {
		icon: 'LionelMessiMini',
		symbol: 'LionelMessi',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_LionelMessi',
	},
	LionelMessi2: {
		icon: 'LionelMessiMini',
		symbol: 'LionelMessi',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_LionelMessi',
	},

	GarethBale: {
		icon: 'GarethBaleMini',
		symbol: 'GarethBale',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_GarethBale',
	},

	JadonSancho: {
		icon: 'JadonSanchoMini',
		symbol: 'JadonSancho',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_JadonSancho',
	},

	MarcusRashford: {
		icon: 'MarcusRashfordMini',
		symbol: 'MarcusRashford',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_MarcusRashford',
	},

	HarryKane: {
		icon: 'HarryKaneMini',
		symbol: 'HarryKane',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_HarryKane',
	},
	HarryKane2: {
		icon: 'HarryKaneMini',
		symbol: 'HarryKane',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_HarryKane',
	},

	Forden: {
		icon: 'FordenMini',
		symbol: 'Forden',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_PhilFoden',
	},
	Forden2: {
		icon: 'FordenMini',
		symbol: 'Forden',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_PhilFoden',
	},

	PaulPogba: {
		icon: 'PaulPogbaMini',
		symbol: 'PaulPogba',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_PaulPogba',
	},

	NGoloKante: {
		icon: 'NGoloKanteMini',
		symbol: 'NGoloKante',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	EdinsonCavani: {
		icon: 'EdinsonCavaniMini',
		symbol: 'EdinsonCavani',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	SonHeungMin: {
		icon: 'SonHeungMinMini',
		symbol: 'SonHeungMin',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	ToniKroos: {
		icon: 'ToniKroosMini',
		symbol: 'ToniKroos',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_ToniKroos',
	},

	SadioMane: {
		icon: 'SadioManeMini',
		symbol: 'SadioMane',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_SadioMane',
	},

	ViniciusJunior: {
		icon: 'ViniciusJuniorMini',
		symbol: 'ViniciusJunior',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_ViniciusJunior',
	},

	Casemiro: {
		icon: 'CasemiroMini',
		symbol: 'Casemiro',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_Casemiro',
	},
	JosuaKimmich: {
		icon: 'JosuaKimmichMini',
		symbol: 'JosuaKimmich',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_JoshuaKimmich',
	},
	Fabinho: {
		icon: 'FabinhoMini',
		symbol: 'Fabinho',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_Fabinho',
	},
	AlexanderArnold: {
		icon: 'AlexanderArnoldMini',
		symbol: 'AlexanderArnold',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_AlexanderArnold',
	},
	VirgilVanDijk: {
		icon: 'VirgilVanDijkMini',
		symbol: 'VirgilVanDijk',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_VirgilvanDijk',
	},
	AntonioRudiger: {
		icon: 'AntonioRudigerMini',
		symbol: 'AntonioRudiger',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_AntonioRudiger',
	},
	BernardoSilva: {
		icon: 'BernardoSilvaMini',
		symbol: 'BernardoSilva',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_BernaldoSilva',
	},

	// end tien dao

	DavidDeGea: {
		icon: 'DegeaMini',
		symbol: 'DavidDeGea',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	KarimBenzema: {
		icon: 'KarimBenzemaMini',
		symbol: 'KarimBenzema',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_KarimBenzema',
	},
	KarimBenzema2: {
		icon: 'KarimBenzemaMini',
		symbol: 'KarimBenzema',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_KarimBenzema',
	},
	RobertLewandowski: {
		icon: 'RobertLewandowskiMini',
		symbol: 'RobertLewandowski',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_RobertLewandowski',
	},
	RobertLewandowski2: {
		icon: 'RobertLewandowskiMini',
		symbol: 'RobertLewandowski',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_RobertLewandoski',
	},
	RafaelLeao: {
		icon: 'RafaelLeaoMini',
		symbol: 'RafaelLeao',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_RafaelLeao',
	},

	RafaelLeao2: {
		icon: 'RafaelLeaoMini',
		symbol: 'RafaelLeao',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_RafaelLeao',
	},
	JordanPickford: {
		icon: 'JordanPickfordMini',
		symbol: 'JordanPickford',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Glove_No_JordanPickford',
	},
	ManuelNeuer: {
		icon: 'ManuelNeuerMini',
		symbol: 'ManuelNeuer',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Glove_No_ManuelNeuer',
	},
	ThibautCourtois: {
		icon: 'ThibautCourtoisMini',
		symbol: 'ThibautCourtois',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Glove_No_ThibautCourtois',
	},
	ThibautCourtois2: {
		icon: 'ThibautCourtoisMini',
		symbol: 'ThibautCourtois',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Ball_No_ThibautCourtois',
	},

	HugoLloris: {
		icon: 'HugoLlorisMini',
		symbol: 'HugoLloris',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Glove_No_HugoLloris',
	},

	WojciechSzczesny: {
		icon: 'WojciechSzczesnyMini',
		symbol: 'WojciechSzczesny',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Glove_No_WojciechSzczesny',
	},
	Alisson: {
		icon: 'AlissonMini',
		symbol: 'Alisson',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Glove_No_Alisson',
	},

	MarcoReus: {
		icon: 'MarcoReusMini',
		symbol: 'MarcoReus',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Scorer_No_MarcoReus',
	},

	// end gold

	Ecuador: {
		icon: 'Ecuador',
		symbol: 'Ecuador',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_A_Ecuador',
	},
	Ecuador2: {
		icon: 'Ecuador',
		symbol: 'Ecuador',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Ecuador',
	},
	England: {
		icon: 'England',
		symbol: 'England',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_B_England',
	},
	England2: {
		icon: 'England',
		symbol: 'England',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_England',
	},
	Netherlands: {
		icon: 'Netherlands',
		symbol: 'Netherlands',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_A_Netherlands',
	},
	Netherlands2: {
		icon: 'Netherlands',
		symbol: 'Netherlands',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Netherlands',
	},
	Qatar: {
		icon: 'Qatar',
		symbol: 'Qatar',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_A_Qatar',
	},
	Qatar2: {
		icon: 'Qatar',
		symbol: 'Qatar',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Qatar',
	},
	Brazill: {
		icon: 'Brazill',
		symbol: 'Brazill',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_G_Brazill',
	},
	Brazill2: {
		icon: 'Brazill',
		symbol: 'Brazill',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Brazill',
	},
	France: {
		icon: 'France',
		symbol: 'France',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_D_France',
	},
	France2: {
		icon: 'France',
		symbol: 'France',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_France',
	},

	Iran: {
		icon: 'Iran',
		symbol: 'Iran',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_B_Iran',
	},

	Iran2: {
		icon: 'Iran',
		symbol: 'Iran',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Iran',
	},

	Senegal: {
		icon: 'Senegal',
		symbol: 'Senegal',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_A_Senegal',
	},
	Senegal2: {
		icon: 'Senegal',
		symbol: 'Senegal',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Senegal',
	},

	UnitedStates: {
		icon: 'UnitedStates',
		symbol: 'UnitedStates',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_B_UnitedStates',
	},
	UnitedStates2: {
		icon: 'UnitedStates',
		symbol: 'UnitedStates',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_UnitedStates',
	},

	Argentina: {
		icon: 'Argentina',
		symbol: 'Argentina',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_C_Argentina',
	},
	Argentina2: {
		icon: 'Argentina',
		symbol: 'Argentina',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Argentina',
	},

	Wales: {
		icon: 'Wales',
		symbol: 'Wales',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_B_Wales',
	},

	Wales2: {
		icon: 'Wales',
		symbol: 'Wales',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Wales',
	},

	SaudiArabic: {
		icon: 'SaudiArabic',
		symbol: 'SaudiArabic',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_C_SaudiArabic',
	},

	SaudiArabic2: {
		icon: 'SaudiArabic',
		symbol: 'SaudiArabic',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_SaudiArabic',
	},

	Croatia: {
		icon: 'Croatia',
		symbol: 'Croatia',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_F_Croatia',
	},

	Croatia2: {
		icon: 'Croatia',
		symbol: 'Croatia',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Croatia',
	},

	SWITZERLAND: {
		icon: 'SWITZERLAND',
		symbol: 'SWITZERLAND',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_G_SWITZERLAND',
	},
	SWITZERLAND2: {
		icon: 'SWITZERLAND',
		symbol: 'SWITZERLAND',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_SWITZERLAND',
	},

	Poland: {
		icon: 'Poland',
		symbol: 'Poland',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_C_Poland',
	},
	Poland2: {
		icon: 'Poland',
		symbol: 'Poland',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Poland',
	},

	Australia: {
		icon: 'Australia',
		symbol: 'Australia',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_D_Australia',
	},

	Australia2: {
		icon: 'Australia',
		symbol: 'Australia',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Australia',
	},
	Mexico: {
		icon: 'Mexico',
		symbol: 'Mexico',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_C_Mexico',
	},
	Mexico2: {
		icon: 'Mexico',
		symbol: 'Mexico',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Mexico',
	},

	denmark: {
		icon: 'denmark',
		symbol: 'denmark',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_D_denmark',
	},
	denmark2: {
		icon: 'denmark',
		symbol: 'denmark',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_denmark',
	},

	Tunisian: {
		icon: 'Tunisian',
		symbol: 'Tunisian',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_D_Tunisian',
	},
	Tunisian2: {
		icon: 'Tunisian',
		symbol: 'Tunisian',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Tunisian',
	},

	Spain: {
		icon: 'Spain',
		symbol: 'Spain',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_E_Spain',
	},
	Spain2: {
		icon: 'Spain',
		symbol: 'Spain',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Spain',
	},

	CostaRica: {
		icon: 'CostaRica',
		symbol: 'CostaRica',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_E_CostaRica',
	},
	CostaRica2: {
		icon: 'CostaRica',
		symbol: 'CostaRica',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_CostaRica',
	},

	Germany: {
		icon: 'Germany',
		symbol: 'Germany',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_E_Germany',
	},
	Germany2: {
		icon: 'Germany',
		symbol: 'Germany',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Germany',
	},
	Belgium: {
		icon: 'Belgium',
		symbol: 'Belgium',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_F_Belgium',
	},
	Belgium2: {
		icon: 'Belgium',
		symbol: 'Belgium',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Belgium',
	},

	Cameroon: {
		icon: 'Cameroon',
		symbol: 'Cameroon',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_G_Cameroon',
	},
	Cameroon2: {
		icon: 'Cameroon',
		symbol: 'Cameroon',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Cameroon',
	},

	CANADA: {
		icon: 'CANADA',
		symbol: 'CANADA',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_F_CANADA',
	},
	CANADA2: {
		icon: 'CANADA',
		symbol: 'CANADA',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_CANADA',
	},

	Ghana: {
		icon: 'Ghana',
		symbol: 'Ghana',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_H_Ghana',
	},
	Ghana2: {
		icon: 'Ghana',
		symbol: 'Ghana',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Ghana',
	},

	Japan: {
		icon: 'Japan',
		symbol: 'Japan',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_E_Japan',
	},
	Japan2: {
		icon: 'Japan',
		symbol: 'Japan',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Japan',
	},

	Korea: {
		icon: 'Korea',
		symbol: 'Korea',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_H_Korea',
	},
	Korea2: {
		icon: 'Korea',
		symbol: 'Korea',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Korea',
	},

	Morocco: {
		icon: 'Morocco',
		symbol: 'Morocco',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_F_Morocco',
	},
	Morocco2: {
		icon: 'Morocco',
		symbol: 'Morocco',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Morocco',
	},

	Potugal: {
		icon: 'Potugal',
		symbol: 'Potugal',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_H_Potugal',
	},
	Potugal2: {
		icon: 'Potugal',
		symbol: 'Potugal',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Potugal',
	},

	Serbia: {
		icon: 'Serbia',
		symbol: 'Serbia',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_G_Serbia',
	},
	Serbia2: {
		icon: 'Serbia',
		symbol: 'Serbia',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Serbia',
	},

	Uruguay: {
		icon: 'Uruguay',
		symbol: 'Uruguay',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Group_H_Uruguay',
	},
	Uruguay2: {
		icon: 'Uruguay',
		symbol: 'Uruguay',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
		typeApi: 'Champion_No_Uruguay',
	},

	// Switzerland: {
	// 	icon: 'Switzerland',
	// 	symbol: 'Switzerland',
	// 	address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
	// 	decimals: 18,
	// },

	// Betting Group
	Swi: {
		icon: 'Swi',
		symbol: 'Swi',
		name: 'Netherlands',
		tour: 'Swi',
		typeApi: 'Match_G_SWICAM_Swi',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Swi2: {
		icon: 'Swi',
		symbol: 'Swi',
		name: 'Netherlands',
		tour: 'Swi2',
		typeApi: 'Match_G_BRASWI_Swi',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Swi3: {
		icon: 'Swi',
		symbol: 'Swi',
		name: 'Netherlands',
		tour: 'Swi3',
		typeApi: 'Match_G_SERSWI_Swi',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Swi4: {
		icon: 'Swi',
		symbol: 'Swi',
		name: 'Netherlands',
		tour: 'SwiRound16',
		typeApi: 'Match_KO_PORSWI_Swi',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Cam: {
		icon: 'Cam',
		symbol: 'Cam',
		name: 'Cameroon',
		tour: 'Cam',
		typeApi: 'Match_G_SWICAM_Cam',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Cam2: {
		icon: 'Cam',
		symbol: 'Cam',
		name: 'Cameroon',
		tour: 'Cam2',
		typeApi: 'Match_G_CAMSER_Cam',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Cam3: {
		icon: 'Cam',
		symbol: 'Cam',
		name: 'Cameroon',
		tour: 'Cam3',
		typeApi: 'Match_G_CAMBRA_Cam',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Bra: {
		icon: 'Bra',
		symbol: 'Bra',
		name: 'Brazil',
		tour: 'Bra',
		typeApi: 'Match_G_BRASER_Bra',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Bra2: {
		icon: 'Bra',
		symbol: 'Bra',
		name: 'Brazil',
		tour: 'Bra2',
		typeApi: 'Match_G_BRASWI_Bra',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Bra3: {
		icon: 'Bra',
		symbol: 'Bra',
		name: 'Brazil',
		tour: 'Bra3',
		typeApi: 'Match_G_CAMBRA_Bra',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Bra4: {
		icon: 'Bra',
		symbol: 'Bra',
		name: 'Brazil',
		tour: 'BraRound16',
		typeApi: 'Match_KO_BRAKOR_Bra',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Ser: {
		icon: 'Ser',
		symbol: 'Ser',
		name: 'Serbia',
		tour: 'Ser',
		typeApi: 'Match_G_BRASER_Ser',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Ser2: {
		icon: 'Ser',
		symbol: 'Ser',
		name: 'Serbia',
		tour: 'Ser2',
		typeApi: 'Match_G_CAMSER_Ser',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Ser3: {
		icon: 'Ser',
		symbol: 'Ser',
		name: 'Serbia',
		tour: 'Ser3',
		typeApi: 'Match_G_SERSWI_Ser',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Net: {
		icon: 'Net',
		symbol: 'Net',
		name: 'Netherlands',
		tour: 'Net',
		typeApi: 'Match_A_SENNET_Net',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Net2: {
		icon: 'Net',
		symbol: 'Net',
		name: 'Netherlands',
		tour: 'Net2',
		typeApi: 'Match_A_NETECU_Net',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Net3: {
		icon: 'Net',
		symbol: 'Net',
		name: 'Netherlands',
		tour: 'Net3',
		typeApi: 'Match_A_NETQAT_Net',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Net4: {
		icon: 'Net',
		symbol: 'Net',
		name: 'Netherlands',
		tour: 'NetRound16',
		typeApi: 'Match_KO_NETUSA_Net',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Sen: {
		icon: 'Sen',
		symbol: 'Sen',
		name: 'Senegal',
		tour: 'Sen',
		typeApi: 'Match_A_SENNET_Sen',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Sen2: {
		icon: 'Sen',
		symbol: 'Sen',
		name: 'Senegal',
		tour: 'Sen2',
		typeApi: 'Match_A_QATSEN_Sen',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Sen3: {
		icon: 'Sen',
		symbol: 'Sen',
		name: 'Senegal',
		tour: 'Sen3',
		typeApi: 'Match_A_ECUSEN_Sen',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Sen4: {
		icon: 'Sen',
		symbol: 'Sen',
		name: 'Senegal',
		tour: 'SenRound16',
		typeApi: 'Match_KO_ENDSEN_Sen',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Qata: {
		icon: 'Qat',
		symbol: 'Qat',
		name: 'Qatar',
		tour: 'Qat',
		typeApi: 'Match_A_QATECU_Qat',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Qata2: {
		icon: 'Qat',
		symbol: 'Qat',
		name: 'Qatar',
		tour: 'Qat2',
		typeApi: 'Match_A_QATSEN_Qat',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Qata3: {
		icon: 'Qat',
		symbol: 'Qat',
		name: 'Qatar',
		tour: 'Qat3',
		typeApi: 'Match_A_NETQAT_Qat',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Ecu: {
		icon: 'Ecu',
		symbol: 'Ecu',
		name: 'Ecuador',
		tour: 'Ecu',
		typeApi: 'Match_A_QATECU_Ecu',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Ecu2: {
		icon: 'Ecu',
		symbol: 'Ecu',
		name: 'Ecuador',
		tour: 'Ecu2',
		typeApi: 'Match_A_NETECU_Ecu',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Ecu3: {
		icon: 'Ecu',
		symbol: 'Ecu',
		name: 'Ecuador',
		tour: 'Ecu3',
		typeApi: 'Match_A_ECUSEN_Ecu',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	End: {
		icon: 'End',
		symbol: 'End',
		name: 'England',
		tour: 'End',
		typeApi: 'Match_B_ENDIRA_End',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	End2: {
		icon: 'End',
		symbol: 'End',
		name: 'England',
		tour: 'End2',
		typeApi: 'Match_B_ENDUSA_End',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	End3: {
		icon: 'End',
		symbol: 'End',
		name: 'England',
		tour: 'End3',
		typeApi: 'Match_B_WALEND_End',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	End4: {
		icon: 'End',
		symbol: 'End',
		name: 'England',
		tour: 'EndRound16',
		typeApi: 'Match_KO_ENDSEN_End',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Ira: {
		icon: 'Ira',
		symbol: 'Ira',
		name: 'Iran',
		tour: 'Ira',
		typeApi: 'Match_B_ENDIRA_Ira',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Ira2: {
		icon: 'Ira',
		symbol: 'Ira',
		name: 'Iran',
		tour: 'Ira2',
		typeApi: 'Match_B_WALIRA_Ira',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Ira3: {
		icon: 'Ira',
		symbol: 'Ira',
		name: 'Iran',
		tour: 'Ira3',
		typeApi: 'Match_B_IRAUSA_Ira',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Usa: {
		icon: 'Usa',
		symbol: 'Usa',
		name: 'USA',
		tour: 'Usa',
		typeApi: 'Match_B_USAWAL_Usa',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Usa2: {
		icon: 'Usa',
		symbol: 'Usa',
		name: 'USA',
		tour: 'Usa2',
		typeApi: 'Match_B_ENDUSA_Usa',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Usa3: {
		icon: 'Usa',
		symbol: 'Usa',
		name: 'USA',
		tour: 'UsaRound16',
		typeApi: 'Match_KO_NETUSA_Usa',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Usa4: {
		icon: 'Usa',
		symbol: 'Usa',
		name: 'USA',
		tour: 'Usa3',
		typeApi: 'Match_B_IRAUSA_Usa',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Wal: {
		icon: 'Wal',
		symbol: 'Wal',
		name: 'Wales',
		tour: 'Wal',
		typeApi: 'Match_B_USAWAL_Wal',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Wal2: {
		icon: 'Wal',
		symbol: 'Wal',
		name: 'Wales',
		tour: 'Wal2',
		typeApi: 'Match_B_WALIRA_Wal',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Wal3: {
		icon: 'Wal',
		symbol: 'Wal',
		name: 'Wales',
		tour: 'Wal3',
		typeApi: 'Match_B_WALEND_Wal',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Arg: {
		icon: 'Arg',
		symbol: 'Arg',
		name: 'Argentina',
		tour: 'Arg',
		typeApi: 'Match_C_ARGSAU_Arg',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Arg2: {
		icon: 'Arg',
		symbol: 'Arg',
		name: 'Argentina',
		tour: 'Arg2',
		typeApi: 'Match_C_ARGMEX_Arg',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Arg3: {
		icon: 'Arg',
		symbol: 'Arg',
		name: 'Argentina',
		tour: 'Arg3',
		typeApi: 'Match_C_POLARG_Arg',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Arg4: {
		icon: 'Arg',
		symbol: 'Arg',
		name: 'Argentina',
		tour: 'ArgRound16',
		typeApi: 'Match_KO_ARGAUS_Arg',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Sau: {
		icon: 'Sau',
		symbol: 'Sau',
		name: 'SaudiArabic',
		tour: 'Sau',
		typeApi: 'Match_C_ARGSAU_Sau',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Sau2: {
		icon: 'Sau',
		symbol: 'Sau',
		name: 'SaudiArabic',
		tour: 'Sau2',
		typeApi: 'Match_C_POLSAU_Sau',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Sau3: {
		icon: 'Sau',
		symbol: 'Sau',
		name: 'SaudiArabic',
		tour: 'Sau3',
		typeApi: 'Match_C_SAUMEX_Sau',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Mex: {
		icon: 'Mex',
		symbol: 'Mex',
		name: 'Mexico',
		tour: 'Mex',
		typeApi: 'Match_C_MEXPOL_Mex',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Mex2: {
		icon: 'Mex',
		symbol: 'Mex',
		name: 'Mexico',
		tour: 'Mex2',
		typeApi: 'Match_C_ARGMEX_Mex',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Mex3: {
		icon: 'Mex',
		symbol: 'Mex',
		name: 'Mexico',
		tour: 'Mex3',
		typeApi: 'Match_C_SAUMEX_Mex',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Pol: {
		icon: 'Pol',
		symbol: 'Pol',
		name: 'Poland',
		tour: 'Pol',
		typeApi: 'Match_C_MEXPOL_Pol',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Pol2: {
		icon: 'Pol',
		symbol: 'Pol',
		name: 'Poland',
		tour: 'Pol2',
		typeApi: 'Match_C_POLSAU_Pol',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Pol3: {
		icon: 'Pol',
		symbol: 'Pol',
		name: 'Poland',
		tour: 'Pol3',
		typeApi: 'Match_C_POLARG_Pol',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Pol4: {
		icon: 'Pol',
		symbol: 'Pol',
		name: 'Poland',
		tour: 'PolRound16',
		typeApi: 'Match_KO_FRAPOL_Pol',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Den: {
		icon: 'Den',
		symbol: 'Den',
		name: 'Denmark',
		tour: 'Den',
		typeApi: 'Match_D_DENTUN_Den',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Den2: {
		icon: 'Den',
		symbol: 'Den',
		name: 'Denmark',
		tour: 'Den2',
		typeApi: 'Match_D_FRADEN_Den',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Den3: {
		icon: 'Den',
		symbol: 'Den',
		name: 'Denmark',
		tour: 'Den3',
		typeApi: 'Match_D_DENAUS_Den',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Tun: {
		icon: 'Tun',
		symbol: 'Tun',
		name: 'Tunisian',
		tour: 'Tun',
		typeApi: 'Match_D_DENTUN_Tun',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Tun2: {
		icon: 'Tun',
		symbol: 'Tun',
		name: 'Tunisian',
		tour: 'Tun2',
		typeApi: 'Match_D_TUNAUS_Tun',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Tun3: {
		icon: 'Tun',
		symbol: 'Tun',
		name: 'Tunisian',
		tour: 'Tun3',
		typeApi: 'Match_D_TUNFRA_Tun',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Fra: {
		icon: 'Fra',
		symbol: 'Fra',
		name: 'France',
		tour: 'Fra',
		typeApi: 'Match_D_FRAAUS_Fra',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Fra2: {
		icon: 'Fra',
		symbol: 'Fra',
		name: 'France',
		tour: 'Fra2',
		typeApi: 'Match_D_FRADEN_Fra',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Fra3: {
		icon: 'Fra',
		symbol: 'Fra',
		name: 'France',
		tour: 'Fra3',
		typeApi: 'Match_D_TUNFRA_Fra',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Fra4: {
		icon: 'Fra',
		symbol: 'Fra',
		name: 'France',
		tour: 'FraRound16',
		typeApi: 'Match_KO_FRAPOL_Fra',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Aus: {
		icon: 'Aus',
		symbol: 'Aus',
		name: 'Australia',
		tour: 'Aus',
		typeApi: 'Match_D_FRAAUS_Aus',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Aus2: {
		icon: 'Aus',
		symbol: 'Aus',
		name: 'Australia',
		tour: 'Aus2',
		typeApi: 'Match_D_TUNAUS_Aus',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Aus3: {
		icon: 'Aus',
		symbol: 'Aus',
		name: 'Australia',
		tour: 'Aus3',
		typeApi: 'Match_D_DENAUS_Aus',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Aus4: {
		icon: 'Aus',
		symbol: 'Aus',
		name: 'Australia',
		tour: 'AusRound16',
		typeApi: 'Match_KO_ARGAUS_Aus',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Ger: {
		icon: 'Ger',
		symbol: 'Ger',
		name: 'Germany',
		tour: 'Ger',
		typeApi: 'Match_E_GERJAP_Ger',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Ger2: {
		icon: 'Ger',
		symbol: 'Ger',
		name: 'Germany',
		tour: 'Ger2',
		typeApi: 'Match_E_SPAGER_Ger',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Ger3: {
		icon: 'Ger',
		symbol: 'Ger',
		name: 'Germany',
		tour: 'Ger3',
		typeApi: 'Match_E_COSGER_Ger',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Jap: {
		icon: 'Jap',
		symbol: 'Jap',
		name: 'Japan',
		tour: 'Jap',
		typeApi: 'Match_E_GERJAP_Jap',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Jap2: {
		icon: 'Jap',
		symbol: 'Jap',
		name: 'Japan',
		tour: 'Jap2',
		typeApi: 'Match_E_JAPCOS_Jap',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Jap3: {
		icon: 'Jap',
		symbol: 'Jap',
		name: 'Japan',
		tour: 'Jap3',
		typeApi: 'Match_E_JAPSPA_Jap',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Jap4: {
		icon: 'Jap',
		symbol: 'Jap',
		name: 'Japan',
		tour: 'JapRound16',
		typeApi: 'Match_KO_JAPCRO_Jap',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Spa: {
		icon: 'Spa',
		symbol: 'Spa',
		name: 'Spain',
		tour: 'Spa',
		typeApi: 'Match_E_SPACOS_Spa',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Spa2: {
		icon: 'Spa',
		symbol: 'Spa',
		name: 'Spain',
		tour: 'Spa2',
		typeApi: 'Match_E_SPAGER_Spa',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Spa3: {
		icon: 'Spa',
		symbol: 'Spa',
		name: 'Spain',
		tour: 'Spa3',
		typeApi: 'Match_E_JAPSPA_Spa',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Spa4: {
		icon: 'Spa',
		symbol: 'Spa',
		name: 'Spain',
		tour: 'SpaRound16',
		typeApi: 'Match_KO_MROSPA_Spa',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Cos: {
		icon: 'Cos',
		symbol: 'Cos',
		name: 'CostaRica',
		tour: 'Cos',
		typeApi: 'Match_E_SPACOS',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Cos2: {
		icon: 'Cos',
		symbol: 'Cos',
		name: 'CostaRica',
		tour: 'Cos2',
		typeApi: 'Match_E_JAPCOS_Cos',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Cos3: {
		icon: 'Cos',
		symbol: 'Cos',
		name: 'CostaRica',
		tour: 'Cos3',
		typeApi: 'Match_E_COSGER_Cos',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Mor: {
		icon: 'Mor',
		symbol: 'Mor',
		name: 'Morocco',
		tour: 'Mor',
		typeApi: 'Match_F_MORCRO_Cos',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Mor2: {
		icon: 'Mor',
		symbol: 'Mor',
		name: 'Morocco',
		tour: 'Mor2',
		typeApi: 'Match_F_BELMOR_Mor',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Mor3: {
		icon: 'Mor',
		symbol: 'Mor',
		name: 'Morocco',
		tour: 'Mor3',
		typeApi: 'Match_F_CANMOR_Mor',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Mor4: {
		icon: 'Mor',
		symbol: 'Mor',
		name: 'Morocco',
		tour: 'MorRound16',
		typeApi: 'Match_KO_MROSPA_Mor',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Cro: {
		icon: 'Cro',
		symbol: 'Cro',
		name: 'Croatia',
		tour: 'Cro',
		typeApi: 'Match_F_MORCRO_Mor_Cro',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Cro2: {
		icon: 'Cro',
		symbol: 'Cro',
		name: 'Croatia',
		tour: 'Cro2',
		typeApi: 'Match_F_CROCAN_Cro',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Cro3: {
		icon: 'Cro',
		symbol: 'Cro',
		name: 'Croatia',
		tour: 'Cro3',
		typeApi: 'Match_F_CROBEL_Cro',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Cro4: {
		icon: 'Cro',
		symbol: 'Cro',
		name: 'Croatia',
		tour: 'CroRound16',
		typeApi: 'Match_KO_JAPCRO_Cro',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Bel: {
		icon: 'Bel',
		symbol: 'Bel',
		name: 'Belgium',
		tour: 'Bel',
		typeApi: 'Match_F_BELCAN_Bel',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Bel2: {
		icon: 'Bel',
		symbol: 'Bel',
		name: 'Belgium',
		tour: 'Bel2',
		typeApi: 'Match_F_BELMOR_Bel',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Bel3: {
		icon: 'Bel',
		symbol: 'Bel',
		name: 'Belgium',
		tour: 'Bel3',
		typeApi: 'Match_F_CROBEL_Bel',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Can: {
		icon: 'Can',
		symbol: 'Can',
		name: 'CANADA',
		tour: 'Can',
		typeApi: 'Match_F_BELCAN_Can',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Can2: {
		icon: 'Can',
		symbol: 'Can',
		name: 'CANADA',
		tour: 'Can2',
		typeApi: 'Match_F_CROCAN_Can',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Can3: {
		icon: 'Can',
		symbol: 'Can',
		name: 'CANADA',
		tour: 'Can3',
		typeApi: 'Match_F_CANMOR_Can',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Uru: {
		icon: 'Uru',
		symbol: 'Uru',
		name: 'Uruguay',
		tour: 'Uru',
		typeApi: 'Match_H_URUKOR_Uru',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Uru2: {
		icon: 'Uru',
		symbol: 'Uru',
		name: 'Uruguay',
		tour: 'Uru2',
		typeApi: 'Match_H_PORURU_Uru',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Uru3: {
		icon: 'Uru',
		symbol: 'Uru',
		name: 'Uruguay',
		tour: 'Uru3',
		typeApi: 'Match_H_GHAURU_Uru',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Kor: {
		icon: 'Kor',
		symbol: 'Kor',
		name: 'Korea',
		tour: 'Kor',
		typeApi: 'Match_H_URUKOR_Kor',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Kor2: {
		icon: 'Kor',
		symbol: 'Kor',
		name: 'Korea',
		tour: 'Kor2',
		typeApi: 'Match_H_KORGHA_Kor',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Kor3: {
		icon: 'Kor',
		symbol: 'Kor',
		name: 'Korea',
		tour: 'Kor3',
		typeApi: 'Match_H_KORPOR_Kor',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Kor4: {
		icon: 'Kor',
		symbol: 'Kor',
		name: 'Korea',
		tour: 'KorRound16',
		typeApi: 'Match_KO_BRAKOR_Kor',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Por: {
		icon: 'Por',
		symbol: 'Por',
		name: 'Portugal',
		tour: 'Por',
		typeApi: 'Match_H_PORGHA_Por',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Por2: {
		icon: 'Por',
		symbol: 'Por',
		name: 'Portugal',
		tour: 'Por2',
		typeApi: 'Match_H_PORURU_Por',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Por3: {
		icon: 'Por',
		symbol: 'Por',
		name: 'Portugal',
		tour: 'Por3',
		typeApi: 'Match_H_PORGHA_Por',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Por4: {
		icon: 'Por',
		symbol: 'Por',
		name: 'Portugal',
		tour: 'PorRound16',
		typeApi: 'Match_KO_PORSWI_Por',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	Gha: {
		icon: 'Gha',
		symbol: 'Gha',
		name: 'Ghana',
		tour: 'Gha',
		typeApi: 'Match_H_PORGHA_Gha',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Gha2: {
		icon: 'Gha',
		symbol: 'Gha',
		name: 'Ghana',
		tour: 'Gha2',
		typeApi: 'Match_H_KORGHA_Gha',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},
	Gha3: {
		icon: 'Gha',
		symbol: 'Gha',
		name: 'Ghana',
		tour: 'Gha3',
		typeApi: 'Match_H_KORPOR_Gha',
		address: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
		decimals: 18,
	},

	//T
}

export default tokens
