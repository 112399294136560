import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Flex, Text } from '@footdex2022/uikit'
import Logo from '../../assets/imgQuata/Logo/Logo.png'

interface LogoProps {
	href: string
	isWhite?: boolean
}

const StyledLink = styled(Link)`
	display: flex;
	align-items: center;
	flex-direction: column;
	transition: 0.3s ease;

	&:hover {
		transform: rotate(-5deg);
	}

	.text-orbit {
		font-weight: 500;
		font-family: ManropeMedium;
	}
`

const IconQuata = styled.img`
	width: 144px;
	min-width: 80px;
	height: 100%;
	object-fit: contain;
	display: block;


	@media (max-width: 1200px) {
		max-width: 110px;
	}


	@media (max-width: 960px) {
		max-width: 80px;
	}
`

const LogoQuata: React.FC<LogoProps> = ({ href, isWhite }) => {
	const isAbsoluteUrl = href.startsWith('http')
	const innerLogo = (
		<>
			<IconQuata src={Logo} alt="icon-orbit" className="desktop-icon" />

			{/* <IconOrbit src={OrbitIcon} alt="icon-orbit" className="desktop-icon" /> */}
		</>
	)

	return (
		<Flex>
			{isAbsoluteUrl ? (
				<a href={href} aria-label="home page">
					{innerLogo}
				</a>
			) : (
				<StyledLink to={href} aria-label="home page">
					{innerLogo}
				</StyledLink>
			)}
		</Flex>
	)
}

export default React.memo(LogoQuata)
