import styled, { keyframes } from 'styled-components'
import { Box, Flex, Heading } from '@footdex2022/uikit'

import bannerDetail from '../../assets/imgQuata/MarketplaceDetail/bannerDetail.png'

export const Wrapper = styled(Flex)`
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: flex-start;
`

export const BoxBanner = styled(Flex)`
	width: 100%;
	height: 130px;
	flex-direction: column;
	align-items: flex-start;
	background-image: url(${bannerDetail});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
`

export const BodyBox = styled(Flex)`
	width: 100%;
	height: 100%;
	align-items: flex-start;
	padding-top: 20px;
	padding-bottom: 60px;

	img {
		max-width: 100%;
	}

	@media only screen and (max-width: 960px) {
		flex-direction: column;

		/* .media-flex {
			display: flex;
			gap: 20px;
		} */

		.media-flex-img {
			display: flex;
			justify-content: center;
		}

		.media-flex-info {
			flex-wrap: wrap;
			gap: 20px;

			& > * {
				padding-right: 30px;
			}
		}
	}
`
