import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../../store/auth/authSlice'
import { getResulttWalletAddress } from '../../store/users/userSlice'

import {
	Box,
	Flex,
	Heading,
	Text,
	useMatchBreakpoints,
} from '@footdex2022/uikit'
import { useActiveWeb3React } from '../../ConnectWallet/hooks'

import MenuNewMobile from './MenuNewMobile'
import useModal from '../../hooks/useModal'
import Logo from '../LogoQuata'
import { BoxFull, FlexFull, Button } from '../Quata'
import { ArrMenu } from './config'
import { Wrapper, Left, Right, MenuList, ActiveLink, BtnGroup } from './styled'
import { ActiveIcon } from '../../components/Svg'
import MenuIconMobile from '../../assets/imgQuata/Home/MenuIcon.svg'
import ModalConnect from '../../ConnectWallet/Component/ModalConnect/ConnectWallet'

const MenuNew = () => {
	const { account } = useActiveWeb3React()
	const { search } = useLocation()

	const dispatch = useDispatch()
	const [values, setValues]: any = useState({
		Address: '',
		Signature: '',
		ReferalCode: '',
	})
	// const { user, isLoading, isError, isSuccess, message } = useSelector(
	// 	(state: any) => state.auth,
	// )

	const { resultWallet } = useSelector((state: any) => state.info)

	const [isListMenu] = useState(ArrMenu)
	const customAccount = useMemo<string>(() => {
		if (account) {
			return `${account.substr(0, 4)}...${account.substr(account.length - 4)}`
		} else {
			return `Connect Wallet`
		}
	}, [account])
	const [onModalCustom] = useModal(<ModalConnect />)
	const [isShowMoenuMobile, setIsShowMenuMobile] = useState(false)
	const handleShowMenuMobile = (boolean: any) => {
		setIsShowMenuMobile(boolean)
		document.body.style.overflow = 'hidden'
	}

	useMemo(() => {
		if (account) {
			setValues((prev: any) => ({ ...prev, Address: account }))
		}
		if (search.indexOf('?referral=') !== -1) {
			setValues((prev: any) => ({ ...prev, ReferalCode: search.slice(10) }))
		}
	}, [account, search])

	const _isLogin = (values: any) => {
		if (values.Address !== '') {
			dispatch(login(values))
		}
	}

	useEffect(() => {
		_isLogin(values)
	}, [values])

	// console.log('>>>>>', search)

	useEffect(() => {
		if (account) {
			//
			dispatch(getResulttWalletAddress())
		}
	}, [account])

	useEffect(() => {
		if (resultWallet && resultWallet.data && resultWallet.data.Data) {
			//
			const result = resultWallet && resultWallet.data && resultWallet.data.Data
			window.localStorage.setItem('wallet', result)
		}
	}, [resultWallet])

	return (
		<Wrapper>
			<Left>
				{window.innerWidth < 1150 ? (
					<img
						src={MenuIconMobile}
						alt=""
						onClick={() => {
							handleShowMenuMobile(true)
						}}
					/>
				) : (
					<></>
				)}
				<Logo href="/" />
			</Left>
			<Right>
				<FlexFull className="BoxWalletMenu">
					{window.innerWidth < 1150 ? (
						<></>
					) : (
						<MenuList>
							{isListMenu.map((_item, _index) => {
								return (
									<div key={_index}>
										{_item.title === 'staking' ? (
											<a href={_item.to} rel="noreferrer">
												<FlexFull
													gap="4px"
													alignItems="center"
													justifyContent="center"
												>
													<ActiveLink className="active">
														<ActiveIcon width="16px" />
													</ActiveLink>
													<Heading
														className={`white-space text f-moda`}
														scale="sm"
													>
														{_item.title.toUpperCase()}
													</Heading>
												</FlexFull>
											</a>
										) : (
											<>
												{_item.to !== '/..' ? (
													<NavLink
														exact
														to={_item?.to}
														activeClassName="selected"
														key={_index}
														// target={_item.to === '/playgame' ? '_blank' : ''}
														rel="noopener noreferrer"
													>
														<FlexFull
															gap="4px"
															alignItems="center"
															justifyContent="center"
														>
															<ActiveLink className="active">
																<ActiveIcon width="16px" />
															</ActiveLink>
															<Heading
																className={`white-space text f-moda`}
																scale="sm"
															>
																{_item.title.toUpperCase()}
															</Heading>
														</FlexFull>
													</NavLink>
												) : (
													<>
														<Flex
															// gap="4px"
															alignItems="center"
															justifyContent="center"
														>
															<ActiveLink className="active">
																<ActiveIcon width="16px" />
															</ActiveLink>
															<Heading
																className={`white-space text f-moda`}
																scale="sm"
															>
																{_item.title.toUpperCase()}
															</Heading>
														</Flex>
													</>
												)}
											</>
										)}
									</div>
								)
							})}
						</MenuList>
					)}
				</FlexFull>
			</Right>
			<BtnGroup>
				<Button onClick={onModalCustom}>{customAccount}</Button>
			</BtnGroup>
			{window.innerWidth < 1280 ? (
				<MenuNewMobile
					show={isShowMoenuMobile}
					handleShow={handleShowMenuMobile}
				></MenuNewMobile>
			) : (
				<></>
			)}
		</Wrapper>
	)
}
export default MenuNew
