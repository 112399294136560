import styled from 'styled-components'
import { Box, Flex } from '@footdex2022/uikit'
import pointer from '../../assets/images/cursor.svg'

export const Wrapper = styled(Flex)`
	top: 0;
	left: 0;
	width: 100%;
	min-height: 116px;
	background: transparent;
	color: #000;
	padding: 0 46px;
	font-family: Monda;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	padding-top: 8px;
	z-index: 99;
	@media (max-width: 1260px) {
		padding: 0 12px;
		padding-top: 8px;
	}
`

export const Left = styled(Box)`
	height: 100%;
	max-width: 184px;
	width: max-content;
	display: flex;
`

export const Right = styled(Box)`
	flex-basis: 65%;
	display: flex;
	justify-content: space-between;
	.BoxWalletMenu {
		justify-content: space-between;

		/* div {
			justify-content: space-between;
			width: 100%;
		} */
	}
	.white-space {
		white-space: nowrap;
		/* position: relative;
		z-index: 10; */
	}

	/* background: linear-gradient(267.66deg,#c8ff4d 0.33%,#9ade00 98.08%); */

	.text {
		font-weight: 700;
		transition: 0.3s ease;
		margin-bottom: 0;
		cursor: pointer;
		/* font-family: Monda !important; */
		font-family: MondaBold !important;
		background-image: linear-gradient(
			to right,
			#9ade00,
			#d0fb71 50%,
			#ffffff 50%
		);
		background-size: 200% 100%;
		background-position: -100%;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		transition: all 0.3s ease-in-out;
		cursor: url(${pointer}) 15 15, move !important;
		position: relative;

		&:before {
			content: '';
			background: #d0fb71;
			display: block;
			position: absolute;
			bottom: -6px;
			left: 0;
			width: 0;
			height: 2px;
			transition: all 0.3s ease-in-out;
		}

		:hover {
			font-weight: 900;
			/* font-size: 70px; */
			background-position: 0;
			::before {
				width: 100%;
			}
		}
	}

	.btn-header {
		background: transparent;
		color: #fff;
		border: 2px solid #fff;
		transition: all 0.3s ease;
		position: relative;
		z-index: 10;

		:after {
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			position: absolute;
			outline: none;
			border-radius: 10px;
			background: linear-gradient(267.66deg, #c8ff4d 0.33%, #9ade00 98.08%);
			z-index: -1;
			opacity: 0;
			transition: all 0.3s ease;
		}

		:hover {
			opacity: 1 !important;
			color: #000;
			border: 2px solid transparent;

			:after {
				opacity: 1 !important;
				border: 2px solid #9ade00;
			}
		}
	}

	.active {
		opacity: 0;
		visibility: hidden;
		transition: opacity 500ms ease, visibility 500ms ease;
		animation: spinss 10s linear infinite;

		@-moz-keyframes spinss {
			100% {
				-moz-transform: rotate(360deg);
			}
		}
		@-webkit-keyframes spinss {
			100% {
				-webkit-transform: rotate(360deg);
			}
		}
		@keyframes spinss {
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
	}

	.selected {
		transition: all 0.3s ease;

		.active {
			opacity: 1;
			visibility: visible;
		}

		.text {
			font-weight: 700;
		}
	}
	@media only screen and (max-width: 1100px) {
		button {
			font-size: 12px;
			padding: 0 12px;
		}
		.text {
			font-size: 14px;
		}
	}
	@media only screen and (max-width: 912px) {
		button {
			font-size: 10px;
			padding: 0 12px;
		}
		.text {
			font-size: 10px;
		}
	}
	@media only screen and (max-width: 767px) {
		max-width: 250px;
	}
	@media only screen and (max-width: 376px) {
		max-width: 130px;
	}
`
export const MenuList = styled(Flex)`
	width: 100%;
	justify-content: space-between;
	@media only screen and (max-width: 1260px) {
		padding: 0 10px;
	}
	@media only screen and (max-width: 820px) {
		padding: 0px 12px;
	}
`
export const ActiveLink = styled(Flex)`
	max-width: 48px;
	min-width: 20px;
	width: 100%;
`

export const MenuMobileWarrper = styled(Flex)`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	transition: all 0.2s linear;
	overflow: hidden;
	z-index: 100;
`
export const MenuMobileContainer = styled(Flex)`
	width: 85%;
	height: 100%;
	background: rgba(22, 22, 22, 0.99);
	box-shadow: inset 0px 40px 50px #000000;
	backdrop-filter: blur(30px);
	overflow: hidden;
	display: block;
	padding-top: 12px;
`
export const BtnMenu = styled(Box)`
	width: 100%;
	height: 50px;
	button {
		height: 100%;
		width: 50px;
		border-radius: 0% 50% 50% 0%;
		border: none;
		outline: none;
		background: linear-gradient(132.76deg, #b6fd79 0%, #75e910 100%),
			radial-gradient(
				63.11% 63.11% at 31.97% 19.67%,
				rgba(255, 255, 255, 0.7) 0%,
				rgba(255, 255, 255, 0) 69.79%,
				rgba(255, 255, 255, 0) 100%
			),
			#2f80ed;
	}
`

export const MenuMobileList = styled(Box)`
	width: 100%;
	padding-top: 30px;
`
export const MenuMobileListItem = styled(Box)`
	width: 100%;
	height: 26px;
	padding-left: 52px;
	margin-bottom: 35px;
	position: relative;
	p {
		font-family: MondaBold;
		font-size: 16px;
		font-weight: 700;
		line-height: 26px;
		letter-spacing: 0.3em;
		color: #fff;
	}
`
export const ActiveIconBox = styled(Box)`
	position: absolute;
	top: 50%;
	left: 26px;
	transform: translate(-50%, -50%);
`
export const MenuSocsical = styled(Flex)`
	position: absolute;
	width: 100%;
	bottom: 50px;
	height: 30px;
	left: 0px;
	align-items: center;
	justify-content: space-between;
	padding: 0px 66px;
`
export const BtnGroup = styled(Box)`
	width: 20%;
	max-width: 217px;
	@media only screen and (max-width: 769px) {
		width: max-content;
	}
`
