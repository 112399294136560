import React, { useEffect, useState, useMemo } from 'react'
import web3 from 'web3'
import { useWeb3React } from '@web3-react/core'
import { useConnectWallet, useActiveWeb3React } from '../../hooks'
import { NEED_A_PLACEHOLDER, WALLET_LIST } from '../../constants/index'
import WalletItem from './WalletItem'
import { ModalProps } from '../Modal/types'

import {
	BoxModal,
	BoxModalContent,
	BoxPd,
	ModalText,
	BoxHeader,
	BoxModalDisConnect,
	BoxPdDisconnect,
	CloseModalIcon,
	PlayGameBoXConnect,
	PlayGameBoXConnectHeader,
	PlayGameBoXConnectHeaderTitle,
	PlayGameConnecBody,
	PlayGameConnecBodyItem,
	PlayGameBoxModalContent,
	BoxPdDisconnectPlayGame,
} from './styled'
import { Box, Flex, Text, useMatchBreakpoints } from '@footdex2022/uikit'
import { Button } from '../../../components/Quata'

import Logo from '../../../assets/imgQuata/Logo/Logo.png'

declare const window: Window & typeof globalThis & { ethereum: any }

const ConnectWallet: React.FC<ModalProps> = ({
	title,
	onDismiss,
	onBack,
	children,
	hideCloseButton = false,
	bodyPadding = '24px',
	headerBackground = 'transparent',
	minWidth = '320px',
	...props
}) => {
	const { account } = useActiveWeb3React()
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
	const { walletLogin, walletLogout } = useConnectWallet()
	const chainId = process.env.REACT_APP_CHAIN_ID ?? '0'
	const walletItemClass = 'w-1/3 flex-auto mb-0.5 p-0.5 last:bg-transparent'
	const context = useWeb3React()
	const { active, error } = context

	const { isMobileL } = useMatchBreakpoints()

	if (window.ethereum) {
		window.ethereum.on('chainChanged', (chainId: any) => {
			// window.location.reload();
		})

		window.ethereum.on('accountsChanged', (chainId: string) => {
			// window.location.reload();
		})
	}

	// --- ACTION IN MODAL ---
	const showModalConnect = () => {
		setIsModalVisible(true)
		// setIsLogin(true);
	}
	const handleConnectOk = () => {
		setIsModalVisible(false)
	}
	const handleConnectCancel = (e: any) => {
		e.stopPropagation()
		e.preventDefault()
	}
	//  --- Return address wallet with substring ---
	const accountEllipsis = active
		? `${account?.substring(0, 4)}...${account?.substring(account.length - 4)}`
		: 'Connect Wallet  '

	// ---- HANDLE DISCONNECT ----
	const handleDisconnect = () => {
		walletLogout()
		setIsModalVisible(false)
		// localStorage.removeItem("accessToken");
		// localStorage.removeItem("USER_LOGIN");
		onDismiss()
	}

	// ---- HANDLE CONNECT ----
	const handleConnect = async (connectorId: string) => {
		try {
			const rs = await walletLogin(connectorId)
			setIsModalVisible(false)
			// setIsLogin(true);
			onDismiss()
		} catch (e) {
			console.error('Login failed')
		}
	}

	const changeNetwork = async (chainId: any) => {
		try {
			await window.ethereum.enable()
			// check if the chain to connect to is installed

			await window.ethereum.request({
				method: 'wallet_switchEthereumChain',
				params: [{ chainId: chainId }], // chainId must be in hexadecimal numbers
			})
		} catch (error) {
			// This error code indicates that the chain has not been added to MetaMask
			// if it is not, then install it into the user MetaMask
			//@ts-ignore
			if (error.code === 4902) {
				try {
					await window.ethereum.request({
						method: 'wallet_addEthereumChain',
						params: [
							{
								chainId: chainId,
								rpcUrl: process.env.REACT_APP_BSC_NETWORK_URL ?? '' /* ... */,
							},
						],
					})
				} catch (addError) {
					// handle "add" error
				}
			}
			console.error(error)
		}
	}

	useEffect(() => {
		if (account) {
			if (window.ethereum) {
				if (chainId != window.ethereum.networkVersion) {
					changeNetwork(`0x${parseInt(chainId).toString(16)}`)
				}
			}
		}
	}, [account])

	const accountLog = useMemo(() => {
		if (!isMobileL && account) {
			return (
				<>
					{account.substr(0, 10)}...{account.substr(account.length - 17)}
				</>
			)
		}
		if (isMobileL && account) {
			return (
				<>
					{account.substr(0, 5)}...{account.substr(account.length - 10)}
				</>
			)
		}
	}, [account])
	return (
		<>
			{window.location.pathname === '/playgame' && window.innerHeight < 640 ? (
				<PlayGameBoXConnect>
					<>
						{!active ? (
							<PlayGameBoXConnectHeader>
								<Flex
									alignItems="flex-start"
									width="100%"
									justifyContent="flex-end"
								>
									<Box>
										<CloseModalIcon onClick={onDismiss} />
									</Box>
								</Flex>
								<img className="cus-img" src={Logo} alt="..." />
								<PlayGameBoXConnectHeaderTitle>
									Connect Wallet
								</PlayGameBoXConnectHeaderTitle>
							</PlayGameBoXConnectHeader>
						) : (
							<PlayGameBoXConnectHeader className="none-pb">
								<img className="cus-img" src={Logo} alt="..." />
								<PlayGameBoXConnectHeaderTitle>
									Your wallet
								</PlayGameBoXConnectHeaderTitle>
							</PlayGameBoXConnectHeader>
						)}
						{!active ? (
							<PlayGameConnecBody>
								<PlayGameBoxModalContent>
									{WALLET_LIST.map((wallet) => {
										return (
											<PlayGameConnecBodyItem
												key={wallet.title}
												onClick={() => {
													handleConnect(wallet.connectorId)
												}}
											>
												<wallet.icon width="48px" />
												<p onClick={(e) => e.preventDefault()}>
													{wallet.title}
												</p>
											</PlayGameConnecBodyItem>
										)
									})}
									{NEED_A_PLACEHOLDER && <div className={walletItemClass} />}
								</PlayGameBoxModalContent>
							</PlayGameConnecBody>
						) : (
							<BoxPdDisconnectPlayGame>
								<Text textAlign="center" className="text-custom">
									{accountLog}
									{/* {account.substr(0, 10)}...{account.substr(account.length - 17)} */}
									{/* <CopyToClipboard text={account} onCopy={() => setCopied(true)}>
								<button className="h__btnCopy">
									<IconCoppy />
								</button>
							</CopyToClipboard> */}
									{/* {copied ? <span>Copied.</span> : null} */}
								</Text>
								<Text textAlign="center">
									<a
										href={`https://bscscan.com/address/${account}`}
										target="_blank"
										rel="noreferrer"
									>
										View on Bscscan
									</a>
									<svg
										viewBox="0 0 24 24"
										color="primary"
										width="20px"
										xmlns="http://www.w3.org/2000/svg"
										className="sc-bdfBwQ tqmjO"
									>
										<path
											d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"
											fill="#fff"
										></path>
									</svg>
								</Text>
								<BoxModalDisConnect>
									<div className="noti-out-button">
										<Button
											className="cus-btn"
											onClick={() => {
												handleDisconnect()
											}}
										>
											Disconnect
										</Button>
									</div>
								</BoxModalDisConnect>
							</BoxPdDisconnectPlayGame>
						)}
					</>
				</PlayGameBoXConnect>
			) : (
				<div>
					<BoxModal>
						<>
							{!active ? (
								<BoxHeader>
									<Flex
										alignItems="flex-start"
										width="100%"
										justifyContent="flex-end"
									>
										<Box>
											<CloseModalIcon onClick={onDismiss} />
										</Box>
									</Flex>

									<Box width="144px">
										<img className="cus-img" src={Logo} alt="..." />
									</Box>

									<ModalText>Connect Wallet</ModalText>
								</BoxHeader>
							) : (
								<BoxHeader className="none-pb">
									<Box width="144px">
										<img className="cus-img" src={Logo} alt="..." />
									</Box>
									<ModalText>Your wallet</ModalText>
								</BoxHeader>
							)}
						</>
						{!active ? (
							<BoxPd>
								<BoxModalContent>
									{WALLET_LIST.map((wallet) => {
										return (
											<WalletItem
												className={`cus-wallet`}
												key={wallet.title}
												onClick={() => {
													handleConnect(wallet.connectorId)
												}}
												icon={<wallet.icon width="48px" />}
												title={wallet.title}
											/>
										)
									})}
									{NEED_A_PLACEHOLDER && <div className={walletItemClass} />}
								</BoxModalContent>
							</BoxPd>
						) : (
							<BoxPdDisconnect>
								<Text textAlign="center" className="text-custom">
									{accountLog}
									{/* {account.substr(0, 10)}...{account.substr(account.length - 17)} */}
									{/* <CopyToClipboard text={account} onCopy={() => setCopied(true)}>
								<button className="h__btnCopy">
									<IconCoppy />
								</button>
							</CopyToClipboard> */}
									{/* {copied ? <span>Copied.</span> : null} */}
								</Text>
								<Text textAlign="center">
									<a
										href={`https://bscscan.com/address/${account}`}
										target="_blank"
										rel="noreferrer"
									>
										View on Bscscan
									</a>
									<svg
										viewBox="0 0 24 24"
										color="primary"
										width="20px"
										xmlns="http://www.w3.org/2000/svg"
										className="sc-bdfBwQ tqmjO"
									>
										<path
											d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"
											fill="#fff"
										></path>
									</svg>
								</Text>
								<BoxModalDisConnect>
									<div className="noti-out-button">
										<Button
											className="cus-btn"
											onClick={() => {
												handleDisconnect()
											}}
										>
											Disconnect
										</Button>
									</div>
								</BoxModalDisConnect>
							</BoxPdDisconnect>
						)}
					</BoxModal>
				</div>
			)}
		</>
	)
}

export default ConnectWallet
