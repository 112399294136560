import React from 'react'
import styled from 'styled-components'

const BoxPicture = styled.picture`
	display: inline-block;
`

interface PropsImg {
	url: string
	className?: string
}

const Picture: React.FC<PropsImg> = ({ url, className }) => {
	return (
		<BoxPicture>
			<img className={className} src={url} alt="..." />
		</BoxPicture>
	)
}

export default Picture
