import React from 'react'

const YoutubeIcon = (props: any) => {
	return (
		<svg
			width="21"
			height="15"
			viewBox="0 0 21 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.607 2.20301C19.4931 1.78041 19.2705 1.39501 18.9614 1.08518C18.6522 0.775338 18.2673 0.551868 17.845 0.437007C16.279 0.00700739 10.014 7.59651e-06 10.014 7.59651e-06C10.014 7.59651e-06 3.75 -0.00699261 2.183 0.404007C1.76093 0.524154 1.37682 0.750785 1.06757 1.06214C0.758311 1.3735 0.534287 1.75913 0.417002 2.18201C0.00400165 3.74801 1.46514e-06 6.99601 1.46514e-06 6.99601C1.46514e-06 6.99601 -0.00399852 10.26 0.406001 11.81C0.636001 12.667 1.311 13.344 2.169 13.575C3.751 14.005 9.999 14.012 9.999 14.012C9.999 14.012 16.264 14.019 17.83 13.609C18.2525 13.4943 18.6377 13.2714 18.9477 12.9622C19.2576 12.653 19.4814 12.2682 19.597 11.846C20.011 10.281 20.014 7.03401 20.014 7.03401C20.014 7.03401 20.034 3.76901 19.607 2.20301ZM8.01 10.005L8.015 4.00501L13.222 7.01001L8.01 10.005Z"
				fill={props.color}
			/>
		</svg>
	)
}
export default YoutubeIcon
