import React, { useCallback, useRef, lazy, Suspense } from 'react'
import {
	Router,
	Route,
	Switch,
	Redirect,
	NavLink,
	useLocation,
	useHistory,
} from 'react-router-dom'
import { ResetCSS, Box } from '@footdex2022/uikit'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'

import { Button } from './components/Quata'

import { useFetchPublicPoolsData, usePollBlockNumber } from './store/hooks'
import history from './routerHistory'

//edric
// import { HomeQuata } from './views/Home'
const HomeQuata = lazy(() => import('./views/Home/Home'))

// import PoolsIdo from './views/PoolsIdo/PoolsIdo'
import Logo from './views/Logo/Logo'
import styled from 'styled-components'
import { ScrollToTop } from './components/ScrollToTop'
import MenuNew from './components/MenuNew'
import MarketplaceDetail from './views/MarketplaceDetail'
// import FooterNew from './components/FooterNew'
import { RedirectPathToHome } from './views/Home/redirects'
import UseScrollTop from './hooks/useScrollTop'
import BoxScrollToTop from './hooks/ScrollToTop'
import LoadingQuata from './components/LoadingQuata/LoadingQuata'
const Marketplace = lazy(() => import('./views/Marketplace/Marketplace'))
const Betting = lazy(() => import('./views/Betting/Betting'))
const Metaverse = lazy(() => import('./views/Metaverse/Metaverse'))
const Countdown = lazy(() => import('./views/Countdown/Countdown'))
const PreSale = lazy(() => import('./views/Pre-Sale/PreSale'))
const PlayGames = lazy(() => import('./views/PlayGame'))
const LuckySpin = lazy(() => import('./views/luckyspin'))
const Pools = lazy(() => import('./views/PlayGame'))
const RefferralLink = lazy(() => import('./views/RefferralLink'))
// const MarketplaceDetail = lazy(() => import('./views/MarketplaceDetail'))

import 'react-toastify/dist/ReactToastify.css'

import './App.less'
import IconLuckyWell from 'components/luckyWell'

const App = () => {
	const refMenu = useRef(null)

	const location = useLocation()
	const history = useHistory()

	const handleCollapseMenu = useCallback(async () => {
		if (refMenu.current) {
			return await refMenu.current.toggleCollapsed()
		}
	}, [refMenu])
	return (
		<Suspense fallback={<LoadingQuata />}>
			<Router history={history}>
				{/* <Helmet>
				<meta charSet="utf-8" />
				<title>FootDex - Web3 Betting - Football Game NFT</title>
			</Helmet> */}
				<UseScrollTop />
				<ResetCSS />
				<BoxScrollToTop>
					<StyledContainer>
						{location.pathname !== '/playgame' && '/logo32x32' && <MenuNew />}
						<Switch>
							<Route path="/" exact>
								<HomeQuata />
							</Route>
							<Route path="/betting">
								<Betting />
							</Route>
							<Route path="/luckywell">
								<LuckySpin />
							</Route>
							<Route path="/playgame">
								<PlayGames />
							</Route>

							<Route path="/logo32x32">
								<Logo />
							</Route>
							<Route path="/marketplace">
								<Marketplace />
							</Route>
							<Route path="/metaverse">
								<Metaverse />
							</Route>
							<Route path="/claim">
								<PreSale />
							</Route>
							<Route path="/referral">
								<RefferralLink />
							</Route>
							<Route
								path="/marketplace-detail/:player"
								component={MarketplaceDetail}
							/>
							<Route component={RedirectPathToHome} />
						</Switch>
						{/* <FooterNew /> */}
						{/* ssh */}
					</StyledContainer>
				</BoxScrollToTop>
				{/* {location.pathname !== '/luckywell' ? <IconLuckyWell /> : <></>} */}
				<ScrollToTop />
				{location.pathname !== '/playgame' && (
					<Box position="fixed" bottom="50px" left="2%" zIndex="20">
						<a
							href="https://pancakeswap.finance/swap?outputCurrency=0x862a65D31DC45a0dfCcBB4DE5659137A6589c52a&inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
							target="_blank"
							rel="noreferrer"
						>
							<Button>Buy FBD</Button>
						</a>
					</Box>
				)}
			</Router>
			<ToastContainer
				position="top-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</Suspense>
	)
}

export const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
	z-index: 0;
`

export default React.memo(App)
