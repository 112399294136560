import React from 'react'
import { SvgProps, Svg } from '@footdex2022/uikit'

const ActiveIcon: React.FC<SvgProps> = (props) => {
	return (
		<Svg viewBox="0 0 30 48" {...props}>
			<path
				d="M0.359985 42.36L18.68 24L0.359985 5.64L5.99998 0L30 24L5.99998 48L0.359985 42.36Z"
				fill="white"
			/>
		</Svg>
	)
}

export default ActiveIcon
