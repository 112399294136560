import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ArrMenu } from './config'
import {
	MenuMobileWarrper,
	MenuMobileContainer,
	BtnMenu,
	MenuMobileList,
	MenuMobileListItem,
	MenuSocsical,
	ActiveIconBox,
} from './styled'
import { useActiveWeb3React } from '../../ConnectWallet/hooks'
import MenuIconMobile from '../../assets/imgQuata/Home/MenuIcon.svg'
import Intagram from './SvgIcon/Intargram'
import FaceBookIcon from './SvgIcon/facebook'
import TwitterIcon from './SvgIcon/Twitter'
import YoutubeIcon from './SvgIcon/Youtube'
import { ActiveIcon } from '../../components/Svg'

function MenuNewMobile(props: any) {
	const { account } = useActiveWeb3React()
	const [visible, setVisible] = useState(false)
	const showDrawer = () => {
		setVisible(true)
	}
	const handleUnShow = (e: any) => {
		e.preventDefault()
		e.stopPropagation()
		document.body.style.overflow = 'auto'
	}

	function RoutesCustom() {
		location.assign('https://staking.footdex.io/dashboard')
	}

	return (
		<>
			<MenuMobileWarrper
				style={{ right: `${props.show ? '0' : '100%'}` }}
				onClick={() => {
					props.handleShow(false)
					document.body.style.overflow = 'auto'
				}}
			>
				<MenuMobileContainer
					style={{ right: `${props.show ? '0' : '100%'}` }}
					onClick={(e) => {
						handleUnShow(e)
					}}
				>
					<BtnMenu
						onClick={() => {
							props.handleShow(false)
							document.body.style.overflow = 'auto'
						}}
					>
						<button>
							<img src={MenuIconMobile} alt="" />
						</button>
					</BtnMenu>
					<MenuMobileList>
						{ArrMenu.map((item, index) => {
							return (
								<MenuMobileListItem key={index}>
									{/* {window.location.pathname === item.to ? (
										<ActiveIconBox>
											<ActiveIcon />
										</ActiveIconBox>
									) : (
										<></>
									)} */}

									<>
										{item.title === 'staking' ? (
											<p onClick={() => RoutesCustom()}>
												{item.title.toLocaleUpperCase()}
											</p>
										) : (
											<>
												{/* {item.to !== '/..' ? (
													<Link
														to={item.to}
														onClick={() => {
															props.handleShow(false)
														}}
													>
														<p>{item.title.toLocaleUpperCase()}</p>
													</Link>
												) : (
													<div>
														<p>{item.title.toLocaleUpperCase()}</p>
													</div>
												)} */}
												<Link
													to={item.to}
													onClick={() => {
														props.handleShow(false)
													}}
												>
													<p>{item.title.toLocaleUpperCase()}</p>
												</Link>
											</>
										)}
									</>
								</MenuMobileListItem>
							)
						})}
					</MenuMobileList>
					<MenuSocsical>
						<Intagram color="#CFD9E6" />
						<FaceBookIcon color="#CFD9E6" />
						<TwitterIcon color="#CFD9E6" />
						<YoutubeIcon color="#CFD9E6" />
					</MenuSocsical>
				</MenuMobileContainer>
			</MenuMobileWarrper>
		</>
	)
}
export default MenuNewMobile
