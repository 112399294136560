import React, { useState, useEffect } from 'react'
import { Button } from '../Quata'
import styled from 'styled-components'
import { CheveronUpIcon } from '../Svg'

// edricPhan

const FixedToTop = styled.div<{ visible: boolean }>`
	position: fixed;
	right: 2%;
	bottom: 50px;
	visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
	opacity: ${({ visible }) => (visible ? '1' : '0')};
	transition: 0.3s ease-in-out;
	z-index: 999;
`

const ButtonToTop = styled(Button)`
	width: 50px;
	height: 50px;

	svg {
		margin: 0;
		fill: #fff;
	}
`

const ScrollToTop = () => {
	const [visible, setVisible] = useState(false)

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop
		if (scrolled > 700) {
			setVisible(true)
		} else if (scrolled <= 700) {
			setVisible(false)
		}
	}

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	}

	useEffect(() => {
		window.addEventListener('scroll', toggleVisible)

		return () => window.removeEventListener('scroll', toggleVisible)
	}, [])

	return (
		<FixedToTop visible={visible}>
			<ButtonToTop
				endIcon={<CheveronUpIcon width="34px" />}
				onClick={scrollToTop}
			/>
		</FixedToTop>
	)
}

export default ScrollToTop
