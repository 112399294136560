import React, { createContext, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

interface ModalsContext {
	content?: React.ReactNode
	isOpen?: boolean
	onPresent: (content: React.ReactNode, key?: string) => void
	onDismiss: () => void
}

export const Context = createContext<ModalsContext>({
	onPresent: () => {},
	onDismiss: () => {},
})

const Modals: React.FC = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [content, setContent] = useState<React.ReactNode>()
	const [modalKey, setModalKey] = useState<string>()

	const handlePresent = useCallback(
		(modalContent: React.ReactNode, key?: string) => {
			setModalKey(key)
			setContent(modalContent)
			setIsOpen(true)
		},
		[setContent, setIsOpen, setModalKey],
	)

	const handleDismiss = useCallback(() => {
		setContent(undefined)
		setIsOpen(false)
	}, [setContent, setIsOpen, modalKey])

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden'
		}
		return () => {
			document.body.style.overflow = 'auto'
		}
	}, [isOpen])

	return (
		<Context.Provider
			value={{
				content,
				isOpen,
				onPresent: handlePresent,
				onDismiss: handleDismiss,
			}}
		>
			{children}
			{isOpen && (
				<StyledModalWrapper>
					<StyledModalBackdrop onClick={handleDismiss} />
					{React.isValidElement(content) &&
						React.cloneElement(content, {
							//@ts-ignore
							onDismiss: handleDismiss,
						})}
				</StyledModalWrapper>
			)}
		</Context.Provider>
	)
}

const StyledModalWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	background: rgba(2, 82, 51, 0.2);
`

const StyledModalBackdrop = styled.div`
	position: absolute;
	opacity: 0.6;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
`

export default Modals
