import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { initialState, getBettingTotal } from '../users/userSlice'

export const userSlice = createSlice({
	name: 'invesment',
	initialState,
	reducers: {
		reset: (state) => {
			state.isLoading = false
			state.isSuccess = false
			state.isError = false
			state.message = ''
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getBettingTotal.pending, (state) => {
				state.isLoading = true
			})
			.addCase(getBettingTotal.fulfilled, (state, action: any) => {
				state.isLoading = false
				state.isSuccess = true
				state.info = action.payload
			})
			.addCase(getBettingTotal.rejected, (state, action: any) => {
				state.isLoading = false
				state.isError = true
				state.message = action.payload
			})
	},
})

export const { reset } = userSlice.actions
export default userSlice.reducer
