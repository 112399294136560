import React, { useState } from 'react'
import {
	Heading,
	Box,
	useMatchBreakpoints,
	Text,
	Flex,
} from '@footdex2022/uikit'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Button, BoxFull, FlexFull } from '../../components/Quata'
import Container from '../../components/Container'
import { TitleHeading } from '../Betting/Component/TitleLeft'
import Carousel from './Carousel'
import Footer from '../../components/FooterQuata'

import styled, { keyframes } from 'styled-components'

import Banner from '../../assets/Coundown/Banner.png'

import TshirtOne from '../../assets/imgQuata/Betting/tshirtOne.svg'

import { Wrapper, BannerMarket, BoxTrending, BannerMarketMini } from './styled'
import { Picture } from '../../components/Picture'

import PersonOne from '../../assets/imgQuata/Marketplace/PersonOne.png'
import PersonTwo from '../../assets/imgQuata/Marketplace/PersonTwo.png'
import PersonThree from '../../assets/imgQuata/Marketplace/PersonThree.png'
import PersonFour from '../../assets/imgQuata/Marketplace/PersonFour.png'
import PersonFive from '../../assets/imgQuata/Marketplace/PersonFive.png'

import PersonSix from '../../assets/imgQuata/Marketplace/PersonSix.png'
import PersonSevent from '../../assets/imgQuata/Marketplace/PersonSevent.png'
import PersonEight from '../../assets/imgQuata/Marketplace/PersonEight.png'
import PersonNight from '../../assets/imgQuata/Marketplace/PersonNight.png'
// import PersonFive from '../../assets/imgQuata/Marketplace/PersonFive.png'

import Brazill from '../../assets/imgQuata/Betting/FifaChampion/Brazill.png'
import Ecuador from '../../assets/imgQuata/Betting/FifaChampion/Ecuador.png'
import England from '../../assets/imgQuata/Betting/FifaChampion/England.png'
import France from '../../assets/imgQuata/Betting/FifaChampion/France.png'
import Iran from '../../assets/imgQuata/Betting/FifaChampion/Iran.png'
import Netherlands from '../../assets/imgQuata/Betting/FifaChampion/Netherlands.png'
import Qatar from '../../assets/imgQuata/Betting/FifaChampion/Qatar.png'
import Senegal from '../../assets/imgQuata/Betting/FifaChampion/Senegal.png'
import BannerNew from '../../assets/imgQuata/Marketplace/BannerNew.png'

export const Supper = [
	{
		id: '1',
		title: 'benzema',
		img: PersonOne,
	},
	{
		id: '2',
		img: PersonTwo,
		title: 'mbappe',
	},
	{
		id: '3',
		img: PersonThree,
		title: 'messi',
	},
	{
		id: '4',
		img: PersonFour,
		title: 'luis suarez',
	},
	{
		id: '5',
		img: PersonFive,
		title: 'lukaku',
	},
	{
		id: '6',
		img: PersonSix,
		title: 'neymar',
	},
	{
		id: '7',
		img: PersonSevent,
		title: 'rafael leao',
	},
	{
		id: '8',
		img: PersonEight,
		title: 'raphinha',
	},
	{
		id: '9',
		img: PersonNight,
		title: 'lewandowski',
	},
	{
		id: '10',
		img: PersonFive,
		title: 'lukaku',
	},
]
export const WorldCup = [
	{
		id: '1',
		img: Brazill,
		title: 'brazill',
	},
	{
		id: '2',
		img: Ecuador,
		title: 'ecuador',
	},
	{
		id: '3',
		img: England,
		title: 'england',
	},
	{
		id: '4',
		img: France,
		title: 'france',
	},
	{
		id: '5',
		img: Iran,
		title: 'iran',
	},
	{
		id: '6',
		img: Netherlands,
		title: 'netherlands',
	},
	{
		id: '7',
		img: Qatar,
		title: 'qatar',
	},
	{
		id: '8',
		img: Senegal,
		title: 'senegal',
	},
]

const ButtonArr = [
	{
		id: 1,
		title: 'Super Star',
		func: 'supperStar',
	},
	{
		id: 2,
		title: 'World Cup 2022',
		func: 'worldCup',
	},
	{
		id: 3,
		title: 'Cup C1',
		func: 'cupC1',
	},
	{
		id: 4,
		title: 'English Premier League',
		func: 'els',
	},
	{
		id: 5,
		title: 'LaLiga',
		func: 'laliga',
	},
]

interface Map {
	[key: string]: any[] | undefined
}

const isObjTab: Map = {
	supperStar: Supper,
	worldCup: WorldCup,
	cupC1: WorldCup,
	els: WorldCup,
	laliga: WorldCup,
}

const Marketplace = () => {
	const { isMobileL } = useMatchBreakpoints()
	const [isCheck, setIsCheck] = useState(isObjTab.supperStar)
	const [isName, setIsName] = useState('supperStar')

	const human = (str: string) => {
		isObjTab[str] || str
		setIsCheck(isObjTab[str])
		setIsName(str)
	}

	localStorage.setItem('ARR_DETAIL', JSON.stringify(isName || ''))

	const meta: any = document.querySelector("meta[name='description']")

	meta.content =
		'Create a collection of NFTs from Footdex Marketplace to customize and set up matches to your taste'

	return (
		<Wrapperz>
			<Helmet>
				<title>Marketplace | Football NFT | FootDex</title>
				<meta
					name="description"
					content="Create a collection of NFTs from Footdex Marketplace to customize and set up matches to your taste"
				/>
			</Helmet>
			{/* {isMobileL ? (
				<BannerMarketMini>
					<Flex
						height="50vh"
						justifyContent="center"
						alignItems="center"
						position="relative"
					>
						<Text
							textAlign="center"
							className="cus-font-devantor cus-bg "
							fontSize="120px"
						>
							NFT
						</Text>
					</Flex>
				</BannerMarketMini>
			) : (
				<BannerMarket>
					<Flex
						height="100vh"
						justifyContent="center"
						alignItems="center"
						position="relative"
						mt="-3%"
					>
						<Text
							textAlign="center"
							className="cus-font-devantor cus-title-market"
						>
							NFT
							<br /> Marketplace
						</Text>
					</Flex>
				</BannerMarket>
			)} */}

			<BannerMarket justifyContent="center" alignItems="center">
				<div className="cus-Banner" />
				<Box px="18px">
					<Text className="cus-market-title cus-font-devantor">NFT</Text>
					<Text className="cus-market-title cus-font-devantor cus-market-small">
						Marketplace
					</Text>
				</Box>
				<Box px="18px">
					<img src={BannerNew} alt="footdex" />
				</Box>
			</BannerMarket>

			<BoxTrending>
				<Box pb="40px">
					<Container>
						<Flex alignItems="center" justifyContent="space-between">
							<TitleHeading
								icon={TshirtOne}
								content="Super Star"
								title="TRENDING NFT"
								classNameTitle="cus-title"
								classNameContent="cus-content"
								isPb
							/>
							{/* <Box>
								<Button className="bg-trans">See more</Button>
							</Box> */}
						</Flex>
						<Box pt="40px">
							<Carousel />
						</Box>
					</Container>

					<Box pt="66px">
						<Container>
							<BoxText as="h1" className="cus-font-devantor">
								TOP COLLECTIONS
							</BoxText>
							<Box className="cus-overs">
								<Flex
									className="cus-gap cus-overs"
									justifyContent="space-between"
									pb="62px"
									pt="46px"
									minWidth="1200px"
								>
									{ButtonArr.map((_item, _index) => {
										return (
											<Box className="cus-transition" key={_index}>
												<Button
													className={`cus-btn-market ${
														isName === _item.func ? '' : 'cus-trans'
													}`}
													onClick={() => human(_item.func)}
													disabled={
														(_item.id === 3 ||
															_item.id === 4 ||
															_item.id === 5) &&
														true
													}
												>
													{_item.title}
												</Button>
											</Box>
										)
									})}
								</Flex>
							</Box>
							<FlexFull flexWrap="wrap">
								{isCheck.map((_item, _index) => {
									return (
										<Box
											width={['50%', '50%', '25%', '25%']}
											p="10px"
											key={_index}
										>
											<Link to={`/marketplace-detail/${_item?.id}`}>
												<Picture url={_item.img} />
											</Link>
										</Box>
									)
								})}
							</FlexFull>
							<Flex justifyContent="center" alignItems="center">
								<Box width="auto" pt="58px">
									<Button className="bg-trans">See more</Button>
								</Box>
							</Flex>
						</Container>
					</Box>
				</Box>
			</BoxTrending>
			<BoxFull position="relative" minHeight="375px">
				<BoxFooter />
				<Box>
					<Footer />
				</Box>
			</BoxFull>
		</Wrapperz>
	)
}

export default Marketplace

const Wrapperz = styled(Flex)`
	width: 100%;
	height: 100%;
	min-height: 100vh;
	/* background-image: url(${Banner}); */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow: hidden;

	.countdown-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 0 -8px 0 -8px;
	}

	.time-section {
		padding: 0px 8px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		color: #fff;
	}

	.time {
		margin: 0;
		font-size: 60px;
		font-weight: 800;
	}

	.small {
		font-size: 18px;
	}

	.cus-gap {
		gap: 12px;
	}

	.cus-transition {
		transition: 0.3s ease-in-out;
		width: 100%;
	}

	.cus-btn-market {
		/* width: auto; */
		padding: 15px 53px;
		font-weight: 700;
		transition: 0.3s ease-in-out;
	}

	/* .cus-btn-market.active {
		background: blue;
	} */

	.cus-trans {
		background: transparent;
		border: 1px solid #fff;
		color: #fff;
	}

	.cus-overs {
		overflow-x: scroll;

		::-webkit-scrollbar {
			display: none;
		}
	}

	img {
		max-width: 100%;
	}

	.cus-bg {
		background: linear-gradient(180deg, #ffffff 0%, #b4b4b4 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
	}

	.cus-title-market {
		font-weight: 700;
		font-size: 100.139px;
		text-align: center;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		line-height: initial;
		background: linear-gradient(
			95.39deg,
			#ffffff 45.69%,
			rgba(255, 255, 255, 0) 150.83%
		);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		position: relative;
	}

	@media only screen and (max-width: 860px) {
		.cus-title-market {
			font-size: 80px;
		}
	}

	@media only screen and (max-width: 768px) {
		.cus-title-market {
			font-size: 50px;
		}
	}

	@media only screen and (max-width: 520px) {
		.time {
			font-size: 30px;
		}
	}

	@media only screen and (max-width: 416px) {
		.time {
			font-size: 25px;
		}
	}
`

// const BoxText = styled(Text)`
// 	font-family: Devator;
// 	font-style: normal;
// 	font-weight: 700;
// 	font-size: 48px;
// 	line-height: 120%;

// 	text-align: center;
// 	letter-spacing: 0.02em;
// 	text-transform: uppercase;

// 	background: linear-gradient(
// 		180deg,
// 		#ffffff 0%,
// 		rgba(255, 255, 255, 0.67) 100%
// 	);
// 	-webkit-background-clip: text;
// 	-webkit-text-fill-color: transparent;
// 	background-clip: text;
// 	text-fill-color: transparent;
// 	padding-bottom: 10px;

// 	@media only screen and (max-width: 768px) {
// 		font-size: 34px;
// 	}
// `

// const BoxTextMini = styled(Text)`
// 	font-family: Monda;
// 	font-style: normal;
// 	font-weight: 400;
// 	font-size: 48px;
// 	line-height: 47px;
// 	text-align: center;
// 	color: #f2f2f2;
// 	padding-bottom: 20px;

// 	@media only screen and (max-width: 560px) {
// 		font-size: 22px;
// 	}
// `

export const BoxText = styled(Text)`
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 39px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: linear-gradient(90deg, #69eacb 0%, #eaccf8 48%, #6654f1 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	text-transform: uppercase;
	font-family: Devator;
`

export const BoxFooter = styled(Box)`
	position: absolute;
	top: -65%;
	left: 0;
	width: 100%;
	height: 600px;
	z-index: -1;
	background: linear-gradient(
		180deg,
		rgba(53, 228, 215, 0) 0%,
		rgba(53, 228, 215, 0.28) 32.3%,
		#340e38 59.9%,
		#000000 100%
	);

	@media only screen and (max-width: 860px) {
		top: -5%;
		/* height: 600px; */
	}
`
