export const MAINNET_BSC_URL = 'https://bsc-dataseed.binance.org'
export const MAINNET_CHAIN_ID = 56

const walletTotal =
  window.localStorage.getItem('result') ||
  '0xd210289f033B57310464144Af127C493b335D8DE'

// console.log('?????', walletTotal)

export const bscTokens = [
  // stake pool
  {
    id: 'wbnb',
    symbol: 'wbnb',
    contract: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
  {
    id: 'binance-usd',
    symbol: 'busd',
    contract: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },
  {
    id: 'tether',
    symbol: 'USDT',
    contract: '0x55d398326f99059fF775485246999027B3197955',
  },
  {
    id: 'ethereum',
    symbol: 'ETH',
    contract: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  },
  {
    id: 'dai',
    symbol: 'DAI',
    contract: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  },
  {
    id: 'polis',
    symbol: 'POLIS',
    contract: '0xb5bea8a26d587cf665f2d78f077cca3c7f6341bd',
  },
  {
    id: 'tether',
    symbol: 'USDT',
    contract: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
  },
  {
    id: 'usd-coin',
    symbol: 'USDC',
    contract: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  },
  {
    id: 'visa',
    symbol: 'VISA',
    contract: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  },
  {
    id: 'tribeone',
    symbol: 'HAKA',
    contract: '0xd85ad783cc94bd04196a13dc042a3054a9b52210',
  },
  {
    id: 'bsc-station',
    symbol: 'BSCS',
    contract: '0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d',
  },
]

export const poolsDeath = [0]

export const ADDRESS_RECEVIE_TOKEN = walletTotal

// export const ADDRESS_RECEVIE_TOKEN =
//   '0x26798B2A11C6130Df55C286754bD0DCB9906F052'

export const ADDRESS_USD: any = {
  56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  1: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
  250: '0xAd84341756Bf337f5a0164515b1f6F993D194E1f',
}
export const CONTRACT_TEST: any = {
  56: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
  97: '0xE1068958C357e84f2C065D8C9b9f15C70028B546',
}
export const ADDRESS_FBD: any = {
  56: '0x862a65d31dc45a0dfccbb4de5659137a6589c52a',
}
export const NetworkContextName = 'NETWORK'
