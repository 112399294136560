import React from 'react'
import Logo32 from '../../assets/imgQuata/Logo/logo32x32.png'

const LogoMini = () => {
	return (
		<div>
			<img src={Logo32} alt="logoMini" />
		</div>
	)
}

export default LogoMini
