import { createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { BIG_TEN } from '../../utils/bigNumber'
import { getPoolApr } from '../../utils/apr'
import {
	// fetchPoolsAllowance,
	fetchUserBalances,
	// fetchUserPendingRewards,
	// fetchUserStakeBalances,
} from './fetchPoolsUser'
import { fetchPoolsTotalStaking } from './fetchPools'
import { getBscPrices } from '../../utils/bsc_helpers'
import { getBalanceNumber } from '../../utils/formatBalance'
//edricphan
import poolsConfig from '../../constants/pools'
import lpAprs from 'config/lpAprs.json'

const initialState = {
	data: poolsConfig,
	userDataLoaded: false,
}

// Thunks
export const fetchPoolsPublicDataAsync =
	(currentBlock) => async (dispatch, getState, farmAddress) => {
		// const totalStakings = await fetchPoolsTotalStaking()

		const prices = getState()?.prices?.data || (await getBscPrices())
		const liveData = poolsConfig.map((pool) => {
			// const totalStaking = totalStakings.find(
			// 	(entry) => entry.sousId === pool.sousId,
			// )
			const isPoolEndBlockExceeded = currentBlock > pool.endBlock
			const isPoolFinished = pool.isFinished || isPoolEndBlockExceeded

			const stakingTokenAddress = pool.stakingToken.address
				? pool.stakingToken.address.toLowerCase()
				: null
			const earningTokenAddress = pool.earningToken.address
				? pool.earningToken.address.toLowerCase()
				: null
			const getPriceStakeToken =
				lpAprs[stakingTokenAddress?.toLocaleLowerCase()] ?? 0

			const stakingTokenPrice = stakingTokenAddress
				? pool.typeGetPrice === 1 || pool.typeGetPrice == 3
					? getPriceStakeToken
					: prices[stakingTokenAddress]
				: 0

			const getPriceEarnToken =
				lpAprs[earningTokenAddress?.toLocaleLowerCase()] ?? 0

			const earningTokenPrice = earningTokenAddress
				? pool.typeGetPrice === 2 || pool.typeGetPrice == 3
					? getPriceEarnToken
					: prices[earningTokenAddress]
				: 0

			// const stakingTokenPrice = stakingTokenAddress
			// 	? prices[stakingTokenAddress]
			// 	: 0

			// const earningTokenPrice = earningTokenAddress
			// 	? prices[earningTokenAddress]
			// 	: 0

			// const stakingTokenPrice = stakingTokenAddress
			// 	? stakingTokenAddress === '0x7df2a33bfcd76240b796b0fbbed406aa1fcce830'
			// 		? 0.05
			// 		: prices[stakingTokenAddress]
			// 	: 0

			// const earningTokenPrice = earningTokenAddress
			// ? earningTokenAddress === '0x7df2a33bfcd76240b796b0fbbed406aa1fcce830'
			// 	? 0.05
			// 	: prices[earningTokenAddress]
			// : 0

			const apr = !isPoolFinished
				? getPoolApr(
						pool.typeGetPrice === 1 || pool.typeGetPrice === 3
							? getPriceStakeToken
							: stakingTokenPrice,
						pool.typeGetPrice === 2 || pool.typeGetPrice === 3
							? getPriceEarnToken
							: earningTokenPrice,
						// getBalanceNumber(
						// 	new BigNumber(totalStaking.totalStaked),
						// 	pool.stakingToken.decimals,
						// ),
						pool.tokenPerBlock,
						pool.earningToken.decimals,
				  )
				: 0

			// const stakedTvl = new BigNumber(
			// 	new BigNumber(totalStaking.totalStaked).div(
			// 		BIG_TEN.pow(pool.stakingToken.decimals),
			// 	),
			// )

			// 	.times(
			// 		new BigNumber(
			// 			pool.typeGetPrice === 1 || pool.typeGetPrice == 3
			// 				? getPriceStakeToken
			// 				: stakingTokenPrice,
			// 		),
			// 	)
			// 	.toJSON()
			return {
				// ...totalStaking,
				// stakedTvl,
				stakingTokenPrice,
				earningTokenPrice,
				apr,
				isFinished: isPoolFinished,
			}
		})

		dispatch(setPoolsPublicData(liveData))
	}

export const fetchPoolsUserDataAsync = (account) => async (dispatch) => {
	// const allowances = await fetchPoolsAllowance(account)
	// console.log('allowances', allowances) // {number}

	const stakingTokenBalances = await fetchUserBalances(account)
	// console.log('stakingTokenBalances', stakingTokenBalances) // {number}

	// const balances = await fetchUserStakeBalances(account)
	// console.log('balances', balances) // {number}

	// const pendingRewards = await fetchUserPendingRewards(account)
	// console.log('pendingRewards', pendingRewards) // {number}

	const userData = poolsConfig.map((pool) => ({
		sousId: pool.sousId,
		// allowance: allowances[pool.sousId],
		stakingTokenBalance: stakingTokenBalances[pool.sousId],
		// stakedBalance: balances[pool.sousId].stakedBalance,
		// lastStakingBlock: balances[pool.sousId].lastStakingBlock,
		// pendingReward: pendingRewards[pool.sousId],
	}))

	// console.log(' userData >>>>>', userData)

	dispatch(setPoolsUserData(userData))
}

// export const updateUserAllowance = (sousId, account) => async (dispatch) => {
// 	const allowances = await fetchPoolsAllowance(account)
// 	dispatch(
// 		updatePoolsUserData({
// 			sousId,
// 			field: 'allowance',
// 			value: allowances[sousId],
// 		}),
// 	)
// }

export const updateUserBalance = (sousId, account) => async (dispatch) => {
	const tokenBalances = await fetchUserBalances(account)
	dispatch(
		updatePoolsUserData({
			sousId,
			field: 'stakingTokenBalance',
			value: tokenBalances[sousId],
		}),
	)
}

// export const updateUserStakedBalance =
// 	(sousId, account) => async (dispatch) => {
// 		const stakedBalances = await fetchUserStakeBalances(account)
// 		dispatch(
// 			updatePoolsUserData({
// 				sousId,
// 				field: 'stakedBalance',
// 				value: stakedBalances[sousId],
// 			}),
// 		)
// 	}

// export const updateUserPendingReward =
// 	(sousId, account) => async (dispatch) => {
// 		const pendingRewards = await fetchUserPendingRewards(account)
// 		dispatch(
// 			updatePoolsUserData({
// 				sousId,
// 				field: 'pendingReward',
// 				value: pendingRewards[sousId],
// 			}),
// 		)
// 	}

export const poolsSlice = createSlice({
	name: 'pools',
	initialState,
	reducers: {
		setPoolsPublicData: (state, action) => {
			const livePoolsData = action.payload
			state.data = state.data.map((pool) => {
				const livePoolData = livePoolsData.find(
					(entry) => entry.sousId === pool.sousId,
				)
				return { ...pool, ...livePoolData }
			})
		},
		setPoolsUserData: (state, action) => {
			const userData = action.payload
			// console.log("userData", userData) === userData phia tren
			state.data = state.data.map((pool) => {
				const userPoolData = userData.find(
					(entry) => entry.sousId === pool.sousId,
				)
				// console.log("userPoolData",userPoolData)

				return { ...pool, userData: userPoolData }
			})
			state.userDataLoaded = true
			// console.log("userData",userData)
		},
		updatePoolsUserData: (state, action) => {
			const { field, value, sousId } = action.payload
			const index = state.data.findIndex((p) => p.sousId === sousId)

			if (index >= 0) {
				state.data[index] = {
					...state.data[index],
					userData: { ...state.data[index].userData, [field]: value },
				}
			}
		},
	},
})

// Actions
export const { setPoolsPublicData, setPoolsUserData, updatePoolsUserData } =
	poolsSlice.actions
export default poolsSlice.reducer
